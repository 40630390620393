import { Column } from "react-table";
import { sortByName } from "../../../utils/helpers/sorting";

export const COLUMNS: Column[] | any = [
  {
    Header: "Company Name",
    accessor: "name",
    sortType: sortByName,
  },
  {
    Header: "Company Email",
    accessor: "email",
  },
];

import { useEffect, useState } from "react";

import Select from "react-select";
import { Column } from "react-table";
import { useSelector } from "react-redux";

import HashMap from "hashmap";

import { Checkbox, CircularProgress, Grid, Button } from "@mui/material";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";

import {
  createNonProjectUserAllocation,
  getWeeklyTempAllocations,
  updateAllocation,
} from "../../../../apis/services/allocation.service";
import {
  getAllCompaniesList,
  getCompanyUsersList,
} from "../../../../apis/services/company.service";
import { getGroups, postGroups } from "../../../../apis/services/group.service";

import {
  getCompanyId,
  getDayFromDayNumber,
  hasPermission,
  isAdmin,
  isSuccessCode,
} from "../../../../Common/Common";
import { DayOfWeek } from "../../../../Common/Enums/day";
import { DayNumber } from "../../../../Common/Enums/day-number";
import { Group } from "../../../../Common/Interfaces/Allocation/group";
import { CompanyLevelPermissions } from "../../../../Common/Enums/permissions";
import { Allocation } from "../../../../Common/Interfaces/Allocation/allocation";
import {
  DropdownItem,
  ReactSelect,
} from "../../../../Common/Interfaces/dashboard";

import { ReduxState } from "../../../../redux/model/ReduxState.d";
import { UserCustomClaim } from "../../../../redux/model/UserState.d";

import AddEditGroupsModal from "./AddEditGroupModal";
import BasicTable from "../../../Common/Table/BasicTable";

export default function NextAllocationTabNew() {
  const [showModal, setShowModal] = useState(false);

  const [companiesList, setCompaniesList] = useState([] as ReactSelect[]);
  const [selectedCompany, setSelectedCompany] = useState({} as DropdownItem);
  const [companyUsers, setCompanyUsers] = useState([] as DropdownItem[]);
  const [groupData, setGroupData] = useState([] as Group[]);
  const [checkboxLoadingMap, setCheckboxLoadingMap] = useState(
    new HashMap<string, HashMap<string, boolean>>()
  );

  const [allocationData, setAllocationData] = useState([] as Allocation[]);
  const [allocationMap, setAllocationsMap] = useState(
    new HashMap<string, HashMap<string, boolean>>()
  );
  const [allocationTableData, setAllocationTableData] = useState([] as any);
  const [tableKey, setTableKey] = useState(0);

  const userCustomClaim = useSelector<ReduxState, UserCustomClaim>(
    (state: ReduxState) => state.UserReducer?.userPermission?.userCustomClaim
  );

  const days = Object.keys(DayNumber).map(
    (dayNumber) => dayNumber as DayNumber
  );

  const weekend: DayOfWeek[] = [DayOfWeek.SATURDAY, DayOfWeek.SUNDAY];
  const weekendNumbers: DayNumber[] = [
    DayNumber.DAY_6,
    DayNumber.DAY_7,
    DayNumber.DAY_13,
    DayNumber.DAY_14,
  ];
  const userId = useSelector<ReduxState, string>(
    (state: ReduxState) => state.UserReducer.userId || ""
  );
  const userName = useSelector<ReduxState, string>(
    (state: ReduxState) => state.UserReducer.userName || ""
  );

  useEffect(() => {
    if (userCustomClaim) {
      getCompaniesList();
    }
  }, [userCustomClaim]);

  useEffect(() => {
    getCompanyUsers();
    if (showModal) {
      getCompaniesList();
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (allocationTableData?.length) {
      setTableKey((prevKey) => prevKey + 1);
    }
  }, [allocationTableData]);

  useEffect(() => {
    getAllocations();
  }, [companyUsers]);

  const handleAttendanceStatusChange = async (
    userId: string,
    userName: string,
    date: string,
    status: boolean
  ) => {
    let isEdit = false;
    const allocations = allocationTableData.filter((x: any) => {
      return x.userId === userId;
    });

    const allocation = allocations[0];
    const allocationIsAlreadyCreated = allocationData.filter(
      (allocationObj) => allocationObj.userId === allocation.userId
    );

    if (allocationIsAlreadyCreated.length > 0) {
      isEdit = true;
    } else {
      const checkBoxMap = checkboxLoadingMap.clone();
      if (!checkBoxMap.has(date)) {
        checkBoxMap.set(date, new HashMap<string, boolean>(userId, true));
      } else {
        checkBoxMap.get(date)?.set(userId, true);
      }
      setCheckboxLoadingMap(checkBoxMap);

      const userAllocationProperties = {
        companyId: selectedCompany.id,
        userId: userId,
        userName: userName,
        date: date,
        status: status,
      } as Allocation;

      const res = await createNonProjectUserAllocation(
        selectedCompany.id,
        userAllocationProperties
      );

      if (res && isSuccessCode(res.status)) {
        const allocationObjectMap = allocationMap.clone();
        allocationObjectMap.get(date)?.set(userId, status);

        // Update allocation table data
        const updatedAllocations = allocationTableData.map(
          (allocation: any) => {
            if (allocation.userId === userId) {
              if (allocation[date] !== undefined) {
                allocation[date] = status;
              }
            }
            return allocation;
          }
        );
        setAllocationTableData(updatedAllocations);
        setAllocationsMap(allocationObjectMap);

        checkBoxMap.get(date)?.set(userId, false);
        setCheckboxLoadingMap(checkBoxMap);
      }

      checkBoxMap.get(date)?.set(userId, false);
      setCheckboxLoadingMap(checkBoxMap);
    }

    if (isEdit) {
      const allocationObjData: any = allocationIsAlreadyCreated[0];
      const allocationObj = {
        companyId: allocationObjData?.id,
        userId: allocationObjData?.userId,
        userName: allocationObjData?.userName,
        date: allocationObjData?.date,
        status: status,
        id: allocationObjData?.id,
        voided: allocationObjData?.voided,
      };

      const checkBoxMap = checkboxLoadingMap.clone();

      if (!checkBoxMap.has(date)) {
        checkBoxMap.set(date, new HashMap<string, boolean>(userId, true));
      } else {
        checkBoxMap.get(date)?.set(userId, true);
      }

      setCheckboxLoadingMap(checkBoxMap);

      const res = await updateAllocation(
        selectedCompany.id,
        allocationObj?.id ?? "",
        allocationObj
      );

      if (res && isSuccessCode(res.status)) {
        const allocationObjectMap = allocationMap.clone();
        allocationObjectMap.get(date)?.set(userId, status);

        setAllocationsMap(allocationObjectMap);

        const allocationsDataCopy = allocationData.filter(
          (x) => x.id !== allocation.id
        );
        allocationsDataCopy.push(allocation);

        // Update allocation table data
        const updatedAllocations = allocationTableData.map(
          (allocation: any) => {
            if (allocation?.userId === userId) {
              if (allocation.hasOwnProperty(date)) {
                allocation[date] = status;
              }
            }
            return allocation;
          }
        );

        setAllocationTableData(updatedAllocations);
        setAllocationData(allocationsDataCopy);

        checkBoxMap.get(date)?.set(userId, false);
        setCheckboxLoadingMap(checkBoxMap);
      }

      checkBoxMap.get(date)?.set(userId, false);
      setCheckboxLoadingMap(checkBoxMap);
    }
  };

  const handleEdit = async () => {
    await getAllocations();
  };
  const handleAddGroups = async () => {
    const res = await postGroups(selectedCompany.id);
    if (res && isSuccessCode(res?.status)) {
      await getAllocations();
    }
  };
  const getAllocations = async () => {
    if (selectedCompany && selectedCompany.id) {
      // setIsLoading(true);
      const group = await getGroups(selectedCompany.id);
      setGroupData(group);
      const fetchedAllocations = await getWeeklyTempAllocations(
        selectedCompany.id
      );
      const mappedData = new HashMap<string, HashMap<string, boolean>>();
      fetchedAllocations &&
        fetchedAllocations.forEach((a: Allocation) => {
          const date = a.date;
          if (!mappedData.has(date)) {
            if (!weekendNumbers.includes(date)) {
              mappedData.set(
                date,
                new HashMap<string, boolean>(a.userId, a.status)
              );
            }
          }
          mappedData.get(date)?.set(a.userId, a.status);
        });

      if (companyUsers?.length) {
        // generate table data
        const tableData = companyUsers.map((companyUser: any) => {
          const userToUpdate = [];
          const userObject: any = {
            userId: companyUser?.id,
            userName: companyUser?.name,
          };
          if (days?.length > 0) {
            days?.map((day) => {
              let status;
              if (mappedData.get(day)?.get(companyUser?.id)) {
                status = true;
              } else {
                status = false;
              }
              const dayString: any = day;

              userObject[dayString] = status;
            });
          }
          userToUpdate.push(userObject);
          return userToUpdate;
        });

        setAllocationTableData(tableData.flat(1));
        setAllocationData(fetchedAllocations);
        setAllocationsMap(mappedData);
      }
      // setIsLoading(false);
    }
  };

  const getCompaniesList = async () => {
    const companyId = getCompanyId(userCustomClaim);
    if (companyId.length === 0) {
      // setIsLoading(true);
      const companies = await getAllCompaniesList();
      const companiesDropDownData = companies.map(
        (company) => ({ value: company.id, label: company.name } as ReactSelect)
      );
      setCompaniesList(companiesDropDownData);
      if (companies && companies.length > 0) {
        setSelectedCompany(companies[0]);
      }

      // setIsLoading(false);
    } else {
      setSelectedCompany({ id: companyId, name: "" } as DropdownItem);
    }
  };

  const getCompanyUsers = async () => {
    if (
      hasPermission(userCustomClaim, CompanyLevelPermissions.GET_FOOD_ORDER) &&
      selectedCompany.id
    ) {
      // setIsLoading(true);
      const fetchedCompanyUsers = await getCompanyUsersList(selectedCompany.id);
      setCompanyUsers(fetchedCompanyUsers);
      // setIsLoading(false);
    } else {
      setCompanyUsers([{ id: userId, name: userName }] as DropdownItem[]);
    }
  };

  const generateColumns = () => {
    const columns: any = [];
    const uniqueColumnIds = new Set(); // Keep track of unique column identifiers

    if (allocationTableData?.length > 0) {
      allocationTableData.forEach((data: any) => {
        Object.keys(data).forEach((dataKey: any) => {
          if (dataKey !== "userId" && !uniqueColumnIds.has(dataKey)) {
            const column = {
              id: Math.random().toString(),
              Header:
                dataKey === "userName" ? "Name" : getDayFromDayNumber(dataKey),
              accessor: dataKey,
              disableSortBy: true,
              Cell: ({ row }: any) => {
                const nextAllocationTableDataValue = row?.original?.[dataKey];
                const currentUserId = row?.original?.userId;
                const currentUserName = row?.original?.userName;

                if (dataKey !== "userName") {
                  const isLoading =
                    checkboxLoadingMap?.get(dataKey)?.get(currentUserId) ||
                    false;
                  return (
                    <>
                      {!isLoading && (
                        <span className="w-[10px] h-[10px] p-0">
                          <Checkbox
                            key={currentUserId}
                            size="small"
                            id={dataKey}
                            disabled={
                              isAdmin(userCustomClaim) ||
                              weekend.includes(getDayFromDayNumber(dataKey))
                                ? true
                                : false
                            }
                            checked={nextAllocationTableDataValue}
                            onChange={(e) =>
                              handleAttendanceStatusChange(
                                currentUserId,
                                currentUserName,
                                dataKey,
                                e.target.checked
                              )
                            }
                          />
                        </span>
                      )}
                      {isLoading && (
                        <CircularProgress
                          color="primary"
                          className="flex justify-center"
                          size={20}
                          thickness={4}
                        />
                      )}
                    </>
                  );
                } else {
                  return nextAllocationTableDataValue;
                }
              },
            };
            columns.push(column);
            uniqueColumnIds.add(dataKey);
          }
        });
      });
    }

    return columns;
  };

  const tableHooks = (hooks: any) => {
    hooks.visibleColumns.push((columns: any) => [...columns]);
  };

  const COLUMNS: Column[] | any = [...generateColumns()];

  return (
    <>
      <div className="mt-5">
        <Grid container className="flex items-center">
          <Grid item xs={6} className="justify-start">
            {isAdmin(userCustomClaim) && (
              <Select
                className="w-72"
                options={companiesList}
                placeholder="Company"
                isMulti={false}
                onChange={(e: any) => {
                  const companyObj = { id: e.value, name: e.label };
                  setSelectedCompany(companyObj);
                }}
                isSearchable={false}
                styles={{
                  // Adjust the margin to align with buttons
                  container: (provided) => ({
                    ...provided,
                    marginTop: 0,
                  }),
                }}
              />
            )}
          </Grid>

          <Grid item xs={6} className="flex justify-end">
            {hasPermission(
              userCustomClaim,
              CompanyLevelPermissions.CREATE_GROUP
            ) &&
              groupData.length <= 0 && (
                <Button
                  aria-label="add"
                  color="primary"
                  onClick={() => handleAddGroups()}
                  variant="contained"
                  className="my-3"
                >
                  <AddTaskOutlinedIcon fontSize="medium" /> &nbsp;
                  <span className="text-sm">Add Groups</span>
                </Button>
              )}
            &nbsp;
            {hasPermission(
              userCustomClaim,
              CompanyLevelPermissions.CREATE_GROUP
            ) && (
              <Button
                aria-label="add"
                color="primary"
                onClick={() => setShowModal(true)}
                variant="contained"
                className="my-3"
              >
                <BorderColorOutlinedIcon fontSize="medium" /> &nbsp;
                <span className="text-sm">Edit Groups</span>
              </Button>
            )}
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            {allocationTableData?.length > 0 && (
              <BasicTable
                key={tableKey}
                tableColumns={COLUMNS}
                tableData={allocationTableData}
                headerColor="bg-gray-850"
                maxHeight={500}
                tableHooks={tableHooks}
              />
            )}
          </Grid>
        </Grid>
      </div>

      {showModal && (
        <AddEditGroupsModal
          showModal={showModal}
          handleClose={() => setShowModal(false)}
          handleEdit={handleEdit}
          selectedCompany={selectedCompany}
        />
      )}
    </>
  );
}

import { Column } from "react-table";
import { getDayFromDayNumber } from "../../../../Common/Common";

export const COLUMNS: Column[] | any = [
  {
    id: "menudaynumber",
    Header: "Day",
    accessor: "dayNumber",
    disableSortBy: true,
    Cell: ({ row }: any) => {
      return <span>{getDayFromDayNumber(row?.original?.dayNumber)}</span>;
    },
  },
];

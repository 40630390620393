import { Column } from "react-table";
import { sortByName } from "../../../../utils/helpers/sorting";

export const COLUMNS: Column[] | any = [
  {
    Header: "Event Name",
    accessor: "eventName",
    sortType: sortByName,
  },
  {
    Header: "Event Count",
    accessor: "eventCount",
  },
];

export interface CloudinaryConfigVariables {
  timestamp: string;
  apiKey?: string;
  signature: string;
  publicId?: string;
}
export function fetchCloudinaryBaseURL(cloudName: string) {
  return `https://api.cloudinary.com/v1_1/${cloudName}/video/upload`;
}
export function fetchUploadURL(credentials: CloudinaryConfigVariables) {
  const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
  const baseURL = cloudName && fetchCloudinaryBaseURL(cloudName);
  return `${baseURL}?timestamp=${credentials.timestamp}&api_key=${credentials.apiKey}&signature=${credentials.signature}`;
}

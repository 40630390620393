import { AxiosResponse } from "axios";
import {
  Company,
  CompanyUserRole,
  DropdownItem,
} from "../../Common/Interfaces/dashboard";
import {
  deleteData,
  getData,
  postData,
  updateData,
} from "../axios-calls/common-axios-service";

export const getAllCompanies = async (
  companyId = "",
  startAt = "",
  endBefore = ""
): Promise<Company[]> => {
  const companiesInfo = await getData(
    `companies?companyId=${companyId}&startAt=${startAt}&endBefore=${endBefore}`
  );
  return companiesInfo;
};

export const getCompanyUsersList = async (
  companyId: string
): Promise<DropdownItem[]> => {
  const fetchedCompanyUsers = await getData(`user/companies/${companyId}/list`);
  return fetchedCompanyUsers;
};

export const getAllCompaniesList = async (): Promise<DropdownItem[]> => {
  const companiesList = await getData(`companies/list`);
  return companiesList;
};

export const postCompany = async (company: any): Promise<AxiosResponse> => {
  const res = await postData("companies", company);
  return res as AxiosResponse;
};

export const updateCompany = async (
  companyId: string,
  company: any
): Promise<AxiosResponse> => {
  const res = await updateData(`companies/${companyId}`, company);
  return res as AxiosResponse;
};

export const deleteCompany = async (
  companyId: string
): Promise<AxiosResponse> => {
  const res = await deleteData(`companies/${companyId}`);
  return res as AxiosResponse;
};

export const addUserInCompany = async (user: any): Promise<AxiosResponse> => {
  const res = await postData(`user`, user);
  return res as AxiosResponse;
};
export const deleteUserFromCompany = async (
  companyId: string,
  user: string
): Promise<AxiosResponse> => {
  const res = await deleteData(`user/${user}`);
  return res as AxiosResponse;
};
export const getCompanyUsersWithRoles = async (
  companyId: string
): Promise<CompanyUserRole[]> => {
  const fetchedCompanyUsers = await getData(
    `user/companies/${companyId}/companyPermissions`
  );
  return fetchedCompanyUsers;
};

export const updateUserRoleInCompany = async (
  userId: string,
  memberBody: any
): Promise<AxiosResponse> => {
  const res = await updateData(`user/${userId}/companyPermissions`, memberBody);
  return res as AxiosResponse;
};

import { AxiosResponse } from "axios";
import { HolidayAttendance } from "../../Common/Interfaces/Attendance/holiday-attendance";
import {
  getData,
  postData,
  updateData,
} from "../axios-calls/common-axios-service";

export const getAllHolidays = async (
  companyId: string
): Promise<HolidayAttendance[]> => {
  const holidays = await getData(
    `companies/${companyId}/holidayattendance/list`
  );
  return holidays;
};

export const updateHoliday = async (
  companyId: string,
  holidayAttendanceId: string,
  holidayBody: any
): Promise<AxiosResponse> => {
  const res = await updateData(
    `companies/${companyId}/holidayattendance/${holidayAttendanceId}`,
    holidayBody
  );
  return res as AxiosResponse;
};

export const postHoliday = async (
  companyId: string,
  holiday: any
): Promise<AxiosResponse> => {
  const res = await postData(
    `companies/${companyId}/holidayattendance`,
    holiday
  );
  return res as AxiosResponse;
};

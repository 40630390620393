import { Button, Grid, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import Select from "react-select";
import {
  getCompanyId,
  hasPermission,
  isAdmin,
  isSuccessCode,
} from "../../../Common/Common";
import { CompanyLevelPermissions } from "../../../Common/Enums/permissions";
import {
  DropdownItem,
  ProjectDetail,
  ReactSelect,
} from "../../../Common/Interfaces/dashboard";
import { getAllCompaniesList } from "../../../apis/services/company.service";
import {
  deleteProject,
  getAllProjects,
} from "../../../apis/services/projects.service";
import { ReduxState } from "../../../redux/model/ReduxState.d";
import { UserCustomClaim } from "../../../redux/model/UserState.d";
import Alert from "../../Common/Alert/Alert";
// import AppDropdown from "../../Common/Dropdown/Dropdown";
import DeleteModal from "../../Common/Popup/DeleteModal";
import AddEditProjectModal from "./AddEditProjectModal/AddEditProjectModal";
import ProjectCardNew from "./ProjectCard/ProjectCardNew";
import "./ProjectTab.scss";

import AccountTreeIcon from "@mui/icons-material/AccountTree";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { MaxPageData } from "../../../Common/Enums/pagination";
import { useLogEvent } from "../Analytics/hooks/useLogEvent";

export default function ProjectTab() {
  const [projects, setProjects] = useState([] as ProjectDetail[]);
  const [showModal, setShowModal] = useState(false);
  const [editProject, setEditProject] = useState({} as ProjectDetail);
  const [companiesList, setCompaniesList] = useState([] as ReactSelect[]);
  const [selectedCompany, setSelectedCompany] = useState({} as DropdownItem);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletedProject, setDeletedProject] = useState({} as string);
  const userCustomClaim = useSelector<ReduxState, UserCustomClaim>(
    (state: ReduxState) => state.UserReducer?.userPermission?.userCustomClaim
  );
  const [prePageNumber, setPrePageNumber] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);

  const handleDeleteProject = (projectId: string) => {
    setDeletedProject(projectId);
    setShowDeleteModal(true);
  };
  const deleteProjectData = (projectId: string) => {
    if (projects.length === 1) setPageNumber((pageNumber) => pageNumber - 1);
    else {
      if (projects.length === 6) {
        projects[0].id === projectId
          ? getProjects(projects[1].id)
          : getProjects(projects[0].id);
      } else {
        const projectsList = [...projects];
        for (let i = 0; i < projectsList.length; i++) {
          if (projectsList[i].id === projectId)
            i === 0 ? getProjects(projects[i].id) : projectsList.splice(i, 1);
        }
        setProjects(projectsList);
      }
    }
  };

  const handleDelete = async () => {
    setShowDeleteModal(false);

    const res = await deleteProject(selectedCompany.id, deletedProject);
    if (res && isSuccessCode(res?.status)) {
      deleteProjectData(deletedProject);
    }
  };
  const handleClose = (project: ProjectDetail, isAddDelMember: boolean) => {
    if (isAddDelMember) addProject(project, true);
    setShowModal(false);
  };
  const handleShow = (isEdit: boolean, project: ProjectDetail | any) => {
    if (isEdit) {
      setEditProject(project);
    } else {
      setEditProject({
        id: "",
        name: "",
        startingDate: new Date(),
        clientName: "",
        clientEmail: "",
        projectBoardUrl: "",
        profilePicture: "",
        teammates: 0,
      } as ProjectDetail);
    }
    setShowModal(true);
  };

  useLogEvent("page_view", "Project");

  useEffect(() => {
    if (userCustomClaim) {
      getCompaniesList();
    }
  }, [userCustomClaim, pageNumber]);

  useEffect(() => {
    if (selectedCompany.id && selectedCompany.id.length > 0) {
      getProjects();
    }
  }, [selectedCompany]);

  const getCompaniesList = async () => {
    const companyId = getCompanyId(userCustomClaim);
    if (companyId.length === 0) {
      setIsLoading(true);
      const companies = await getAllCompaniesList();
      const companiesDropDownData = companies.map(
        (company) => ({ value: company.id, label: company.name } as ReactSelect)
      );
      setCompaniesList(companiesDropDownData);
      if (companies && companies.length > 0) {
        setSelectedCompany(companies[0]);
      }
      setIsLoading(false);
    } else {
      setSelectedCompany({ id: companyId, name: "" } as DropdownItem);
    }
  };

  const getProjects = async (startAt = "") => {
    if (selectedCompany && selectedCompany.id) {
      let endBefore = "";
      if (startAt === "")
        if (pageNumber > prePageNumber)
          startAt = projects[projects.length - 1].id ?? "";
        else if (pageNumber < prePageNumber) {
          endBefore = projects[0].id ?? "";
        }
      setIsLoading(true);
      const fetchedProjects = await getAllProjects(
        selectedCompany.id,
        startAt,
        endBefore
      );
      setProjects(fetchedProjects);
      pageNumber === 1 && pageNumber < prePageNumber
        ? setPrePageNumber(0)
        : setPrePageNumber(pageNumber);
      setIsLoading(false);
    }
  };

  const addProject = (updatedProject: ProjectDetail, isEdit = false) => {
    if (!isEdit) {
      if (projects.length !== 6) {
        const projectsList = [...projects, updatedProject];
        setProjects(projectsList);
      }
    } else {
      const updatedProjects = projects.map((project: ProjectDetail) => {
        if (project.id === editProject.id) {
          return {
            id: editProject.id,
            name: updatedProject.name,
            startingDate: updatedProject.startingDate,
            clientName: updatedProject.clientName,
            clientEmail: updatedProject.clientEmail,
            projectBoardUrl: updatedProject.projectBoardUrl,
            profilePicture: updatedProject.profilePicture,
            teammates: updatedProject.teammates,
          } as ProjectDetail;
        } else {
          return project;
        }
      });
      setProjects(updatedProjects);
    }
    setShowModal(false);
  };

  const projectsAvailable = (): boolean => {
    if (!projects || (projects && projects.length === 0)) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="projects-tab">
      <Grid container spacing={2}>
        <Grid item xs={12} spacing={2}>
          <div>
            {hasPermission(
              userCustomClaim,
              CompanyLevelPermissions.CREATE_PROJECTS
            ) &&
              !isAdmin(userCustomClaim) && (
                <Button
                  onClick={() => handleShow(false, {})}
                  variant="contained"
                  className="float-right"
                  startIcon={
                    <AccountTreeIcon className="text-white" fontSize="medium" />
                  }
                >
                  New Project
                </Button>
              )}
          </div>

          {isLoading ? (
            <Skeleton height={100} />
          ) : (
            <div>
              {isAdmin(userCustomClaim) && (
                <Select
                  className="w-96"
                  options={companiesList}
                  placeholder="Select Company"
                  isMulti={false}
                  onChange={(e: any) => {
                    const companyObj = { id: e.value, name: e.label };
                    setSelectedCompany(companyObj);
                  }}
                  isSearchable={false}
                />
              )}
            </div>
          )}
        </Grid>

        <Grid item xs={12} spacing={2} justifyContent={"center"}>
          {!projectsAvailable() && <Alert message="No Projects Available" />}
        </Grid>

        <Grid item xs={12} className="h-[600px]">
          {isLoading ? (
            <>
              <Skeleton height={250} />
              <Skeleton height={250} />
              <Skeleton height={250} />
            </>
          ) : (
            <Grid container spacing={2}>
              {projects.map((project: any) => (
                <Grid
                  className="flex justify-center"
                  key={project.id}
                  item
                  xs={12}
                  sm={6}
                  md={3}
                >
                  <ProjectCardNew
                    key={project.id}
                    project={project}
                    handleShow={handleShow}
                    handleDeleteProject={handleDeleteProject}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>

      {projects.length > 0 && (
        <Grid container justifyContent="flex-end">
          <div className="flex my-2">
            <Grid item>
              {pageNumber === 0 ? (
                <IconButton aria-label="left" color="primary" disabled>
                  <NavigateBeforeIcon fontSize="large" />
                </IconButton>
              ) : (
                <IconButton aria-label="left" color="primary">
                  <NavigateBeforeIcon
                    fontSize="large"
                    onClick={() => {
                      setPageNumber((pageNumber) => pageNumber - 1);
                    }}
                  />
                </IconButton>
              )}
            </Grid>
            <Grid item>
              {projects.length < MaxPageData.DATA_SIZE ? (
                <IconButton aria-label="right" color="primary" disabled>
                  <NavigateNextIcon fontSize="large" />
                </IconButton>
              ) : (
                <IconButton
                  aria-label="right"
                  color="primary"
                  disabled={projects.length < MaxPageData.DATA_SIZE}
                >
                  <NavigateNextIcon
                    fontSize="large"
                    onClick={() => {
                      setPageNumber((pageNumber) => pageNumber + 1);
                    }}
                  />
                </IconButton>
              )}
            </Grid>
          </div>
        </Grid>
      )}

      {showModal && (
        <AddEditProjectModal
          showModal={showModal}
          handleClose={handleClose}
          editProject={editProject}
          selectedCompanyId={selectedCompany.id}
          addProject={addProject}
          selectedCompany={selectedCompany}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          showModal={showDeleteModal}
          handleClose={() => setShowDeleteModal(false)}
          handleDelete={handleDelete}
          message="Are you sure you want to delete this Project?"
        />
      )}
    </div>
  );
}

import { textToId } from "../../../Common/Common";
import { AppButtonProps } from "../../../Common/Interfaces/app-button";
import "./Button.scss";

export default function Button(props: AppButtonProps) {
  return (
    <button
      id={textToId(props.text)}
      type={props.type}
      onClick={props.onClick}
      className={`rounded-full	 ` + (props.additionalClasses ?? "")}
      disabled={props.disabled}
    >
      {props.iconPath && <i className={`${props.iconPath} px-2`}></i>}
      {props.icon && props.icon}&nbsp;
      {props.text}
    </button>
  );
}

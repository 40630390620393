import { useEffect, useState } from "react";
import {
  DropdownItem,
  ReactSelect,
  WorklogFiltersProps,
} from "../../../../Common/Interfaces/dashboard";
import { IError } from "../../../../Common/Interfaces/errorInterface";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { Billable } from "../../../../Common/Enums/dashboard";
import {
  addAllInDropdown,
  enumStringToText,
  getCompanyId,
  getGlobalProject,
  getProjectPermissions,
  hasPermission,
  isAdmin,
  isCompanyAdmin,
} from "../../../../Common/Common";
import {
  getAllCompaniesList,
  getCompanyUsersList,
} from "../../../../apis/services/company.service";
import { getAllProjectsList } from "../../../../apis/services/projects.service";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import {
  CompanyLevelPermissions,
  ProjectLevelPermissions,
} from "../../../../Common/Enums/permissions";
import { getProjectMembersAndRoles } from "../../../../apis/services/user-projects.service";
import { ReduxState } from "../../../../redux/model/ReduxState.d";
import { UserCustomClaim } from "../../../../redux/model/UserState.d";
import Alert from "../../../Common/Alert/Alert";
import { Button, Grid, Typography } from "@mui/material";
import Select from "react-select";
import SearchIcon from "@mui/icons-material/Search";

export default function WorklogFilters(props: WorklogFiltersProps) {
  const [companies, setCompanies] = useState([] as ReactSelect[]);
  const [members, setMembers] = useState([] as ReactSelect[]);
  const [projects, setProjects] = useState([] as ReactSelect[]);
  const [companiesLoading, setCompaniesLoading] = useState(false);
  const userCustomClaim = useSelector<ReduxState, UserCustomClaim>(
    (state: ReduxState) => state.UserReducer?.userPermission?.userCustomClaim
  );
  const userId = useSelector<ReduxState, string>(
    (state: ReduxState) => state.UserReducer.userId || ""
  );
  const userName = useSelector<ReduxState, string>(
    (state: ReduxState) => state.UserReducer.userName || ""
  );
  const projectState = useSelector(
    (state: ReduxState) => state.SelectedProjectReducer
  );
  const [error, setError] = useState({
    isError: false,
    message: "",
  } as IError);

  const billables = [
    { value: Billable.ALL, label: enumStringToText(Billable.ALL) },
    { value: Billable.YES, label: enumStringToText(Billable.YES) },
    { value: Billable.NO, label: enumStringToText(Billable.NO) },
  ];
  useEffect(() => {
    try {
      Promise.all([getProjects(), getMembers()]);
    } catch (e) {
      setError({
        isError: true,
        message: "unable to fetch projects and members",
      });
    }
  }, [props.selectedCompany]);
  useEffect(() => {
    try {
      if (isAdmin(userCustomClaim)) {
        getCompaniesList();
      }
    } catch (e) {
      setError({
        isError: true,
        message: "unable to fetch companies",
      });
    }
  }, [userCustomClaim]);

  const isAuthorizedForCompanyMembers = (): boolean => {
    if (
      hasPermission(
        userCustomClaim,
        CompanyLevelPermissions.GET_COMPANY_USERS
      ) &&
      hasPermission(
        userCustomClaim,
        CompanyLevelPermissions.GET_COMPANY_USERS_WORKLOG
      )
    ) {
      return true;
    } else {
      return false;
    }
  };
  const isAuthorizedForProjectMembers = (): boolean => {
    if (
      getProjectPermissions(userCustomClaim, projectState).includes(
        ProjectLevelPermissions.GET_PROJECT_USERS
      ) &&
      getProjectPermissions(userCustomClaim, projectState).includes(
        ProjectLevelPermissions.GET_PROJECT_MEMBER_WORKLOG
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getMembers = async () => {
    if (props.selectedCompany && props.selectedCompany.id) {
      if (isAuthorizedForCompanyMembers()) {
        props.setIsLoading(true);
        props.setSelectedMember({} as DropdownItem);
        const membersList = await getCompanyUsersList(props.selectedCompany.id);
        const newMembers = addAllInDropdown(membersList, "Members");
        const dropdownMembersData = newMembers.map(
          (members) =>
            (members = { value: members.id, label: members.name } as any)
        );
        setMembers(dropdownMembersData);
        props.setIsLoading(false);
      } else if (isAuthorizedForProjectMembers()) {
        props.setIsLoading(true);
        props.setSelectedMember({} as DropdownItem);
        const membersList = await getProjectMembersAndRoles(
          props.selectedCompany.id,
          "",
          getGlobalProject(projectState).projectId ?? ""
        );
        const mappedList = membersList.map(
          (x) => ({ id: x.userId, name: x.userName } as DropdownItem)
        );
        const newMembers = addAllInDropdown(mappedList, "Members");
        const dropdownMembersData = newMembers.map(
          (members) =>
            (members = { value: members.id, label: members.name } as any)
        );
        setMembers(dropdownMembersData);
        props.setIsLoading(false);
      } else {
        props.setSelectedMember({
          id: userId,
          name: userName,
        } as DropdownItem);
      }
    }
  };

  const getProjects = async () => {
    const selectedGlobalProject = getGlobalProject(projectState);
    if (
      selectedGlobalProject.projectId.length === 0 &&
      hasPermission(
        userCustomClaim,
        CompanyLevelPermissions.GET_COMPANY_PROJECTS
      )
    ) {
      if (props.selectedCompany && props.selectedCompany.id) {
        props.setIsLoading(true);
        props.setSelectedProject({} as DropdownItem);
        const projectsList = await getAllProjectsList(props.selectedCompany.id);
        const newProjectsList = addAllInDropdown(projectsList, "Projects");
        const dropdownCompaniesData = newProjectsList.map(
          (projects) =>
            (projects = { value: projects.id, label: projects.name } as any)
        );
        setProjects(dropdownCompaniesData);
        props.setIsLoading(false);
      }
    } else {
      props.setSelectedProject({
        id: selectedGlobalProject.projectId,
        name: selectedGlobalProject.projectName,
      });
    }
  };

  const getCompaniesList = async () => {
    const companyId = getCompanyId(userCustomClaim);
    if (companyId.length === 0) {
      setCompaniesLoading(true);

      const companiesList = await getAllCompaniesList();
      const dropdownCompaniesData = companiesList.map(
        (company) =>
          (company = { value: company.id, label: company.name } as any)
      );
      setCompanies(dropdownCompaniesData);
      if (companiesList && companiesList.length > 0) {
        props.setSelectedCompany(companiesList[0]);
      }
      setCompaniesLoading(false);
    } else {
      props.setSelectedCompany({ id: companyId, name: "" } as DropdownItem);
    }
  };
  const OnChangeHandler = (e: any, fieldName: string) => {
    const value = e.value === "ALL" ? "" : e.value;
    const label =
      e.label === "All Projects" || e.label === "All Members" ? "" : e.label;
    const dropDownObj = { id: value, name: label };
    fieldName === "Members"
      ? props.setSelectedMember(dropDownObj)
      : props.setSelectedProject(dropDownObj);
  };

  return (
    <>
      <Grid
        container
        spacing={0}
        justifyContent={"center"}
        className={`${isAdmin(userCustomClaim) ? "w-[85%]" : "w-[100%]"}`}
      >
        {isAdmin(userCustomClaim) && (
          <>
            {error.isError && <Alert message={error.message} />}
            <Grid item xs={2} className="text-center">
              {companiesLoading && <Skeleton height={35} width={180} />}

              {!companiesLoading && (
                <Select
                  className="w-52"
                  options={companies}
                  placeholder="Companies"
                  isMulti={false}
                  onChange={(e: any) => {
                    const companyObj = { id: e.value, name: e.label };
                    props.setSelectedCompany(companyObj);
                  }}
                  isSearchable={false}
                />
              )}
            </Grid>
            <Grid item xs={2} className="text-center">
              <Select
                className="w-52"
                options={projects}
                placeholder="Projects"
                isMulti={false}
                onChange={(e) => OnChangeHandler(e, "Projects")}
                isSearchable={false}
              />
            </Grid>
          </>
        )}

        {isCompanyAdmin(userCustomClaim) && (
          <>
            {error.isError && <Alert message={error.message} />}
            <Grid item xs={2} className="text-center">
              <Select
                className="w-52"
                options={projects}
                placeholder="Projects"
                isMulti={false}
                onChange={(e) => OnChangeHandler(e, "Projects")}
                isSearchable={false}
              />
            </Grid>
          </>
        )}

        {error.isError && <Alert message={error.message} />}
        {(isAuthorizedForProjectMembers() ||
          isAuthorizedForCompanyMembers()) && (
          <Grid item xs={2} className="text-center">
            <Select
              className="w-52"
              options={members}
              placeholder="Members"
              isMulti={false}
              onChange={(e) => OnChangeHandler(e, "Members")}
              isSearchable={false}
            />
          </Grid>
        )}

        <Grid item xs={2} className="text-center">
          <Select
            className="w-52"
            options={billables}
            placeholder="Billables"
            isMulti={false}
            onChange={(e: any) => {
              props.setBillable(e.value);
            }}
            isSearchable={false}
          />
        </Grid>

        <Grid item xs={2} className="text-center">
          <div className="w3-container">
            <DateRangePickerComponent
              format="dd-MMM-yy"
              onChange={(range: any) => {
                props.setStartDate(range.value[0]);
                props.setEndDate(range.value[1]);
              }}
              showClearButton={false}
              startDate={props.startDate}
              endDate={props.endDate}
              delayUpdate={true}
            />
          </div>
        </Grid>
        <Grid item xs={2} className="flex justify-end text-center">
          <Button
            onClick={() => props.getWorkLogs()}
            startIcon={<SearchIcon />}
            variant="contained"
          >
            Find Timelogs
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        className="my-2 mx-[120px]"
        justifyContent={"flex-start"}
      >
        <Grid item xs={12}>
          <Typography variant="caption" className="text-gray-600">
            {" "}
            Please click on "Find Timelogs" to display results{" "}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

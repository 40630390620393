import axios, { AxiosResponse } from "axios";
import { addNotificationToApp, isSuccessCode } from "../../Common/Common";
import { axiosClient } from "./Interceptor";

export const postData = async (
  path: string,
  // eslint-disable-next-line @typescript-eslint/ban-types
  body: Object
): Promise<AxiosResponse> => {
  try {
    const res = await axiosClient.post(path, body);
    if (isSuccessCode(res.status)) {
      addNotificationToApp("Success", "Added Successfully!", "success");
    } else {
      addNotificationToApp("Error", "Failed to add data", "danger");
    }
    return res;
  } catch (e: any) {
    addNotificationToApp(
      "Error",
      e?.response?.data?.error?.message ?? "Failed to add data",
      "danger"
    );
    return {} as AxiosResponse;
  }
};

export const getData = async (path: string): Promise<any> => {
  try {
    const res = await axiosClient.get(path);
    if (isSuccessCode(res.status)) {
      return res.data;
    } else {
      addNotificationToApp("Error", "Failed to get data", "danger");
    }
  } catch (e: any) {
    addNotificationToApp(
      "Error",
      e?.response?.data?.error?.message ?? "Failed to get data",
      "danger"
    );
    return undefined;
  }
};

export const updateData = async (
  path: string,
  // eslint-disable-next-line @typescript-eslint/ban-types
  body: Object
): Promise<AxiosResponse> => {
  try {
    const res = await axios.put(path, body);
    if (isSuccessCode(res.status)) {
      addNotificationToApp("Success", "Updated Successfully!", "success");
    } else {
      addNotificationToApp("Error", "Failed to update data", "danger");
    }
    return res;
  } catch (e: any) {
    addNotificationToApp(
      "Error",
      e?.response?.data?.error?.message ?? "Failed to update data",
      "danger"
    );
    return {} as AxiosResponse;
  }
};
export const deleteData = async (path: string): Promise<AxiosResponse> => {
  try {
    const res = await axios.delete(path);
    if (isSuccessCode(res.status)) {
      addNotificationToApp("Success", "Deleted Successfully!", "success");
    } else {
      addNotificationToApp("Error", "Failed to delete data", "danger");
    }
    return res;
  } catch (e: any) {
    addNotificationToApp(
      "Error",
      e?.response?.data?.error?.message ?? "Failed to delete data",
      "danger"
    );
    return {} as AxiosResponse;
  }
};

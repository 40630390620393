import { Dropdown, SplitButton } from "react-bootstrap";
import { DropdownItem } from "../../../Common/Interfaces/dashboard";
import { AppDropdownProps } from "../../../Common/Interfaces/dropdown";

export default function AppDropdown(props: AppDropdownProps) {
  return (
    <SplitButton
      variant="default"
      className={`w3-container border-purple mb-2 ${props.additionalClasses}`}
      title={props.title}
      onSelect={(key) =>
        props.saveObject
          ? props.setValue(props.list.filter((x) => x.id === key)[0])
          : props.setValue(props.list.filter((x) => x.id === key)[0].id)
      }
    >
      {props.list &&
        props.list.map((item: DropdownItem) => {
          return (
            <Dropdown.Item key={item.id} eventKey={item.id}>
              {item.name}
            </Dropdown.Item>
          );
        })}
    </SplitButton>
  );
}

import React, { useMemo } from "react";
import { useTable, useSortBy } from "react-table";

import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const BasicTable = ({ ...args }) => {
  const { tableColumns, tableData, headerColor, maxHeight, tableHooks } = args;
  const columns = useMemo(() => tableColumns, []);
  const data = useMemo(() => tableData, []);
  const tableInstance = useTable(
    {
      columns: columns as any,
      data,
    },
    useSortBy,
    tableHooks
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <>
      <Paper className="w-full overflow-hidden		drop-shadow-xl">
        <TableContainer
          className={`scrollbar-hide`}
          sx={{ maxHeight: maxHeight }}
        >
          <Table {...getTableProps()} stickyHeader aria-label="sticky table">
            <TableHead>
              {headerGroups.map((headerGroup) => {
                return (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => {
                      return (
                        <TableCell
                          className={`text-white ${headerColor} min-w-170`}
                          align="left"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}

                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <ArrowDropDownIcon />
                              ) : (
                                <ArrowDropUpIcon />
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {rows.map((row: any) => {
                prepareRow(row);
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell: any) => {
                      return (
                        <TableCell align="left" {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default BasicTable;

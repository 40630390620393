import { LOADING } from "../Constants";
import { ILoaderSuccess } from "../model/ActionsInterface.d";
import { LoadingState } from "../model/LoadingState.d";

const initialState: LoadingState = {
  isLoading: false,
};

const LoaderReducer = (state = initialState, action: ILoaderSuccess) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        isLoading: action.LoadingState.isLoading,
      };
    default:
      return state;
  }
};

export default LoaderReducer;

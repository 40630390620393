import { StylesConfig } from "react-select";
import Select from "react-select";
import {
  enumStringToText,
  getAttendanceStatusColor,
} from "../../../Common/Common";
import { AttendanceStatus } from "../../../Common/Enums/dashboard";
import { AttendanceDropdownProps } from "../../../Common/Interfaces/Attendance/attendance-dropdown";
import { AttendanceStatusOption } from "../../../Common/Interfaces/Attendance/attendance-status-option";

export default function AttendanceDropdown(props: AttendanceDropdownProps) {
  const attendanceStatusOptions = Object.keys(AttendanceStatus).map(
    (status) => {
      return {
        value: status as AttendanceStatus,
        label: enumStringToText(status),
        color: getAttendanceStatusColor(status),
      } as AttendanceStatusOption;
    }
  );

  const dot = (color = "transparent") => ({
    alignItems: "center",
    display: "flex",

    ":before": {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: "block",
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });

  const colourStyles: StylesConfig<AttendanceStatusOption> = {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,

        backgroundColor: isSelected ? data.color : "default",
        color: isSelected ? "white" : data.color,
        cursor: isDisabled ? "not-allowed" : "default",
        ":active": {
          ...styles[":active"],
          backgroundColor: isSelected ? data.color : "default",
          color: isSelected ? "white" : "default",
        },
      };
    },
    input: (styles) => ({ ...styles, ...dot(), width: props.width }),
    placeholder: (styles) => ({ ...styles, ...dot("#ccc") }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
    control: (base) => ({
      ...base,
      fontSize: "small",
    }),
    menu: (base) => ({
      ...base,
      fontSize: "small",
    }),
  };

  return (
    <Select
      options={attendanceStatusOptions}
      defaultValue={
        {
          value: props.attendanceStatus,
          label: enumStringToText(props.attendanceStatus),
          color: getAttendanceStatusColor(props.attendanceStatus),
        } as AttendanceStatusOption
      }
      onChange={(e) => props.onChange(e as AttendanceStatusOption)}
      styles={colourStyles}
      menuPortalTarget={props.target.tagName ? props.target : null}
      isMulti={false}
      isDisabled={props.isDisabled}
    />
  );
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import { AxiosResponse } from "axios";
import { Group } from "../../Common/Interfaces/Allocation/group";
import { ProjectGroup } from "../../Common/Interfaces/Allocation/project-group";
import {
  getData,
  postData,
  updateData,
} from "../axios-calls/common-axios-service";

export const getProjectGroups = async (
  companyId: string
): Promise<ProjectGroup[]> => {
  const items = await getData(`companies/${companyId}/groups/projectGroup`);
  return items;
};

export const getGroups = async (companyId: string): Promise<Group[]> => {
  const items = await getData(`companies/${companyId}/groups`);
  return items;
};

export const updateGroups = async (
  companyId: string,
  body: ProjectGroup[]
): Promise<AxiosResponse> => {
  const res = await updateData(
    `companies/${companyId}/groups/projectGroup`,
    body
  );
  return res as AxiosResponse;
};

export const postGroups = async (companyId: string): Promise<AxiosResponse> => {
  const body = {};
  const res = await postData(`companies/${companyId}/groups`, body);
  return res as AxiosResponse;
};

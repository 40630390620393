import FileSaver from "file-saver";
import JSZip from "jszip";
import { SalaryDataItem } from "../../Common/Interfaces/Salary/ISalaryItems";

const generateZipFile = async (salaryData: SalaryDataItem[]) => {
  const zip = new JSZip();

  // Iterate through the array and add each file to the zip archive
  for (const fileObj of salaryData) {
    const { data, name } = fileObj;
    zip.file(name, data.data);
  }

  // Generate the zip file asynchronously
  const zipBlob = await zip.generateAsync({ type: "blob" });

  // Trigger the download using the FileSaver library
  FileSaver.saveAs(zipBlob, "Employees_Salary_Slips.zip");
};

export default generateZipFile;

import * as React from "react";

import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Divider, TextField } from "@mui/material";
import { DayNumber } from "../../../../Common/Enums/day-number";

const EditItemModal = ({
  row,
  showModal,
  handleEdit,
  handleClose,
  textFieldValuesMap,
  setTextFieldValuesMap,
}: any) => {
  const handleUpdateItem = (
    id: string,
    dayNumber: DayNumber,
    itemName: string
  ) => {
    handleEdit(id, dayNumber, itemName);
  };

  return (
    <div>
      <Modal
        open={showModal}
        onClose={handleClose}
        closeAfterTransition
        className="flex p-10 items-center justify-center w-[500px] top-[50%] left-[40%] right-[60%] bottom-[50%]"
      >
        <div className="bg-white w-[300px] rounded-2xl">
          <div className="p-2">
            <Typography id="spring-modal-title" variant="h6" component="h2">
              Edit Item
            </Typography>
            <Divider className="border-1 bg-gray-750 my-2" />
          </div>

          <div className="p-4">
            <TextField
              type={"text"}
              id={row?.dayNumber}
              key={row?.dayNumber}
              placeholder={"Enter Menu Item"}
              onChange={(e: any) => {
                const amap = textFieldValuesMap.clone();
                amap.set(row?.dayNumber, e.target.value);
                setTextFieldValuesMap(amap);
              }}
              defaultValue={row?.itemName || ""}
              fullWidth
            />
          </div>
          <div className="float-right my-2 px-3">
            <Button
              aria-label="add"
              color="primary"
              onClick={handleClose}
              variant="text"
            >
              <span className="text-sm">Close</span>
            </Button>{" "}
            <Button
              aria-label="add"
              color="primary"
              onClick={() =>
                handleUpdateItem(
                  row?.id,
                  row?.dayNumber,
                  textFieldValuesMap.get(row?.dayNumber) || ""
                )
              }
              variant="contained"
            >
              <span className="text-sm">Save</span>
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EditItemModal;

import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";

import { Tab, Tabs } from "@mui/material";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import RestaurantMenuOutlinedIcon from "@mui/icons-material/RestaurantMenuOutlined";

import { hasPermission } from "../../../Common/Common";
import { CompanyLevelPermissions } from "../../../Common/Enums/permissions";

import { ReduxState } from "../../../redux/model/ReduxState.d";
import { UserCustomClaim } from "../../../redux/model/UserState.d";

import { useLogEvent } from "../Analytics/hooks/useLogEvent";

import MenuTabNew from "./MenuTab/MenuTabNew";
import OrdersTabNew from "./OrdersTab/OrdersTabNew";

export default function LunchAllocationTab() {
  const navigate = useNavigate();
  const userCustomClaim = useSelector<ReduxState, UserCustomClaim>(
    (state: ReduxState) => state.UserReducer?.userPermission?.userCustomClaim
  );
  useLogEvent("page_view", "Lunch Allocation");

  const handleTabChange = (event: React.SyntheticEvent, tabValue: string) => {
    navigate(tabValue);
  };

  const getTab = (): string => {
    return hasPermission(
      userCustomClaim,
      CompanyLevelPermissions.GET_LUNCH_MENU
    )
      ? "menu"
      : "orders";
  };
  return (
    <>
      <Tabs
        value={location.pathname.split("/").pop()}
        aria-label="basic tabs example"
        onChange={handleTabChange}
        className="h-[60px]"
      >
        {hasPermission(
          userCustomClaim,
          CompanyLevelPermissions.GET_LUNCH_MENU
        ) && (
          <Tab
            label="Menu"
            value="menu"
            iconPosition="start"
            icon={<RestaurantMenuOutlinedIcon />}
          />
        )}
        {hasPermission(
          userCustomClaim,
          CompanyLevelPermissions.GET_USER_FOOD_ORDER
        ) && (
          <Tab
            label="Orders"
            value="orders"
            iconPosition="start"
            icon={<SummarizeOutlinedIcon />}
          />
        )}
      </Tabs>
      <Outlet />
      <Routes>
        <Route path="menu" element={<MenuTabNew />} />
        <Route path="orders" element={<OrdersTabNew />} />
        <Route path="/" element={<Navigate replace to={getTab()} />} />
      </Routes>
    </>
  );
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import { AxiosResponse } from "axios";
import { Menu } from "../../Common/Interfaces/Lunch/menu-item";
import {
  getData,
  postData,
  updateData,
} from "../axios-calls/common-axios-service";

export const getWeeklyMenu = async (companyId: string): Promise<Menu[]> => {
  const items = await getData(`companies/${companyId}/menus`);
  return items;
};

export const updateMenuItem = async (
  companyId: string,
  menuId: string,
  menu: Menu
): Promise<AxiosResponse> => {
  const res = await updateData(`companies/${companyId}/menus/${menuId}`, menu);
  return res as AxiosResponse;
};

export const postMenu = async (
  companyId: string,
  menu: Menu[]
): Promise<AxiosResponse> => {
  const body = { menu: menu };
  const res = await postData(`companies/${companyId}/menus`, body);
  return res as AxiosResponse;
};

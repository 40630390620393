const AuroraLogoFull: React.FC = () => {
  const svgStyles = {
    fill1: "url(#linear-gradient-1)",
    fill2: "url(#linear-gradient-2)",
    fill3: "url(#linear-gradient-3)",
    fill4: "url(#linear-gradient-4)",
    fill5: "url(#linear-gradient-5)",
    fill6: "url(#linear-gradient-6)",
    fill7: "url(#linear-gradient-7)",
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1151.35 238.03"
    >
      <defs>
        <style>
          .cls-1{svgStyles.fill1}.cls-2{svgStyles.fill2}.cls-3
          {svgStyles.fill3}
          .cls-4{svgStyles.fill4}.cls-5{svgStyles.fill5}.cls-6
          {svgStyles.fill6}
        </style>
        <linearGradient
          id="linear-gradient"
          x1="111.27"
          y1="117.28"
          x2="152.01"
          y2="92.49"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#0048c0" />
          <stop offset="0.99" stop-color="#003180" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="167.3"
          y1="85.21"
          x2="207"
          y2="62.75"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#ff9a35" />
          <stop offset="0.99" stop-color="#f2b005" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-3"
          x1="4.29"
          y1="167.36"
          x2="100.34"
          y2="137"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="112.69"
          y1="234.09"
          x2="207.21"
          y2="132.01"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#24a638" />
          <stop offset="0.99" stop-color="#168c55" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-5"
          x1="15.99"
          y1="56.24"
          x2="146.31"
          y2="27.64"
          xlinkHref="#linear-gradient-4"
        />
        <linearGradient
          id="linear-gradient-6"
          x1="-2.83"
          y1="181.82"
          x2="38.7"
          y2="159.05"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-7"
          x1="0%"
          y1="0%"
          x2="100%"
          y2="0%"
        >
          <stop offset="100%" stopColor="white" stopOpacity="1" />
          <stop offset="100%" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            fill="url(#linear-gradient-7)"
            d="M295.89,103.08l10.87,31.52-21.79,0Zm-8.73-17.5L260,161h15.87l5-14.34,30,0L315.82,161h16l-27-75.45Z"
          />
          <path
            fill="url(#linear-gradient-7)"
            d="M398.42,161.1H385.17l-2-7.55c-3.78,5.07-10.36,8.3-17.81,8.3-13.93,0-24-9.08-23.95-25.49l0-35.08h15.12l0,32.92c0,9.61,5.27,14.79,13.26,14.8,8.2,0,13.39-5.18,13.39-14.78l0-32.93h15.21Z"
          />
          <path
            fill="url(#linear-gradient-7)"
            d="M428.47,161.12H413.36l0-59.8h13.15l1.95,9.29a20.77,20.77,0,0,1,18.36-10.13v15.87h-4c-9,0-14.35,3.45-14.36,15Z"
          />
          <path
            fill="url(#linear-gradient-7)"
            d="M483.53,162.13c-17.27,0-30.32-12.11-30.3-30.89s13.51-30.86,30.78-30.85,30.75,12,30.74,30.89-13.84,30.86-31.22,30.85m0-13.17c8,0,15.66-5.81,15.66-17.69s-7.43-17.71-15.42-17.71-15.22,5.71-15.23,17.69,6.9,17.71,15,17.71"
          />
          <path
            fill="url(#linear-gradient-7)"
            d="M539,161.2H523.92l0-59.8h13.15l1.95,9.28a20.76,20.76,0,0,1,18.36-10.13v15.87h-4c-8.95,0-14.35,3.44-14.36,15Z"
          />
          <path
            fill="url(#linear-gradient-7)"
            d="M591,100.46c9.61,0,16.18,4.55,19.74,9.52l2-8.53H626l0,59.8H610.72V152.5c-3.56,5.18-10.36,9.71-19.86,9.7-15.11,0-27.19-12.43-27.18-31.1s12.11-30.65,27.33-30.64m3.87,13.29c-8.09,0-15.76,6-15.76,17.36s7.65,17.82,15.74,17.82c8.31,0,15.87-6.25,15.88-17.58s-7.55-17.6-15.86-17.6"
          />
          <path
            fill="url(#linear-gradient-7)"
            d="M692.47,162.05c-15.65,0-27.73-8.22-27.93-22.47h16.19c.42,6,4.42,10,11.43,10s11.34-3.77,11.34-9.17c0-16.29-38.85-6.49-38.73-33.81,0-13.59,11-21.78,26.57-21.77s26,7.89,27,21.49H701.69c-.33-5-4.31-8.85-10.79-9-5.93-.22-10.36,2.7-10.37,8.84,0,15.12,38.63,6.72,38.61,33.27,0,11.88-9.51,22.56-26.67,22.54"
          />
          <path
            fill="url(#linear-gradient-7)"
            d="M756.82,162.31c-17.27,0-30.32-12.11-30.31-30.89s13.51-30.86,30.78-30.85,30.75,12,30.74,30.89-13.83,30.86-31.21,30.85m0-13.17c8,0,15.65-5.81,15.66-17.69s-7.43-17.7-15.42-17.71-15.22,5.71-15.23,17.69,6.9,17.71,15,17.71"
          />
          <rect
            fill="url(#linear-gradient-7)"
            x="799.2"
            y="81.51"
            width="15.11"
            height="79.87"
          />
          <path
            fill="url(#linear-gradient-7)"
            d="M883.67,161.43H870.42l-2-7.55c-3.78,5.06-10.36,8.29-17.81,8.29-13.93,0-24-9.08-23.95-25.49l0-35.08h15.12l0,32.93c0,9.6,5.27,14.79,13.26,14.79,8.2,0,13.39-5.17,13.39-14.78l0-32.92h15.22Z"
          />
          <path
            fill="url(#linear-gradient-7)"
            d="M901,114.06h-7.13V101.64H901l2-14.79h13.26v14.79h13.38v12.41H916.23l0,28.92c0,4,1.62,5.72,6.37,5.73h7v12.74h-9.49c-11.44,0-19.11-4.86-19.1-18.58Z"
          />
          <path
            fill="url(#linear-gradient-7)"
            d="M938.93,85.7c0-5,3.9-8.86,9.3-8.85a8.92,8.92,0,0,1,9.27,8.86c0,5-4,8.84-9.29,8.84s-9.28-3.89-9.28-8.85m1.62,16h15.11l0,59.8H940.51Z"
          />
          <path
            fill="url(#linear-gradient-7)"
            d="M995.12,162.47c-17.28,0-30.33-12.11-30.31-30.89s13.51-30.86,30.78-30.85,30.75,12,30.74,30.89-13.84,30.86-31.21,30.85m0-13.17c8,0,15.66-5.81,15.66-17.69s-7.43-17.71-15.42-17.71-15.22,5.71-15.23,17.69,6.9,17.71,15,17.71"
          />
          <path
            fill="url(#linear-gradient-7)"
            d="M1077.4,128.53c0-9.6-5.28-14.79-13.27-14.8-8.2,0-13.49,5.18-13.49,14.78l0,33H1035.5l0-59.8h13.15l2,7.45c3.89-5.06,10.36-8.3,18-8.3,13.93,0,23.85,9.08,23.84,25.6l0,35.08h-15.11Z"
          />
          <path
            fill="url(#linear-gradient-7)"
            d="M1127.8,162.56c-15,0-25.47-8.77-26.1-19.88h15.22c.53,4.54,4.73,8,10.78,8s9.17-2.69,9.18-6.15c0-10.68-33.78-3.91-33.77-25.81,0-9.94,8.87-17.92,23.65-17.91,14.46,0,23.2,7.89,24.06,19.76h-14.47c-.43-4.63-4.09-7.76-10-7.77-5.61,0-8.52,2.26-8.52,5.72,0,10.9,33.12,4.12,33.55,26,0,10.26-9,18-23.55,18"
          />
          <path
            fill="url(#linear-gradient-3)"
            className="cls-1"
            d="M61.68,89.39a3.35,3.35,0,0,0,1.69,2.93l42.86,24.74a5.66,5.66,0,0,1,2.82,4.87l0,49.45a3.38,3.38,0,0,0,5.06,2.92l40.48-23.45a3.39,3.39,0,0,0,1.68-2.92V93.23a3.38,3.38,0,0,0-1.68-2.92l-47.2-27.25a3.35,3.35,0,0,0-3.37,0L63.37,86.47A3.37,3.37,0,0,0,61.68,89.39Z"
          />
          <path
            fill="url(#linear-gradient-2)"
            className="cls-2"
            d="M116.05,58.1A3.33,3.33,0,0,0,117.74,61L160.6,85.77a5.61,5.61,0,0,1,2.81,4.86l0,49.46a3.37,3.37,0,0,0,5.06,2.91L209,119.55a3.38,3.38,0,0,0,1.68-2.92V61.93A3.38,3.38,0,0,0,209,59l-47.2-27.25a3.4,3.4,0,0,0-1.69-.45,3.36,3.36,0,0,0-1.68.45L117.74,55.18A3.35,3.35,0,0,0,116.05,58.1Z"
          />
          <path
            fill="url(#linear-gradient-3)"
            className="cls-3"
            d="M55.67,212.91,97.3,237.55a3.32,3.32,0,0,0,3.39,0,3.36,3.36,0,0,0,1.7-2.93h0l-.09-110.11a3.39,3.39,0,0,0-1.69-2.92L6,67.2a3.36,3.36,0,0,0-1.68-.45,3.41,3.41,0,0,0-1.66.44A3.33,3.33,0,0,0,.92,70.06L.07,116.57a3.39,3.39,0,0,0,1.68,3l49.47,28.86A5.63,5.63,0,0,1,54,153.26V210A3.38,3.38,0,0,0,55.67,212.91Z"
          />
          <path
            fill="url(#linear-gradient-4)"
            className="cls-4"
            d="M208.73,129.25a3.42,3.42,0,0,0-1.69-.45,3.33,3.33,0,0,0-1.69.46l-94.63,54.83A3.38,3.38,0,0,0,109,187L109,234a3.38,3.38,0,0,0,5.06,2.93l94.71-54.41a3.37,3.37,0,0,0,1.69-2.93V132.18A3.36,3.36,0,0,0,208.73,129.25Z"
          />
          <path
            fill="url(#linear-gradient-4)"
            className="cls-5"
            d="M7.56,58.15a3.34,3.34,0,0,0,1.68,2.92L49.87,84.52a3.36,3.36,0,0,0,3.36,0L148,30A3.37,3.37,0,0,0,149.7,27,3.33,3.33,0,0,0,148,24.12L107.45.46a3.32,3.32,0,0,0-1.7-.46,3.36,3.36,0,0,0-1.68.45L9.24,55.23A3.34,3.34,0,0,0,7.56,58.15Z"
          />
          <path
            fill="url(#linear-gradient-2)"
            className="cls-6"
            d="M45.59,152.93,5.07,129.3a3.33,3.33,0,0,0-3.38,0A3.32,3.32,0,0,0,0,132.21v47.31a3.4,3.4,0,0,0,1.72,2.95h0L42.34,205.3a3.37,3.37,0,0,0,5-3l-.09-46.51A3.43,3.43,0,0,0,45.59,152.93Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default AuroraLogoFull;

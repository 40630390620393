import { Dispatch } from "react";
import { LOADING } from "../Constants";
import { LoadingState } from "../model/LoadingState.d";
import { ILoaderSuccess } from "../model/ActionsInterface.d";

export const setLoader =
  (loading: LoadingState) => (dispatch: Dispatch<ILoaderSuccess>) => {
    dispatch({
      type: LOADING,
      LoadingState: loading,
    });
  };

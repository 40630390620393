const AuroraLogo: React.FC = () => {
  const svgStyles = {
    fill1: "url(#linear-gradient-1)",
    fill2: "url(#linear-gradient-2)",
    fill3: "url(#linear-gradient-3)",
    fill4: "url(#linear-gradient-4)",
    fill5: "url(#linear-gradient-5)",
    fill6: "url(#linear-gradient-6)",
    fill7: "url(#linear-gradient-7)",
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1151.35 238.03"
    >
      <defs>
        <style>
          .cls-1{svgStyles.fill1}.cls-2{svgStyles.fill2}.cls-3{svgStyles.fill3}
          .cls-4{svgStyles.fill4}.cls-5{svgStyles.fill5}.cls-6{svgStyles.fill6}
        </style>
        <linearGradient
          id="linear-gradient"
          x1="111.27"
          y1="117.28"
          x2="152.01"
          y2="92.49"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#0048c0" />
          <stop offset="0.99" stop-color="#003180" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="167.3"
          y1="85.21"
          x2="207"
          y2="62.75"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#ff9a35" />
          <stop offset="0.99" stop-color="#f2b005" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-3"
          x1="4.29"
          y1="167.36"
          x2="100.34"
          y2="137"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="112.69"
          y1="234.09"
          x2="207.21"
          y2="132.01"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#24a638" />
          <stop offset="0.99" stop-color="#168c55" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-5"
          x1="15.99"
          y1="56.24"
          x2="146.31"
          y2="27.64"
          xlinkHref="#linear-gradient-4"
        />
        <linearGradient
          id="linear-gradient-6"
          x1="-2.83"
          y1="181.82"
          x2="38.7"
          y2="159.05"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-7"
          x1="0%"
          y1="0%"
          x2="100%"
          y2="0%"
        >
          <stop offset="100%" stopColor="white" stopOpacity="1" />
          <stop offset="100%" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            fill="url(#linear-gradient-3)"
            className="cls-1"
            d="M61.68,89.39a3.35,3.35,0,0,0,1.69,2.93l42.86,24.74a5.66,5.66,0,0,1,2.82,4.87l0,49.45a3.38,3.38,0,0,0,5.06,2.92l40.48-23.45a3.39,3.39,0,0,0,1.68-2.92V93.23a3.38,3.38,0,0,0-1.68-2.92l-47.2-27.25a3.35,3.35,0,0,0-3.37,0L63.37,86.47A3.37,3.37,0,0,0,61.68,89.39Z"
          />
          <path
            fill="url(#linear-gradient-2)"
            className="cls-2"
            d="M116.05,58.1A3.33,3.33,0,0,0,117.74,61L160.6,85.77a5.61,5.61,0,0,1,2.81,4.86l0,49.46a3.37,3.37,0,0,0,5.06,2.91L209,119.55a3.38,3.38,0,0,0,1.68-2.92V61.93A3.38,3.38,0,0,0,209,59l-47.2-27.25a3.4,3.4,0,0,0-1.69-.45,3.36,3.36,0,0,0-1.68.45L117.74,55.18A3.35,3.35,0,0,0,116.05,58.1Z"
          />
          <path
            fill="url(#linear-gradient-3)"
            className="cls-3"
            d="M55.67,212.91,97.3,237.55a3.32,3.32,0,0,0,3.39,0,3.36,3.36,0,0,0,1.7-2.93h0l-.09-110.11a3.39,3.39,0,0,0-1.69-2.92L6,67.2a3.36,3.36,0,0,0-1.68-.45,3.41,3.41,0,0,0-1.66.44A3.33,3.33,0,0,0,.92,70.06L.07,116.57a3.39,3.39,0,0,0,1.68,3l49.47,28.86A5.63,5.63,0,0,1,54,153.26V210A3.38,3.38,0,0,0,55.67,212.91Z"
          />
          <path
            fill="url(#linear-gradient-4)"
            className="cls-4"
            d="M208.73,129.25a3.42,3.42,0,0,0-1.69-.45,3.33,3.33,0,0,0-1.69.46l-94.63,54.83A3.38,3.38,0,0,0,109,187L109,234a3.38,3.38,0,0,0,5.06,2.93l94.71-54.41a3.37,3.37,0,0,0,1.69-2.93V132.18A3.36,3.36,0,0,0,208.73,129.25Z"
          />
          <path
            fill="url(#linear-gradient-4)"
            className="cls-5"
            d="M7.56,58.15a3.34,3.34,0,0,0,1.68,2.92L49.87,84.52a3.36,3.36,0,0,0,3.36,0L148,30A3.37,3.37,0,0,0,149.7,27,3.33,3.33,0,0,0,148,24.12L107.45.46a3.32,3.32,0,0,0-1.7-.46,3.36,3.36,0,0,0-1.68.45L9.24,55.23A3.34,3.34,0,0,0,7.56,58.15Z"
          />
          <path
            fill="url(#linear-gradient-2)"
            className="cls-6"
            d="M45.59,152.93,5.07,129.3a3.33,3.33,0,0,0-3.38,0A3.32,3.32,0,0,0,0,132.21v47.31a3.4,3.4,0,0,0,1.72,2.95h0L42.34,205.3a3.37,3.37,0,0,0,5-3l-.09-46.51A3.43,3.43,0,0,0,45.59,152.93Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default AuroraLogo;

import { FeedbackType } from "../Enums/feedback-type";
import ActionProvider from "./ActionProvider";

class MessageParser {
  actionProvider: ActionProvider;

  constructor(actionProvider: ActionProvider) {
    this.actionProvider = actionProvider;
  }

  parse = (message: string): void => {
    const uppercase = message.toUpperCase();
    if (
      uppercase == FeedbackType.ANONYMOUS ||
      uppercase == FeedbackType.NON_ANONYMOUS
    ) {
      return this.actionProvider.addFeedbackType(uppercase);
    }
    return this.actionProvider.addFeedback(message);
  };
}

export default MessageParser;

import * as React from "react";
// import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";

const DescriptionModal = ({
  setToggleDescriptionModal,
  toggleDescriptionModal,
  descriptionModalData,
}: any) => {
  const handleOpen = () => setToggleDescriptionModal(true);
  const handleClose = () => setToggleDescriptionModal(false);

  return (
    <div>
      <Button onClick={handleOpen}>Open modal</Button>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={toggleDescriptionModal}
        onClose={handleClose}
        closeAfterTransition
        className="flex p-10 items-center justify-center"
      >
        <div
          className={`scrollbar-hide absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[500px] ${
            descriptionModalData.length > 575 ? "h-[500px]" : "h-auto"
          } bg-white shadow-24 rounded-2 p-10 transition-opacity duration-300 ease-in ${
            toggleDescriptionModal ? "opacity-100" : "opacity-0"
          } overflow-y-scroll`}
        >
          <Typography id="spring-modal-title" variant="h6" component="h2">
            Description
          </Typography>
          <Divider className="border-1 bg-gray-750 my-2" />

          <Typography id="spring-modal-description" className="mt-2">
            {descriptionModalData}{" "}
          </Typography>
        </div>
      </Modal>
    </div>
  );
};

export default DescriptionModal;

import { AxiosResponse } from "axios";
import { postData } from "../axios-calls/common-axios-service";
import { ISalaryBody } from "../../Common/Interfaces/Salary/ISalaryItems";

export const postSalaryData = async (
  salary: ISalaryBody
): Promise<AxiosResponse> => {
  const salaryResponse = await postData(`upload/csv`, salary);
  return salaryResponse as AxiosResponse;
};

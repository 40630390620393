import { LOGIN_SUCCESS, LOGOUT, UPDATE } from "../Constants";
import { UserState } from "../model/UserState.d";
import { IEventSuccess } from "../model/ActionsInterface.d";

const initialState: UserState = {
  userId: "",
  userName: "",
  userPermission: undefined,
};

const UserReducer = (state = initialState, action: IEventSuccess) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        userId: action.userState.userId,
        userName: action.userState.userName,
        userPermission: action.userState.userPermission,
      };
    case LOGOUT:
      return {
        ...state,
        userId: "",
        userName: "",
        userPermission: undefined,
      };
    case UPDATE:
      return {
        ...state,
        userId: action.userState.userId,
        userName: action.userState.userName,
        userPermission: action.userState.userPermission,
      };
    default:
      return state;
  }
};

export default UserReducer;

import { Dispatch } from "react";
import { LOGOUT, PROJECT_UPDATE } from "../Constants";
import { IEventSelectProject } from "../model/ActionsInterface.d";
import { SelectedGlobalProject } from "../model/SelectedProject.d";

const selectProjectEvent = (project: SelectedGlobalProject) => ({
  type: PROJECT_UPDATE,
  projectId: project.projectId,
  projectName: project.projectName,
});

const logoutEvent = (projectState: SelectedGlobalProject) => ({
  type: LOGOUT,
  projectId: projectState.projectId,
  projectName: projectState.projectName,
});

export const selectGlobalProject =
  (project: SelectedGlobalProject) =>
  async (dispatch: Dispatch<IEventSelectProject>) => {
    dispatch(selectProjectEvent(project));
  };

export const logout = () => async (dispatch: Dispatch<IEventSelectProject>) => {
  dispatch(
    logoutEvent({
      projectId: "",
      projectName: "",
    })
  );
};

import { z } from "zod";
// const MAX_FILE_SIZE = 500000;
// const ACCEPTED_IMAGE_TYPES = ["image/jpeg", "image/jpg", "image/png"];
export const AddEditCompanySchema = z.object({
  companyName: z.string().nonempty({ message: "Company Name is required" }),

  companyEmail: z
    .string()
    .nonempty({ message: "Company Email is required" })
    .email({
      message: "Must be a valid email",
    }),
  // companyLogo: z
  //   .any()
  //   .refine((files) => files?.length !== 1, "Logo is required.")
  //   .refine(
  //     (files) => files?.[0]?.size <= MAX_FILE_SIZE,
  //     `Max file size is 5MB.`
  //   )
  //   .refine(
  //     (files) => !ACCEPTED_IMAGE_TYPES.includes(files?.[0]?.type),
  //     ".jpg, .jpeg, .png and .webp files are accepted."
  //   ),
});
export const AddEditCompanyMemberSchema = z.object({
  memberName: z.string().nonempty({ message: "Member Name is required" }),
  memberEmail: z
    .string()
    .nonempty({ message: "Member Email is required" })
    .email({
      message: "Must be a valid email",
    }),
  memberPassword: z
    .string()
    .nonempty({ message: "Member Password is required" }),
});

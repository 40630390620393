import { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import Select from "react-select";

import Skeleton from "react-loading-skeleton";

import moment from "moment";

import EditIcon from "@mui/icons-material/Edit";
import { Button, Grid, IconButton } from "@mui/material";
import AddHomeWorkOutlinedIcon from "@mui/icons-material/AddHomeWorkOutlined";

import { getAllCompaniesList } from "../../../../../apis/services/company.service";
import { getAllHolidays } from "../../../../../apis/services/holiday.service";

import {
  enumStringToText,
  getCompanyId,
  isAdmin,
} from "../../../../../Common/Common";
import Alert from "../../../../Common/Alert/Alert";
import BasicTable from "../../../../Common/Table/BasicTable";
import { AttendanceStatus } from "../../../../../Common/Enums/dashboard";
import {
  DropdownItem,
  ReactSelect,
} from "../../../../../Common/Interfaces/dashboard";
import { HolidayAttendance } from "../../../../../Common/Interfaces/Attendance/holiday-attendance";

import { COLUMNS } from "./dataColumns";

import { ReduxState } from "../../../../../redux/model/ReduxState.d";
import { UserCustomClaim } from "../../../../../redux/model/UserState.d";

import AddEditHolidayModal from "../../AddEditHolidayModal/AddEditHolidayModal";
import { sortByName } from "../../../../../utils/helpers/sorting";

export default function HolidayAttendanceList() {
  const [holidays, setHolidays] = useState([] as HolidayAttendance[]);
  const [showModal, setShowModal] = useState(false);
  const [editHoliday, setEditHoliday] = useState({} as HolidayAttendance);
  const [isLoading, setIsLoading] = useState(false);
  const userCustomClaim = useSelector<ReduxState, UserCustomClaim>(
    (state: ReduxState) => state.UserReducer?.userPermission?.userCustomClaim
  );
  const [companiesList, setCompaniesList] = useState([] as ReactSelect[]);
  const [selectedCompany, setSelectedCompany] = useState({} as DropdownItem);

  const handleClose = () => setShowModal(false);
  const handleShow = (isEdit: boolean, holiday: HolidayAttendance) => {
    if (isEdit) {
      setEditHoliday(holiday);
    } else {
      setEditHoliday({
        Id: "",
        name: "",
        startingDate: moment(new Date()).format("MM/DD/yyyy"),
        endingDate: moment(new Date()).format("MM/DD/yyyy"),
        status: AttendanceStatus.PUBLIC_HOLIDAYS,
      } as HolidayAttendance);
    }
    setShowModal(true);
  };

  useEffect(() => {
    if (selectedCompany.id && selectedCompany.id.length > 0) {
      getHolidays();
    }
  }, [selectedCompany]);

  useEffect(() => {
    const companyId = getCompanyId(userCustomClaim);
    if (isAdmin(userCustomClaim)) {
      getCompaniesList(companyId);
    } else {
      setSelectedCompany({ id: companyId, name: "" } as DropdownItem);
    }
  }, [userCustomClaim]);

  const getCompaniesList = async (companyId: string) => {
    if (companyId.length === 0) {
      setIsLoading(true);
      const companies = await getAllCompaniesList();
      const companiesDropDownData = companies.map(
        (company) => ({ value: company.id, label: company.name } as ReactSelect)
      );
      setCompaniesList(companiesDropDownData);
      if (companies && companies.length > 0) {
        setSelectedCompany(companies[0]);
      }
      setIsLoading(false);
    } else {
      setSelectedCompany({ id: companyId, name: "" } as DropdownItem);
    }
  };

  const getHolidays = async () => {
    if (selectedCompany && selectedCompany.id) {
      setIsLoading(true);
      const fetchedHolidays = await getAllHolidays(selectedCompany.id);
      setHolidays(fetchedHolidays);
      setIsLoading(false);
    }
  };

  const addHoliday = (updatedHoliday: HolidayAttendance, isEdit = false) => {
    if (!isEdit) {
      const holidaysCopy = [...holidays, updatedHoliday];
      setHolidays(holidaysCopy);
    } else {
      const updatedHolidays = holidays.map((holiday: HolidayAttendance) => {
        if (holiday.Id === editHoliday.Id) {
          return {
            Id: editHoliday.Id,
            name: updatedHoliday.name,
            startingDate: updatedHoliday.startingDate,
            endingDate: updatedHoliday.endingDate,
            status: updatedHoliday.status,
          } as HolidayAttendance;
        } else {
          return holiday;
        }
      });
      setHolidays(updatedHolidays);
    }
    setShowModal(false);
  };

  const holidaysAvailable = (): boolean => {
    if (!holidays || (holidays && holidays.length === 0)) {
      return false;
    } else {
      return true;
    }
  };

  const tableHooks = (hooks: any) => {
    hooks.visibleColumns.push((columns: any) => [
      ...columns,
      {
        Header: "Status",
        accessor: "status",
        sortType: sortByName,
        Cell: ({ row }: any) => {
          return enumStringToText(row?.original?.status);
        },
      },
      {
        id: "date",
        Header: "Date",
        disableSortBy: true,
        Cell: ({ row }: any) => {
          return (
            <>
              <span>
                {moment(row?.original?.startingDate).format("MM/DD/yyyy")} -{" "}
                {moment(row?.original?.endingDate).format("MM/DD/yyyy")}
              </span>
            </>
          );
        },
      },
      {
        id: "holidayattendanceactions",
        Header: "Actions",
        accessor: "actions",
        disableSortBy: true,
        Cell: ({ row }: any) => {
          return (
            <>
              {
                <IconButton
                  aria-label="Edit"
                  color="primary"
                  onClick={() => handleShow(true, row.original)}
                >
                  <EditIcon fontSize="medium" />
                </IconButton>
              }
            </>
          );
        },
      },
    ]);
  };

  return (
    <>
      <div className="holiday-container mt-4">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container>
              {isAdmin(userCustomClaim) && (
                <Grid item xs={6} className="flex justify-start">
                  <Select
                    className="w-72"
                    options={companiesList}
                    placeholder="Company"
                    isMulti={false}
                    onChange={(e: any) => {
                      const companyObj = { id: e.value, name: e.label };
                      setSelectedCompany(companyObj);
                    }}
                    isSearchable={false}
                  />
                </Grid>
              )}
              <Grid
                item
                xs={isAdmin(userCustomClaim) ? 6 : 12}
                className="flex justify-end"
              >
                <Button
                  aria-label="add"
                  color="primary"
                  onClick={() => handleShow(false, {} as HolidayAttendance)}
                  variant="contained"
                >
                  <AddHomeWorkOutlinedIcon fontSize="medium" /> &nbsp;
                  <span className="text-sm">Add New Holiday</span>
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {isLoading && (
            <>
              <Grid item xs={12}>
                <Skeleton height={50} />
              </Grid>
              <Grid item xs={12}>
                <Skeleton height={50} />
              </Grid>
              <Grid item xs={12}>
                <Skeleton height={50} />
              </Grid>
            </>
          )}

          {!isLoading && (
            <Grid item xs={12}>
              {!holidaysAvailable() && (
                <Alert message={"No Holidays Available"} />
              )}

              {holidays?.length > 0 && (
                <>
                  <div className="table-responsive">
                    <BasicTable
                      tableColumns={COLUMNS}
                      tableData={holidays}
                      headerColor="bg-gray-850"
                      maxHeight={550}
                      tableHooks={tableHooks}
                    />
                  </div>
                </>
              )}
            </Grid>
          )}
        </Grid>
      </div>

      {showModal && (
        <AddEditHolidayModal
          showModal={showModal}
          handleClose={handleClose}
          editHoliday={editHoliday}
          addHoliday={addHoliday}
          selectedCompanyId={selectedCompany.id}
        />
      )}
    </>
  );
}

import { useEffect, useState } from "react";
// import { Container } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
// import { MemberStatus, Status } from "../../../Common/Enums/dashboard";
import { useSelector } from "react-redux";
import {
  addDefaultSrc,
  // addDefaultSrc,
  enumStringToText,
  getGlobalProject,
  getProjectPermissions,
  getRoleFromProjectPermissions,
  hasPermission,
} from "../../../Common/Common";
import {
  DropdownItem,
  Member,
  ReactSelect,
} from "../../../Common/Interfaces/dashboard";
import { getCompanyUsersList } from "../../../apis/services/company.service";
import { getAllProjectsList } from "../../../apis/services/projects.service";
import {
  getAllMembers,
  getProjectMembersAndRoles,
} from "../../../apis/services/user-projects.service";
import Alert from "../../Common/Alert/Alert";
import "../Members/MembersTab.scss";
import MembersTabFilters from "./Filters/MembersTabFilters";
// import ReactTooltip from "react-tooltip";
import { Divider, Grid } from "@mui/material";
import { MemberStatus, Status } from "../../../Common/Enums/dashboard";
import {
  CompanyLevelPermissions,
  ProjectLevelPermissions,
} from "../../../Common/Enums/permissions";
import { ReduxState } from "../../../redux/model/ReduxState.d";
import { UserCustomClaim } from "../../../redux/model/UserState.d";
import BasicTable from "../../Common/Table/BasicTable";
import { useLogEvent } from "../Analytics/hooks/useLogEvent";
import { COLUMNS } from "./dataColumns";

export default function MembersTab() {
  const [members, setMembers] = useState([] as Member[]);
  const [selectedMember, setSelectedMember] = useState({} as DropdownItem);
  const [selectedCompany, setSelectedCompany] = useState({} as DropdownItem);
  const [selectedProject, setSelectedProject] = useState({} as DropdownItem);
  const [membersForFilter, setMembersForFilter] = useState(
    ([] as DropdownItem[]) || ([] as ReactSelect[])
  );
  const [projectsForFilter, setProjectsForFilter] = useState(
    ([] as DropdownItem[]) || ([] as ReactSelect[])
  );
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userCustomClaim = useSelector<ReduxState, UserCustomClaim>(
    (state: ReduxState) => state.UserReducer?.userPermission?.userCustomClaim
  );
  const projectState = useSelector(
    (state: ReduxState) => state.SelectedProjectReducer
  );

  useLogEvent("page_view", "Members");

  useEffect(() => {
    getMembers();
    getCompanyUsers();
    getProjects();
  }, [selectedCompany]);

  const getCompanyUsers = async () => {
    if (selectedCompany.id) {
      setFiltersLoading(true);
      setSelectedMember({} as DropdownItem);
      let fetchedUsers = ([] as DropdownItem[]) || ([] as ReactSelect[]);
      if (
        hasPermission(
          userCustomClaim,
          CompanyLevelPermissions.GET_COMPANY_USERS
        )
      ) {
        fetchedUsers = await getCompanyUsersList(selectedCompany.id);
      } else if (
        getProjectPermissions(userCustomClaim, projectState).includes(
          ProjectLevelPermissions.GET_PROJECT_USERS
        )
      ) {
        const membersList = await getProjectMembersAndRoles(
          selectedCompany.id,
          "",
          getGlobalProject(projectState).projectId ?? ""
        );
        fetchedUsers = membersList.map(
          (x) => ({ id: x.userId, name: x.userName } as DropdownItem)
        );
      }
      setMembersForFilter(fetchedUsers);
      setFiltersLoading(false);
    }
  };

  const getMembers = async () => {
    if (selectedCompany.id) {
      setIsLoading(true);
      // TODO: add backend check for ALL type
      let fetchedUsers = await getAllMembers(
        selectedCompany.id,
        selectedMember.id !== "ALL" ? selectedMember.id : "",
        selectedProject.id !== "ALL" ? selectedProject.id : ""
      );
      fetchedUsers = fetchedUsers.map((user: any) => {
        return (user = {
          ...user,
          role: enumStringToText(
            getRoleFromProjectPermissions(user.userProjectPermissions)
          ),
        });
      });
      setMembers(fetchedUsers);
      setIsLoading(false);
    }
  };

  const getProjects = async () => {
    const selectedGlobalProject = getGlobalProject(projectState);
    if (selectedGlobalProject.projectId.length === 0) {
      if (selectedCompany && selectedCompany.id) {
        setFiltersLoading(true);
        setSelectedProject({} as DropdownItem);
        const fetchedProjects = await getAllProjectsList(selectedCompany.id);
        const projectDropDownData = fetchedProjects.map(
          (x) => ({ value: x.id, label: x.name } as any)
        );
        setProjectsForFilter(projectDropDownData);
        setFiltersLoading(false);
      }
    } else {
      setSelectedProject({
        id: selectedGlobalProject.projectId,
        name: selectedGlobalProject.projectName,
      });
    }
  };

  const membersAvailable = () => {
    if (members?.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const tableHooks = (hooks: any) => {
    hooks.visibleColumns.push((columns: any) => [
      {
        id: "userprofilepicture",
        Header: "Profile Picture",
        accessor: "userProfilePicture",
        disableSortBy: true,
        Cell: ({ row }: any) => {
          return (
            <img
              src={row.original.userProfilePicture}
              onError={(e) => addDefaultSrc(e)}
              alt="Picture"
              className="w-8 h-8"
            />
          );
        },
      },
      ...columns,
      {
        id: "status",
        Header: "Status",
        accessor: "status",
        disableSortBy: true,
        Cell: ({ row }: any) => {
          return (
            <>
              {row.original.status === Status.ACTIVE && (
                <span className="badge rounded-pill bg-success p-1">
                  {MemberStatus.Assigned}
                </span>
              )}
              {row.original.status === Status.INACTIVE && (
                <span className="badge rounded-pill bg-red-600 p-1">
                  {MemberStatus.Unassigned}
                </span>
              )}
            </>
          );
        },
      },
    ]);
  };

  return (
    <>
      <div>
        {/* <Container className="members-tab"> */}
        {/* <Loader active={isLoading || filtersLoading}> */}
        <Grid container justifyContent={"center"}>
          <Grid item xs={12}>
            <MembersTabFilters
              selectedMember={selectedMember}
              setSelectedMember={setSelectedMember}
              selectedCompany={selectedCompany}
              setSelectedCompany={setSelectedCompany}
              selectedProject={selectedProject}
              setSelectedProject={setSelectedProject}
              membersForFilter={membersForFilter}
              projectsForFilter={projectsForFilter}
              isLoading={filtersLoading}
              setIsLoading={setFiltersLoading}
              getMembers={getMembers}
            />
          </Grid>

          <Divider variant="middle" className="bg-gray-700 mx-0 mb-4" />

          {isLoading && (
            <>
              <Skeleton height={50} />
              <Skeleton height={50} />
              <Skeleton height={50} />
            </>
          )}

          {!isLoading && (
            <>
              {!membersAvailable() && <Alert message={"No Members Found"} />}
              {members?.length > 0 && (
                <BasicTable
                  tableColumns={COLUMNS}
                  tableData={members}
                  headerColor="bg-gray-850"
                  maxHeight={500}
                  tableHooks={tableHooks}
                />
              )}
            </>
          )}
          {/* </Loader> */}
          {/* </Container> */}
        </Grid>
      </div>
    </>
  );
}

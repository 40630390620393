import { Column } from "react-table";
import { sortByName } from "../../../../../utils/helpers/sorting";

export const COLUMNS: Column[] | any = [
  {
    Header: "Name",
    accessor: "name",
    sortType: sortByName,
  },
];

import HashMap from "hashmap";
import { useEffect, useState } from "react";
import { Modal, Row, Table } from "react-bootstrap";
import {
  getProjectGroups,
  updateGroups,
} from "../../../../apis/services/group.service";
import { enumStringToText, isSuccessCode } from "../../../../Common/Common";
import { GroupType } from "../../../../Common/Enums/dashboard";
import { AddEditGroupModalProps } from "../../../../Common/Interfaces/Allocation/add-edit-group.modal.props";
import { ProjectGroup } from "../../../../Common/Interfaces/Allocation/project-group";
import Alert from "../../../Common/Alert/Alert";
import Button from "../../../Common/Button/Button";
import AppDropdown from "../../../Common/Dropdown/Dropdown";

export default function AddEditGroupsModal(props: AddEditGroupModalProps) {
  const [companyId] = useState(props.selectedCompany?.id || "");
  const [projectGroups, setProjectGroups] = useState<ProjectGroup[]>(
    {} as ProjectGroup[]
  );
  const [isLoading, setIsLoading] = useState(false);

  const [projectGroupDataMap, setProjectGroupDataMap] = useState(
    new HashMap<string, GroupType>()
  );

  useEffect(() => {
    getProjectGroupsData();
  }, [companyId]);

  const changeStatus = (projectId: string, groupType: GroupType) => {
    const pmap = projectGroupDataMap.clone();
    pmap.set(projectId, groupType);
    setProjectGroupDataMap(pmap);
  };

  const getProjectGroupsData = async () => {
    setIsLoading(true);

    const mappedData = new HashMap<string, GroupType>();
    const fetchedProjectGroups = await getProjectGroups(companyId);
    fetchedProjectGroups.forEach((a: ProjectGroup) => {
      mappedData.set(a.projectId, a.groupType);
    });
    setProjectGroups(fetchedProjectGroups);
    setProjectGroupDataMap(mappedData);
    setIsLoading(false);
  };

  const handleSave = async () => {
    const projectGroupsData: ProjectGroup[] = [];
    projectGroups.forEach((projectGroup) => {
      const projectGroupType: GroupType =
        projectGroupDataMap.get(projectGroup.projectId) ?? GroupType.GROUP_1;
      const projectGroupObj: ProjectGroup = {
        companyId: projectGroup.companyId,
        projectId: projectGroup.projectId,
        projectName: projectGroup.projectName,
        groupId: projectGroup.groupId,
        groupType: projectGroupType,
      };
      projectGroupsData.push(projectGroupObj);
    });
    setIsLoading(true);
    const res = await updateGroups(props.selectedCompany.id, projectGroupsData);
    setIsLoading(false);
    if (res && isSuccessCode(res?.status)) {
      props.handleClose();
      props.handleEdit();
    }
  };
  const projectGroupsAvailable = (): boolean => {
    if (!projectGroups || (projectGroups && projectGroups.length === 0)) {
      return false;
    } else {
      return true;
    }
  };
  const groupTypes = [
    { id: GroupType.GROUP_1, name: enumStringToText(GroupType.GROUP_1) },
    { id: GroupType.GROUP_2, name: enumStringToText(GroupType.GROUP_2) },
  ];
  return (
    <>
      <Modal
        className="containerModal"
        size="lg"
        show={props.showModal}
        onHide={() => props.handleClose()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Groups</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            <Row className="mt-4">
              {!projectGroupsAvailable() && (
                <Alert message={"No Projects Available"} />
              )}
              {!isLoading && projectGroups && projectGroups.length > 0 && (
                <Table
                  striped
                  bordered
                  hover
                  className="table mt-2 text-center"
                >
                  <tbody>
                    {projectGroups &&
                      projectGroups.length > 0 &&
                      projectGroups.map((projectGroup: ProjectGroup) => {
                        const status =
                          projectGroupDataMap.get(projectGroup.projectId) ??
                          GroupType.GROUP_1;
                        return (
                          <tr key={projectGroup.projectId}>
                            <td>
                              {projectGroup.projectName}
                              <br />
                            </td>
                            <td>
                              {
                                <AppDropdown
                                  title={status}
                                  setValue={(e: GroupType) =>
                                    changeStatus(projectGroup.projectId, e)
                                  }
                                  list={groupTypes}
                                  saveObject={false}
                                />
                              }
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              )}
            </Row>
          }
        </Modal.Body>
        {
          <Modal.Footer>
            <Button
              onClick={() => props.handleClose()}
              text="Close"
              additionalClasses={"gray-button"}
            />
            <Button
              onClick={() => handleSave()}
              text="Save Changes"
              additionalClasses={"purple-button"}
            />
          </Modal.Footer>
        }
      </Modal>
    </>
  );
}

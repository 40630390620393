export enum DayNumber {
  DAY_1 = "DAY_1",
  DAY_2 = "DAY_2",
  DAY_3 = "DAY_3",
  DAY_4 = "DAY_4",
  DAY_5 = "DAY_5",
  DAY_6 = "DAY_6",
  DAY_7 = "DAY_7",
  DAY_8 = "DAY_8",
  DAY_9 = "DAY_9",
  DAY_10 = "DAY_10",
  DAY_11 = "DAY_11",
  DAY_12 = "DAY_12",
  DAY_13 = "DAY_13",
  DAY_14 = "DAY_14",
}

import { useEffect, useState } from "react";

import Select from "react-select";
import { Column } from "react-table";
import { useSelector } from "react-redux";

import {
  DateRangePickerComponent,
  RenderDayCellEventArgs,
} from "@syncfusion/ej2-react-calendars";

import HashMap from "hashmap";

import moment from "moment";

import { Checkbox, CircularProgress, Grid, Typography } from "@mui/material";

import {
  getAllCompaniesList,
  getCompanyUsersList,
} from "../../../../apis/services/company.service";
import {
  getWeeklyOrders,
  updateOrder,
} from "../../../../apis/services/food-order.service";

import {
  getCompanyId,
  hasPermission,
  isAdmin,
  isSuccessCode,
  foodOrderPrice,
} from "../../../../Common/Common";
import BasicTable from "../../../Common/Table/BasicTable";
import { WeekDayNumber } from "../../../../Common/Enums/week-day-number";
import { FoodOrder } from "../../../../Common/Interfaces/Lunch/food-order";
import { MenuByDate } from "../../../../Common/Interfaces/Lunch/menu-by-date";
import { CompanyLevelPermissions } from "../../../../Common/Enums/permissions";
import {
  DropdownItem,
  ReactSelect,
} from "../../../../Common/Interfaces/dashboard";

import { ReduxState } from "../../../../redux/model/ReduxState.d";
import { UserCustomClaim } from "../../../../redux/model/UserState.d";

export default function OrdersTabNew() {
  const [companiesList, setCompaniesList] = useState([] as ReactSelect[]);
  const [selectedCompany, setSelectedCompany] = useState({} as DropdownItem);
  const [companyUsers, setCompanyUsers] = useState([] as DropdownItem[]);
  const [dates, setDates] = useState([] as Date[]);
  const [menuData, setMenuData] = useState([] as MenuByDate[]);
  const [ordersData, setOrdersData] = useState([] as FoodOrder[]);
  const [ordersTableData, setOrdersTableData] = useState([] as any);
  const [tableKey, setTableKey] = useState(0);

  const [ordersMap, setOrdersMap] = useState(
    new HashMap<string, HashMap<string, boolean>>()
  ); // {date : { user1 : true }, {user2 : false}...}
  const [checkboxLoadingMap, setCheckboxLoadingMap] = useState(
    new HashMap<string, HashMap<string, boolean>>()
  ); // {date : { user1 : true }, {user2 : false}...}

  const [showError, setShowError] = useState(
    moment(new Date()).format("ddd") !== "Mon"
  );
  const userCustomClaim = useSelector<ReduxState, UserCustomClaim>(
    (state: ReduxState) => state.UserReducer?.userPermission?.userCustomClaim
  );
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(
    new Date(new Date().getTime() + 14 * 24 * 60 * 60 * 1000)
  );
  const userId = useSelector<ReduxState, string>(
    (state: ReduxState) => state.UserReducer.userId || ""
  );
  const userName = useSelector<ReduxState, string>(
    (state: ReduxState) => state.UserReducer.userName || ""
  );

  useEffect(() => {
    if (userCustomClaim) {
      getCompaniesList();
    }
  }, [userCustomClaim]);

  useEffect(() => {
    getCompanyUsers();
  }, [selectedCompany]);

  useEffect(() => {
    setMenuItems();
  }, [ordersData]);

  useEffect(() => {
    if (ordersTableData?.length && menuData?.length) {
      setTableKey((prevKey) => prevKey + 1);
    }
  }, [ordersTableData, menuData]);

  const disabledDate = (args: RenderDayCellEventArgs): void => {
    if (
      (args.date as Date).getDay() === 0 ||
      (args.date as Date).getDay() === 6
    ) {
      // set 'true' to disable the weekends
      args.isDisabled = true;
    }
  };

  useEffect(() => {
    const datesArray = getDatesBetween(startDate, endDate);
    setDates(datesArray);
  }, [endDate]);

  useEffect(() => {
    getFoodOrders();
  }, [dates]);

  const setMenuItems = async () => {
    if (selectedCompany && selectedCompany.id) {
      //   setIsLoading(true);
      const items = [] as MenuByDate[];
      ordersData &&
        dates.forEach((date) => {
          const itemName = ordersData.find(
            (x) => moment(x.date).format("L") === moment(date).format("L")
          )?.itemName;
          if (itemName) {
            items.push({
              itemName: itemName || "-",
              date: date,
            });
          }
        });
      setMenuData(items);
      //   setIsLoading(false);
    }
  };

  const calculateTotalChecked = (date: Date, orders: any): number => {
    const count = orders
      .get(moment(date).format("L"))
      ?.values()
      .filter((filter: any) => filter).length;
    return count ?? 0;
  };

  const getFoodOrders = async () => {
    if (selectedCompany?.id && !showError) {
      //   setIsLoading(true);
      const fetchedOrders = await getWeeklyOrders(
        selectedCompany.id,
        moment(startDate).format("L"),
        moment(endDate).format("L"),
        hasPermission(userCustomClaim, CompanyLevelPermissions.GET_FOOD_ORDER)
          ? ""
          : userId
      );
      const mappedData = new HashMap<string, HashMap<string, boolean>>();

      fetchedOrders?.forEach((a: FoodOrder) => {
        const date = moment(a.date).format("L");
        if (!mappedData.has(date)) {
          mappedData.set(
            date,
            new HashMap<string, boolean>(a.userId, a.ordered)
          );
        }
        mappedData.get(date)?.set(a.userId, a.ordered);
      });

      if (companyUsers?.length) {
        const totalCountObj: any = {
          userId: "",
          userName: "Total Count",
          totalPrice: null,
        };

        if (dates?.length > 0) {
          dates.map((d) => {
            const dateString: string = moment(d).format("MM/DD/yyyy");

            totalCountObj[dateString] = calculateTotalChecked(d, mappedData);
          });
        }

        // generate table data
        const tableData = companyUsers.map((companyUser: any) => {
          const userToUpdate = [];
          const userObject: any = {
            userId: companyUser?.id,
            userName: companyUser?.name,
          };

          if (dates.length > 0) {
            dates.map((d) => {
              const status =
                mappedData.get(moment(d).format("L"))?.get(companyUser.id) ||
                false;
              const dateString: any = moment(d).format("MM/DD/yyyy");

              userObject[dateString] = status;
            });
          }
          if (dates.length <= 0) {
            dates.map((d: any) => {
              const status = mappedData.get(d)?.get(companyUser.id) || false;
              const dateString: any = moment(d).format("MM/DD/yyyy");

              userObject[dateString] = status;
            });
          }
          userObject.totalPrice = calculateTotalBill(
            companyUser?.id,
            fetchedOrders
          );

          userToUpdate.push(userObject);
          return userToUpdate;
        });

        tableData.push(totalCountObj);
        setOrdersTableData(tableData.flat(1));
      }

      setOrdersData(fetchedOrders);
      setOrdersMap(mappedData);
      //   setIsLoading(false);
    }
  };

  const getDatesBetween = (sDate: Date, eDate: Date) => {
    const allDates = [];
    const currentDate = new Date(sDate);
    while (currentDate < eDate) {
      if (!(currentDate.getDay() === 0 || currentDate.getDay() === 6)) {
        allDates.push(new Date(currentDate));
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    allDates.push(eDate);
    return allDates;
  };

  const getCompaniesList = async () => {
    const companyId = getCompanyId(userCustomClaim);
    if (companyId.length === 0) {
      //   setIsLoading(true);
      const companies = await getAllCompaniesList();
      const companiesDropDownData = companies.map(
        (company) => ({ value: company.id, label: company.name } as ReactSelect)
      );
      setCompaniesList(companiesDropDownData);
      if (companies && companies.length > 0) {
        setSelectedCompany(companies[0]);
      }
      //   setIsLoading(false);
    } else {
      setSelectedCompany({ id: companyId, name: "" } as DropdownItem);
    }
  };

  const getCompanyUsers = async () => {
    if (
      hasPermission(userCustomClaim, CompanyLevelPermissions.GET_FOOD_ORDER) &&
      selectedCompany.id
    ) {
      //   setIsLoading(true);
      const fetchedCompanyUsers = await getCompanyUsersList(selectedCompany.id);
      setCompanyUsers(fetchedCompanyUsers);
      //   setIsLoading(false);
    } else {
      setCompanyUsers([{ id: userId, name: userName }] as DropdownItem[]);
    }
  };
  const getMenuForDate = (date: Date): string | undefined => {
    return menuData.find((x) => x.date === date)?.itemName;
  };

  const findMenuItemByDateForTableHeader = (date: any) => {
    if (menuData?.length > 0) {
      const matchingItem = menuData.find(
        (item) => new Date(item.date).getTime() === date.getTime()
      );
      return matchingItem ? matchingItem.itemName : null;
    }
  };

  const calculateTotalBill = (userId: string, orders: any): number => {
    const count = orders.filter(
      (x: any) => x.userId === userId && x.ordered
    ).length;
    return count * foodOrderPrice;
  };

  const checkDisabled = (d: Date) => {
    const today = new Date();
    return (
      !getMenuForDate(d) || // menu added for that day
      !hasPermission(
        userCustomClaim,
        CompanyLevelPermissions.UPDATE_FOOD_ORDER
      ) || // has permission to update food order
      moment().week() % 2 === 0 || // disable if even week number
      (moment().week() % 2 === 1 &&
        ![WeekDayNumber.THURSDAY, WeekDayNumber.FRIDAY].includes(
          today.getDay()
        )) || // disable if today is not (alternate/odd week number's) thursday or friday
      d < today // cannot edit past orders
    );
  };

  const handleOrderStatusChange = async (
    userId: string,
    userName: string,
    date: string,
    status: boolean
  ) => {
    let isEdit = false;
    const orders = ordersData.filter(
      (x) => x.userId === userId && moment(x.date).format("L") === date
    );
    if (orders.length > 0) {
      isEdit = true;
    }
    if (isEdit) {
      const order = orders[0];

      // to show loader in place of checkbox when api call is in progress
      const cmap = checkboxLoadingMap.clone();
      if (!cmap.has(date)) {
        cmap.set(date, new HashMap<string, boolean>(userId, true));
      } else {
        cmap.get(date)?.set(userId, true);
      }
      setCheckboxLoadingMap(cmap);
      const res = await updateOrder(selectedCompany.id, order.id, status);
      if (res && isSuccessCode(res.status)) {
        const omap = ordersMap.clone();
        omap.get(date)?.set(userId, status);
        setOrdersMap(omap);
        const ordersDataCopy = ordersData.filter((x) => x.id !== order.id);
        order.ordered = status;
        ordersDataCopy.push(order);
        setOrdersData(ordersDataCopy);
      }
      // to show checkbox in place of loader when api call is finished
      cmap.get(date)?.set(userId, false);
      setCheckboxLoadingMap(cmap);
    }
  };

  const getFormattedHeaderValue = (dataKey: string) => {
    if (dataKey === "userName") {
      return "Name";
    } else if (dataKey === "totalPrice") {
      return "Total Price";
    } else {
      const formattedDate = moment(dataKey).format("DD MMMM ddd");
      const menuItem = findMenuItemByDateForTableHeader(new Date(dataKey));
      return formattedDate + " " + (menuItem ? `(${menuItem})` : "( - )");
    }
  };

  const generateColumns = () => {
    const columns: any = [];
    const uniqueColumnIds = new Set(); // Keep track of unique column identifiers

    if (ordersTableData?.length > 0) {
      ordersTableData.forEach((data: any) => {
        Object.keys(data).forEach((dataKey) => {
          if (dataKey !== "userId" && !uniqueColumnIds.has(dataKey)) {
            const column = {
              id: Math.random().toString(),
              Header: getFormattedHeaderValue(dataKey),
              accessor: dataKey,
              disableSortBy: true,
              Cell: ({ row }: any) => {
                const ordersCalendarTableDataValue = row?.original?.[dataKey];
                const currentUserId = row?.original?.userId;
                const currentUserName = row?.original?.userName;
                const isTotalCountRow = currentUserName === "Total Count";

                if (dataKey !== "userName" && dataKey !== "totalPrice") {
                  if (isTotalCountRow) {
                    return (
                      <span className="mx-[14px]">
                        {ordersCalendarTableDataValue}
                      </span>
                    );
                  } else {
                    const isLoading =
                      checkboxLoadingMap?.get(dataKey)?.get(currentUserId) ||
                      false;
                    return (
                      <>
                        {!isLoading && (
                          <span className="w-[10px] h-[10px] p-0">
                            <Checkbox
                              size="small"
                              id={dataKey}
                              disabled={checkDisabled(new Date(dataKey))}
                              checked={ordersCalendarTableDataValue}
                              onChange={(e) =>
                                handleOrderStatusChange(
                                  currentUserId,
                                  currentUserName,
                                  dataKey,
                                  e.target.checked
                                )
                              }
                            />
                          </span>
                        )}
                        {isLoading && (
                          <CircularProgress
                            color="primary"
                            className="flex justify-center"
                            size={20}
                            thickness={4}
                          />
                        )}
                      </>
                    );
                  }
                } else {
                  // Display the total count for the respective date key
                  return ordersCalendarTableDataValue;
                }
              },
            };
            columns.push(column);
            uniqueColumnIds.add(dataKey);
          }
        });
      });
    }

    return columns;
  };

  const tableHooks = (hooks: any) => {
    hooks.visibleColumns.push((columns: any) => [...columns]);
  };

  const COLUMNS: Column[] | any = [...generateColumns()];

  return (
    <div className="mt-5">
      <Grid container spacing={2}>
        <Grid xs={6} className="flex" justifyContent={"start"}>
          {isAdmin(userCustomClaim) && (
            <Select
              className="w-72"
              options={companiesList}
              placeholder="Company"
              isMulti={false}
              onChange={(e: any) => {
                const companyObj = { id: e.value, name: e.label };
                setSelectedCompany(companyObj);
              }}
              isSearchable={false}
            />
          )}
        </Grid>

        <Grid item xs={6} className="flex justify-end">
          <span className="block">
            <DateRangePickerComponent
              width={250}
              format="dd-MMM-yy"
              onChange={(range: any) => {
                if (moment(range.value[0]).format("ddd") === "Mon") {
                  setShowError(false);
                  setStartDate(range.value[0]);
                  setEndDate(range.value[1]);
                } else {
                  setShowError(true);
                }
              }}
              showClearButton={false}
              startDate={startDate}
              endDate={endDate}
              delayUpdate={true}
              maxDays={14}
              renderDayCell={disabledDate}
              allowEdit={false}
            />
            <div>
              {showError && (
                <Typography variant="caption" className="text-gray-700">
                  <strong>Note:</strong> Start Date should be Monday!
                </Typography>
              )}
            </div>
          </span>
        </Grid>
      </Grid>

      {!showError && (
        <Grid container spacing={1} className="my-2">
          <Grid item xs={12} className="w-[500px]">
            {ordersTableData?.length > 0 && (
              <BasicTable
                key={tableKey}
                tableColumns={COLUMNS}
                tableData={ordersTableData}
                headerColor="bg-gray-850"
                maxHeight={500}
                tableHooks={tableHooks}
              />
            )}
          </Grid>
        </Grid>
      )}
    </div>
  );
}

import { Grid, GridProps, Divider as MuiDivider } from "@mui/material";
import { FC, ReactNode } from "react";

interface DividerProps extends GridProps {
  children: ReactNode;
}
const Divider: FC<DividerProps> = ({ children, ...props }) => (
  <Grid container alignItems="center" {...props}>
    <Grid item xs>
      <MuiDivider className="bg-white ml-24" />
    </Grid>
    <Grid item className="text-white">
      {children}
    </Grid>
    <Grid item xs>
      <MuiDivider className="bg-white mr-24" />
    </Grid>
  </Grid>
);

export default Divider;

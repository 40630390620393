import { Column } from "react-table";
import { sortByName } from "../../../utils/helpers/sorting";

export const COLUMNS: Column[] | any = [
  {
    Header: "User Name",
    accessor: "userName",
    sortType: sortByName,
  },
  {
    Header: "Project Name",
    accessor: "projectName",
    sortType: sortByName,
  },
  {
    Header: "User Role",
    accessor: "role",
    sortType: sortByName,
  },
];

import { useState } from "react";
import { Chatbot, createChatBotMessage } from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import IConfig from "react-chatbot-kit/build/src/interfaces/IConfig";
import ActionProvider from "../../../../Common/chatbot/ActionProvider";
import MessageParser from "../../../../Common/chatbot/MessageParser";
import Options from "./Options";

export default function FeedbackChatbot() {
  const [showBot, setShowBot] = useState(false);

  const config: IConfig = {
    botName: "Feedback Assistant",
    customStyles: {
      botMessageBox: {
        backgroundColor: "#7A6AF6",
      },
      chatButton: {
        backgroundColor: "#7A6AF6",
      },
    },
    initialMessages: [
      createChatBotMessage(`Hello 👋`, {}),
      createChatBotMessage("What type of feedback would you like to give?", {
        widget: "options",
      }),
    ],
    widgets: [
      {
        widgetName: "options",
        widgetFunc: (props: any) => <Options {...props} />,
        props: {},
        mapStateToProps: [],
      },
    ],
    customComponents: {
      botAvatar: () => (
        <img
          className="w-15"
          src={require("../../../../assets/images/feedback-assistant.png")}
        ></img>
      ),
    },
  };

  return (
    <>
      {showBot && (
        <div className="app-chatbot-container">
          <Chatbot
            config={config}
            messageParser={MessageParser}
            actionProvider={ActionProvider}
          />
        </div>
      )}
      <button
        className="app-chatbot-button"
        onClick={() => setShowBot((prev) => !prev)}
      >
        <img
          className="h-100 w-100"
          src={require("../../../../assets/images/feedback-assistant.png")}
        ></img>
      </button>
    </>
  );
}

import { z } from "zod";

export const AddEditProjectSchema = z.object({
  projectName: z.string().nonempty({ message: "Project Name is required" }),
  clientName: z.string().nonempty({ message: "Client Name is required" }),
  clientEmail: z
    .string()
    .nonempty({ message: "Client email is required" })
    .email({
      message: "Must be a valid email",
    }),
  projectBoardUrl: z
    .string()
    .nonempty({ message: "Project board url is required" })
    .url({ message: "Url format should be like http://www.google.com" }),

  projectDate: z.date({
    required_error: "Please select date",
    invalid_type_error: "Please select valid date!",
  }),
});
export const AddEditProjectMembersSchema = z
  .object({
    projectMember: z
      .array(
        z.object({
          value: z.string(),
          label: z.string(),
        })
      )
      .nonempty({ message: "Please select member" }),
    memberRole: z.string().nonempty({ message: "Please select member role" }),
    customRole: z
      .array(
        z.object({
          value: z.string(),
          label: z.string(),
        })
      )
      .optional(),
  })
  .refine((val) => {
    if (
      val.memberRole === "CUSTOM" &&
      (!val.customRole || val.customRole.length === 0)
    )
      return false;
    else return true;
  });

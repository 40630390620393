import { AxiosResponse } from "axios";
import { FoodOrder } from "../../Common/Interfaces/Lunch/food-order";
import { getData, updateData } from "../axios-calls/common-axios-service";

export const getWeeklyOrders = async (
  companyId: string,
  startingDate: string,
  endingDate: string,
  userId = "",
  startAfter = "",
  endBefore = ""
): Promise<FoodOrder[]> => {
  const orders = await getData(
    `companies/${companyId}/foodOrders?startingDate=${startingDate}&endingDate=${endingDate}&startAfter=${startAfter}&endBefore=${endBefore}&userId=${userId}`
  );
  return orders;
};

export const updateOrder = async (
  companyId: string,
  foodOrderId: string,
  orderStatus: boolean
): Promise<AxiosResponse> => {
  const res = await updateData(
    `companies/${companyId}/foodOrders/${foodOrderId}`,
    { ordered: orderStatus }
  );
  return res as AxiosResponse;
};

import DownloadIcon from "@mui/icons-material/Download";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import {
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Tooltip,
} from "@mui/material";
import { ExportToCsv } from "export-to-csv";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { getCompanyId, isAdmin, isSuccessCode } from "../../../Common/Common";
import { Billable, TicketType } from "../../../Common/Enums/dashboard";
import { DropdownItem, Worklog } from "../../../Common/Interfaces/dashboard";
import {
  deleteWorklog,
  getAllWorkLogs,
  getAllWorkLogsForCsv,
} from "../../../apis/services/worklogs.service";
import { ReduxState } from "../../../redux/model/ReduxState.d";
import { UserCustomClaim } from "../../../redux/model/UserState.d";
import Alert from "../../Common/Alert/Alert";
import DeleteModal from "../../Common/Popup/DeleteModal";
import BasicTable from "../../Common/Table/BasicTable";
import WorklogFilters from "./Filters/WorklogFilters";
import AddWorklogModal from "./WorklogModals/AddEditWorklogModal/AddEditWorklogModal";
import WorklogDetailModal from "./WorklogModals/WorklogDetailModal/WorklogDetailModal";
import { COLUMNS } from "./dataColumns";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import moment from "moment";
import { MaxPageData } from "../../../Common/Enums/pagination";
import { useLogEvent } from "../Analytics/hooks/useLogEvent";
import DescriptionModal from "./DescriptionModal/DescriptionModal";
import WorklogCardNew from "./WorklogCard/WorklogCardNew";
// import Loader from "../../Common/Loader/Loader";

export default function WorklogTab() {
  const [worklogs, setWorklogs] = useState([] as Worklog[]);
  const [viewMode, setViewMode] = useState("tabular");
  const [showWorklogModal, setShowWorklogModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({} as Worklog);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddWorklogModal, setShowAddWorklogModal] = useState(false);

  const [selectedCompany, setSelectedCompany] = useState({} as DropdownItem);
  const [selectedMember, setSelectedMember] = useState({} as DropdownItem);
  const [selectedProject, setSelectedProject] = useState({} as DropdownItem);
  const [billable, setBillable] = useState<string>(Billable.ALL);
  const [startDate, setStartDate] = useState<Date>(
    new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
  );
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [deletedWorklog, setDeletedWorklog] = useState({} as Worklog);
  const [editWorklog, setEditWorklog] = useState({} as Worklog);
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [pageNumber, setPageNumber] = useState(0);
  const [prePageNumber, setPrePageNumber] = useState(0);

  const [toggleDescriptionModal, setToggleDescriptionModal] = useState(false);
  const [descriptionModalData, setDescriptionModalData] = useState("");

  const userCustomClaim = useSelector<ReduxState, UserCustomClaim>(
    (state: ReduxState) => state.UserReducer?.userPermission?.userCustomClaim
  );
  const userId = useSelector<ReduxState, string>(
    (state: ReduxState) => state.UserReducer.userId || ""
  );
  const projectState = useSelector(
    (state: ReduxState) => state.SelectedProjectReducer
  );

  const options = {
    filename: "worklogs",
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    // headers: ['Project Name', 'User Name', 'date', 'Ticket Type', 'Time Duration', 'Work Description', 'Task URL', 'Billable']
  };

  const downloadCsv = async () => {
    const csvExporter = new ExportToCsv(options);
    const date = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
      0,
      0,
      0,
      0
    );
    const edate = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate(),
      0,
      0,
      0,
      0
    );
    const data = await getAllWorkLogsForCsv(
      selectedCompany.id,
      date,
      edate,
      billable,
      selectedMember.id ?? "",
      selectedProject.id ?? ""
    );
    csvExporter.generateCsv(data);
  };

  useEffect(() => {
    if (!isAdmin(userCustomClaim)) {
      setSelectedCompany({
        id: getCompanyId(userCustomClaim),
        name: "",
      } as DropdownItem);
    }
  }, [userCustomClaim]);

  useEffect(() => {
    setSelectedProject({
      id: projectState.projectId,
      name: projectState.projectName,
    } as DropdownItem);
  }, [projectState]);

  useLogEvent("page_view", "Worklog");

  useEffect(() => {
    getWorkLogs();
  }, [selectedProject, pageNumber]);

  const getWorkLogs = async (startAt = "") => {
    let endBefore = "";
    if (startAt === "")
      if (pageNumber > prePageNumber)
        startAt = worklogs[worklogs?.length - 1].id ?? "";
      else if (pageNumber < prePageNumber) {
        endBefore = worklogs[0].id ?? "";
      }

    if (selectedCompany?.id) {
      setIsLoading(true);
      const date = moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      let edate = moment(endDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      // this check is applied because for date selection of one day , data was not returning though data exists.
      if (date === edate) {
        edate = moment(endDate)
          .set({ hour: 23, minute: 59, second: 59 })
          .format("YYYY-MM-DDTHH:mm:ss.SS[Z]");
      }
      const fetchedWorklogs = await getAllWorkLogs(
        selectedCompany.id,
        date,
        edate,
        billable,
        selectedMember.id ?? "",
        selectedProject.id ?? "",
        startAt,
        endBefore
      );
      setWorklogs(fetchedWorklogs);
      pageNumber === 1 && pageNumber < prePageNumber
        ? setPrePageNumber(0)
        : setPrePageNumber(pageNumber);
      setIsLoading(false);
    }
  };

  const addWorklog = (updatedWorklog: Worklog, isEdit = false) => {
    if (!isEdit) {
      if (worklogs?.length !== 6) {
        const worklogsList = [...worklogs, updatedWorklog];
        setWorklogs(worklogsList);
      }
    } else {
      const updatedWorklogs = worklogs?.map((worklog: Worklog) => {
        if (worklog.id === editWorklog.id) {
          return {
            id: editWorklog.id,
            projectId: updatedWorklog.projectId,
            projectName: updatedWorklog.projectName,
            userId: updatedWorklog.userId,
            userName: updatedWorklog.userName,
            date: updatedWorklog.date,
            ticketType: updatedWorklog.ticketType,
            worklogMinutes: updatedWorklog.worklogMinutes,
            ticketName: updatedWorklog.ticketName,
            ticketDescription: updatedWorklog.ticketDescription,
            ticketUrl: updatedWorklog.ticketUrl,
            billable: updatedWorklog.billable,
          } as Worklog;
        } else {
          return worklog;
        }
      });
      setWorklogs(updatedWorklogs);
    }
    setShowAddWorklogModal(false);
  };
  const deleteWorklogData = (worklogId: string) => {
    if (worklogs?.length === 1) setPageNumber((pageNumber) => pageNumber - 1);
    else {
      //If workLogs length is six that means next page existed so we must refetch
      // so, when the next page is existed and if we delete first index we must refetch data from second index of workLog

      if (worklogs?.length === 6) {
        worklogs[0]?.id === worklogId
          ? getWorkLogs(worklogs[1]?.id)
          : getWorkLogs(worklogs[0]?.id);
      } else {
        const workLogsList = [...worklogs];
        for (let i = 0; i < workLogsList.length; i++) {
          if (workLogsList[i].id === worklogId)
            i === 0 ? getWorkLogs(worklogs[1].id) : workLogsList.splice(i, 1);
        }
        setWorklogs(workLogsList);
      }
    }
  };
  const handleDeletingWorklog = (worklog: Worklog) => {
    setDeletedWorklog(worklog);
    setShowDeleteModal(true);
  };
  const handleDelete = async () => {
    if (deletedWorklog.id) {
      setShowDeleteModal(false);
      const res = await deleteWorklog(selectedCompany.id, deletedWorklog.id);
      if (res && isSuccessCode(res?.status)) {
        deleteWorklogData(deletedWorklog.id);
      }
    }
  };
  const handleClose = () => setShowAddWorklogModal(false);
  const handleShow = (isEdit: boolean, worklog: Worklog) => {
    if (isEdit) {
      setEditWorklog(worklog);
    } else {
      setEditWorklog({
        id: "",
        projectId: "",
        projectName: "",
        userId: "",
        userName: "",
        date: new Date(),
        ticketType: TicketType.TASK,
        worklogMinutes: 0,
        ticketName: "",
        ticketDescription: "",
        ticketUrl: "",
        billable: true,
      } as Worklog);
    }
    setShowAddWorklogModal(true);
  };

  const worklogsAvailable = () => {
    if (worklogs?.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const onRowClick = (data: Worklog) => {
    setSelectedRowData(data);
    setShowWorklogModal(true);
  };

  const toggleDescriptionModalHandler = (
    toggle: boolean,
    descriptionText: string
  ) => {
    setDescriptionModalData(descriptionText);
    setToggleDescriptionModal(toggle);
  };

  const tableHooks = (hooks: any) => {
    hooks.visibleColumns.push((columns: any) => [
      ...columns,
      {
        id: "worklogactions",
        Header: "Actions",
        accessor: "actions",
        disableSortBy: true,
        Cell: ({ row }: any) => {
          return (
            <>
              {(row.original as any)?.userId === userId && (
                <>
                  <IconButton
                    aria-label="Edit"
                    color="primary"
                    onClick={() => handleShow(true, row.original)}
                  >
                    <EditIcon fontSize="medium" />
                  </IconButton>
                  <IconButton
                    aria-label="Delete"
                    color="error"
                    onClick={() => handleDeletingWorklog(row.original)}
                  >
                    <DeleteIcon fontSize="medium" />
                  </IconButton>
                </>
              )}
            </>
          );
        },
      },
      {
        id: "workloginfo",
        Header: "Info",
        accessor: "info",
        disableSortBy: true,
        Cell: ({ row }: any) => {
          return (
            <>
              {(row.original as any)?.userId === userId && (
                <>
                  <IconButton
                    aria-label="Info"
                    color="primary"
                    onClick={() => onRowClick(row.original)}
                  >
                    <InfoOutlinedIcon fontSize="medium" />
                  </IconButton>
                </>
              )}
            </>
          );
        },
      },
    ]);
  };

  return (
    <>
      {/* {isLoading && <Loader active={isLoading} />} */}
      {/* {!isLoading && ( */}
      <Grid container spacing={0} className="flex justify-center">
        <WorklogFilters
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
          selectedMember={selectedMember}
          setSelectedMember={setSelectedMember}
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
          billable={billable}
          setBillable={setBillable}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          getWorkLogs={getWorkLogs}
          isLoading={filtersLoading}
          setIsLoading={setFiltersLoading}
        />
      </Grid>

      <Grid
        container
        spacing={0}
        justifyContent="center"
        className="w-[100%] flex justify-center"
      >
        <Divider className="border-1 border-gray-700 bg-black-700 mx-0 mb-0 w-[100%]" />
      </Grid>

      <Grid container spacing={1} justifyContent="end">
        <Grid item>
          <Tooltip title="Download Timelogs">
            <IconButton
              className="hover:text-blue-550"
              aria-label="delete"
              onClick={() => downloadCsv()}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        {!isAdmin(userCustomClaim) && (
          <Grid item>
            <Tooltip title="Add Timelog">
              <IconButton
                className="hover:text-blue-550"
                aria-label="delete"
                onClick={() => handleShow(false, {} as Worklog)}
              >
                <MoreTimeIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
        <Grid item>
          <FormControlLabel
            label={viewMode === "card" ? "Table" : "Card"}
            labelPlacement="end"
            control={
              <Switch
                onChange={(e) =>
                  e.target.checked ? setViewMode("card") : setViewMode("table")
                }
              />
            }
          />
        </Grid>
      </Grid>

      <br />

      <Grid container spacing={0}>
        {!isLoading && (
          <>
            {!worklogsAvailable() && <Alert message={"No Timelogs Found"} />}

            {worklogs?.length > 0 && (
              <>
                {viewMode === "card" ? (
                  <Grid container spacing={2}>
                    {(worklogs?.length === 6
                      ? worklogs?.slice(0, -1)
                      : worklogs
                    ).map((worklog: Worklog, index: number) => {
                      return (
                        <Grid item xs={3} key={index}>
                          <WorklogCardNew
                            key={index}
                            worklog={worklog}
                            handleShow={handleShow}
                            toggleDescriptionModalHandler={
                              toggleDescriptionModalHandler
                            }
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                ) : (
                  <>
                    <BasicTable
                      tableColumns={COLUMNS}
                      tableData={
                        worklogs?.length === 6
                          ? worklogs?.slice(0, -1)
                          : worklogs
                      }
                      headerColor="bg-gray-850"
                      maxHeight={500}
                      tableHooks={tableHooks}
                    />
                  </>
                )}

                {worklogs?.length > 0 && (
                  <Grid container justifyContent="flex-end">
                    {pageNumber === 0 ? (
                      <Grid item>
                        <IconButton aria-label="left" color="primary" disabled>
                          <NavigateBeforeIcon fontSize="large" />
                        </IconButton>
                      </Grid>
                    ) : (
                      <Grid item>
                        <IconButton aria-label="left" color="primary">
                          <NavigateBeforeIcon
                            fontSize="large"
                            onClick={() => {
                              setPageNumber((pageNumber) => pageNumber - 1);
                            }}
                          />
                        </IconButton>
                      </Grid>
                    )}
                    {worklogs?.length < MaxPageData.DATA_SIZE ? (
                      <Grid item>
                        <IconButton aria-label="right" color="primary" disabled>
                          <NavigateNextIcon fontSize="large" />
                        </IconButton>
                      </Grid>
                    ) : (
                      <Grid item>
                        <IconButton
                          aria-label="right"
                          color="primary"
                          disabled={worklogs?.length < MaxPageData.DATA_SIZE}
                        >
                          <NavigateNextIcon
                            fontSize="large"
                            onClick={() => {
                              setPageNumber((pageNumber) => pageNumber + 1);
                            }}
                          />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                )}
              </>
            )}
          </>
        )}

        {isLoading && (
          <>
            <Skeleton height={50} />
            <Skeleton height={50} />
            <Skeleton height={50} />
          </>
        )}

        {showWorklogModal && (
          <WorklogDetailModal
            worklog={selectedRowData}
            showModal={showWorklogModal}
            handleClose={setShowWorklogModal}
          />
        )}

        {showAddWorklogModal && (
          <AddWorklogModal
            showModal={showAddWorklogModal}
            handleClose={handleClose}
            selectedCompany={selectedCompany}
            addWorklog={addWorklog}
            editWorkLog={editWorklog}
          />
        )}
        {showDeleteModal && (
          <DeleteModal
            showModal={showDeleteModal}
            handleClose={() => setShowDeleteModal(false)}
            handleDelete={handleDelete}
            message="Are you sure you want to delete this timelog"
          />
        )}
        {toggleDescriptionModal && (
          <DescriptionModal
            descriptionModalData={descriptionModalData}
            toggleDescriptionModal={toggleDescriptionModal}
            setToggleDescriptionModal={setToggleDescriptionModal}
          />
        )}
      </Grid>

      {/* )} */}
    </>
  );
}

import { Form } from "react-bootstrap";
import {
  EditVideoModalProps,
  VideoEditAxiosRequest,
} from "../../../../Common/Interfaces/Videos/edit-video-modal-props";
import Modal from "react-bootstrap/esm/Modal";
import {
  getCompanyId,
  isAdmin,
  isSuccessCode,
} from "../../../../Common/Common";
import Loader from "../../../Common/Loader/Loader";
import { InputLabel } from "@mui/material";
import { useEffect, useState } from "react";
import { VideoUploadShareOptions } from "../../../../Common/Enums/Lunch/video-upload-share-options";
import { ReduxState } from "../../../../redux/model/ReduxState.d";
import { UserCustomClaim } from "../../../../redux/model/UserState.d";
import { ProjectsListOptions } from "../../../../Common/Interfaces/Videos/projects-list-options";
import { DropdownItem } from "../../../../Common/Interfaces/dashboard";
import {
  getAllCompaniesList,
  getCompanyUsersList,
} from "../../../../apis/services/company.service";
import { getUserProjects } from "../../../../apis/services/user-projects.service";
import { updateVideo } from "../../../../apis/services/videos.service";
import AppDropdown from "../../../Common/Dropdown/Dropdown";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import "./EditVideoModal.scss";
import { useSelector } from "react-redux";

export default function EditVideoModal(props: EditVideoModalProps) {
  const userCustomClaim = useSelector<ReduxState, UserCustomClaim>(
    (state: ReduxState) => state.UserReducer?.userPermission?.userCustomClaim
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isProjectListLoading, setIsProjectListLoading] = useState(false);
  const [isUserListLoading, setIsUserListLoading] = useState(false);
  const [projectsList, setProjectsList] = useState([] as ProjectsListOptions[]);
  const [selectedProject, setSelectedProject] = useState(
    [] as ProjectsListOptions[]
  );
  const [usersList, setUsersList] = useState([] as ProjectsListOptions[]);
  const [selectedUser, setSelectedUser] = useState([] as ProjectsListOptions[]);
  const [selectOption, setSelectOption] = useState<VideoUploadShareOptions>(
    () => {
      if (props.editVideo.sharedWithUsers.length > 0) {
        return VideoUploadShareOptions.USERS;
      } else if (props.editVideo.sharedWithProjects.length > 0) {
        return VideoUploadShareOptions.PROJECT;
      } else {
        return VideoUploadShareOptions.COMPANY;
      }
    }
  );

  const [companiesList, setCompaniesList] = useState([] as DropdownItem[]);

  const [selectedCompany, setSelectedCompany] = useState(() => {
    if (
      isAdmin(userCustomClaim) &&
      props.editVideo.sharedWithCompanies.length > 0
    ) {
      return {
        id: props.editVideo.sharedWithCompanies[0],
        name: "",
      } as DropdownItem;
    } else {
      return {} as DropdownItem;
    }
  });

  const [validated, setValidated] = useState(true);

  const [isSubmitted, setIsSubmitted] = useState(false);

  let selectOptions: ProjectsListOptions[];
  if (userCustomClaim.projectPermissionsModel.length === 0) {
    selectOptions = [
      {
        value: VideoUploadShareOptions.COMPANY,
        label: VideoUploadShareOptions.COMPANY,
      },

      {
        value: VideoUploadShareOptions.USERS,
        label: VideoUploadShareOptions.USERS,
      },
    ];
  } else {
    selectOptions = [
      {
        value: VideoUploadShareOptions.COMPANY,
        label: VideoUploadShareOptions.COMPANY,
      },
      {
        value: VideoUploadShareOptions.PROJECT,
        label: VideoUploadShareOptions.PROJECT,
      },
      {
        value: VideoUploadShareOptions.USERS,
        label: VideoUploadShareOptions.USERS,
      },
    ];
  }

  async function getUsersList(companyId: string) {
    if (companyId.length !== 0) {
      setIsUserListLoading(true);
      const users = await getCompanyUsersList(companyId);
      const filteredUsers = users.filter((user) => {
        if (props.userInfo.userId !== user.id) {
          return user;
        }
      });
      const userSelectOptions = filteredUsers.map((user) => {
        return {
          value: user.id,
          label: user.name,
        };
      });

      setUsersList(userSelectOptions);
      const editUsers = userSelectOptions.filter((user) =>
        props.editVideo.sharedWithUsers.includes(user.value)
      );
      setSelectedUser(editUsers);

      setIsUserListLoading(false);
    }
  }

  async function getProjectsList(companyId: string) {
    if (companyId.length !== 0) {
      setIsProjectListLoading(true);
      const projects = await getUserProjects(companyId, props.userInfo.userId);
      const selectOptions = projects.map((project) => {
        return {
          value: project.id,
          label: project.name,
        };
      });
      setProjectsList(selectOptions);
      const editProjects = selectOptions.filter((project) =>
        props.editVideo.sharedWithProjects.includes(project.value)
      );
      setSelectedProject(editProjects);

      setIsProjectListLoading(false);
    }
  }

  const getCompaniesList = async (companyId: string) => {
    if (companyId.length === 0) {
      setIsLoading(true);
      const companies = await getAllCompaniesList();
      setCompaniesList(companies);
      const editCompany = companies.find(
        (company) => company.id === props.editVideo.sharedWithCompanies[0]
      );
      if (companies && companies.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        setSelectedCompany(editCompany!);
      }
      setIsLoading(false);
    } else {
      setSelectedCompany({ id: companyId, name: "" } as DropdownItem);
    }
  };

  useEffect(() => {
    const companyId = getCompanyId(userCustomClaim);
    fetchLists(companyId);
  }, []);

  const fetchLists = async (companyId: string) => {
    if (isAdmin(userCustomClaim)) {
      await getCompaniesList(companyId);
    } else {
      await Promise.all([
        getCompaniesList(companyId),
        getUsersList(companyId),
        getProjectsList(companyId),
      ]);
    }
  };

  const handleUpload = async () => {
    let sharedWithCompanies;
    if (isAdmin(userCustomClaim)) {
      sharedWithCompanies = new Set([selectedCompany.id]);
    } else {
      sharedWithCompanies =
        selectOption === VideoUploadShareOptions.COMPANY
          ? new Set([selectedCompany.id])
          : new Set();
    }

    const sharedWithProjects =
      !isAdmin(userCustomClaim) &&
      selectOption === VideoUploadShareOptions.PROJECT
        ? new Set(selectedProject.map((project) => project.value))
        : new Set();

    const sharedWithUsers =
      !isAdmin(userCustomClaim) &&
      selectOption === VideoUploadShareOptions.USERS
        ? new Set(selectedUser.map((user) => user.value))
        : new Set();

    const videoPayLoad: VideoEditAxiosRequest = {
      title: props.editVideo.title,
      sharedWithCompanies: Array.from(sharedWithCompanies) as string[],
      sharedWithProjects: Array.from(sharedWithProjects) as string[],
      sharedWithUsers: Array.from(sharedWithUsers) as string[],
    };
    const res = await updateVideo(props.editVideo.id, videoPayLoad);
    if (isSuccessCode(res.status)) {
      props.handleClose();
    } else {
      setIsSubmitted(false);
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(false);
    } else {
      setValidated(true);
      setIsSubmitted(true);
      await handleUpload();
    }
  };
  return (
    <>
      <Modal
        dialogClassName="edit-video-modal-container"
        show={props.showModal}
        onHide={() => props.handleClose()}
        backdrop="static"
        style={{ width: "2000px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Video Options</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicText">
              <Form.Label className="text-black m-2">Video Title</Form.Label>
              <Form.Control
                required={true}
                type="text"
                placeholder="Enter Video Title"
                isInvalid={!validated}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  props.editVideo.title = e.target.value;
                  setValidated(true);
                }}
                defaultValue={props.editVideo.title}
              />
              <Form.Control.Feedback type="invalid">
                Edit video title
              </Form.Control.Feedback>
            </Form.Group>
            {isAdmin(userCustomClaim) && (
              <Loader active={isLoading}>
                <InputLabel htmlFor="Share With" className="text-black m-2">
                  Change company
                </InputLabel>
                <AppDropdown
                  title={selectedCompany.name || "Select Company"}
                  setValue={setSelectedCompany}
                  list={companiesList}
                  saveObject={true}
                />
              </Loader>
            )}

            {!isAdmin(userCustomClaim) && (
              <Loader active={isUserListLoading && isProjectListLoading}>
                <InputLabel htmlFor="Share With" className="text-black m-2">
                  Share With
                </InputLabel>
                <Select
                  options={selectOptions}
                  isMulti={false}
                  onChange={(e: any) => {
                    setSelectOption(e.value);
                  }}
                  isSearchable={true}
                  defaultValue={
                    {
                      value: selectOption,
                      label: selectOption,
                    } as ProjectsListOptions
                  }
                  required={true}
                />
                <br />
                {selectOption === "users" && (
                  <Select
                    value={selectedUser}
                    options={usersList}
                    placeholder="Select User"
                    isMulti={true}
                    onChange={(e: any) => {
                      setSelectedUser(e);
                    }}
                    isSearchable={true}
                  />
                )}

                {selectOption === "project" && (
                  <Select
                    options={projectsList}
                    placeholder="Select Project"
                    isMulti={false}
                    onChange={(e: any) => {
                      setSelectedProject(e);
                    }}
                    isSearchable={true}
                    value={selectedProject}
                  />
                )}
              </Loader>
            )}
            <Button
              className="btn btn-primary"
              type="submit"
              disabled={isSubmitted}
            >
              Done
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

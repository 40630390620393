import { UploadVideoModalProps } from "../../../../Common/Interfaces/Videos/uploadVideo-props";
// import Modal from "react-bootstrap/esm/Modal";
import "./uploadVideoModal.scss";
// import { Form } from "react-bootstrap";
import { useCallback, useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import { videoInfo } from "../../../../Common/Interfaces/Videos/upload-video";
import {
  fetchCloudinarySignature,
  postVideo,
  uploadVideoToCloudinary,
} from "../../../../apis/services/videos.service";
import {
  addNotificationToApp,
  getCompanyId,
  isAdmin,
  isSuccessCode,
} from "../../../../Common/Common";
import { VideoInfoAxiosRequest } from "../../../../Common/Interfaces/Videos/record-video";
import { useSelector } from "react-redux";
import { UserCustomClaim } from "../../../../redux/model/UserState.d";
import { ReduxState } from "../../../../redux/model/ReduxState.d";
import { DropdownItem } from "../../../../Common/Interfaces/dashboard";
import {
  getAllCompaniesList,
  getCompanyUsersList,
} from "../../../../apis/services/company.service";

// import AppDropdown from "../../../Common/Dropdown/Dropdown";
import Select from "react-select";
import { ProjectsListOptions } from "../../../../Common/Interfaces/Videos/projects-list-options";
import { getUserProjects } from "../../../../apis/services/user-projects.service";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { VideoUploadShareOptions } from "../../../../Common/Enums/Lunch/video-upload-share-options";

export default function UploadVideoModal(props: UploadVideoModalProps) {
  const userCustomClaim = useSelector<ReduxState, UserCustomClaim>(
    (state: ReduxState) => state.UserReducer?.userPermission?.userCustomClaim
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isProjectListLoading, setIsProjectListLoading] = useState(false);
  const [projectsList, setProjectsList] = useState([] as ProjectsListOptions[]);
  const [selectedProject, setSelectedProject] = useState([] as string[]);
  const [isUserListLoading, setIsUserListLoading] = useState(false);
  const [usersList, setUsersList] = useState([] as ProjectsListOptions[]);
  const [selectedUser, setSelectedUser] = useState([] as string[]);
  const [selectOption, setSelectOption] = useState(
    VideoUploadShareOptions.COMPANY
  );

  const [companiesList, setCompaniesList] = useState([] as DropdownItem[]);
  const [selectedCompany, setSelectedCompany] = useState({} as DropdownItem);
  const [videoInfo, setVideoInfo] = useState<videoInfo>({} as videoInfo);
  useRef<any>();
  const [validated, setValidated] = useState(false);
  useRef<string>("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  console.log({ isProjectListLoading, isUserListLoading, isLoading });

  let selectOptions: ProjectsListOptions[];

  if (userCustomClaim.projectPermissionsModel.length === 0) {
    selectOptions = [
      {
        value: VideoUploadShareOptions.COMPANY,
        label: VideoUploadShareOptions.COMPANY,
      },

      {
        value: VideoUploadShareOptions.USERS,
        label: VideoUploadShareOptions.USERS,
      },
    ];
  } else {
    selectOptions = [
      {
        value: VideoUploadShareOptions.COMPANY,
        label: VideoUploadShareOptions.COMPANY,
      },
      {
        value: VideoUploadShareOptions.PROJECT,
        label: VideoUploadShareOptions.PROJECT,
      },
      {
        value: VideoUploadShareOptions.USERS,
        label: VideoUploadShareOptions.USERS,
      },
    ];
  }

  async function getUsersList() {
    const companyId = getCompanyId(userCustomClaim);
    if (companyId.length !== 0) {
      setIsUserListLoading(true);
      const users = await getCompanyUsersList(companyId);
      const filteredUsers = users.filter((user) => {
        if (props.userInfo.userId !== user.id) {
          return user;
        }
      });
      const userSelectOptions = filteredUsers.map((user) => {
        return {
          value: user.id,
          label: user.name,
        };
      });

      setUsersList(userSelectOptions);

      if (users && users.length > 0) {
        setSelectedUser([users[0].id]);
      }
      setIsUserListLoading(false);
    }
  }

  async function getProjectsList() {
    const companyId = getCompanyId(userCustomClaim);
    if (companyId.length !== 0) {
      setIsProjectListLoading(true);
      const projects = await getUserProjects(companyId, props.userInfo.userId);
      selectOptions = projects.map((project) => {
        return {
          value: project.id,
          label: project.name,
        };
      });
      setProjectsList(selectOptions);
      if (projects && projects.length > 0) {
        setSelectedProject([projects[0].id]);
      }
      setIsProjectListLoading(false);
    }
  }

  const getCompaniesList = async () => {
    const companyId = getCompanyId(userCustomClaim);
    if (companyId.length === 0) {
      setIsLoading(true);
      const companies = await getAllCompaniesList();
      setCompaniesList(companies);
      if (companies && companies.length > 0) {
        setSelectedCompany(companies[0]);
      }
      setIsLoading(false);
    } else {
      setSelectedCompany({ id: companyId, name: "" } as DropdownItem);
    }
  };
  const fetchLists = useCallback(async () => {
    await Promise.all([getCompaniesList(), getUsersList(), getProjectsList()]);
  }, []);

  useEffect(() => {
    fetchLists();
  }, []);

  const handleUpload = async (videoBlob: Blob[]) => {
    const blob = new Blob(videoBlob, { type: "video/mp4" });
    try {
      const uploadVideo = await uploadToCloudinary(blob);
      await saveVideoDetails(uploadVideo.secure_url, uploadVideo.public_id);
    } catch (e) {
      addNotificationToApp("Error", "Upload Failed", "danger");
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(false);
    } else {
      setIsSubmitted(true);
      setValidated(true);
      await handleUpload(props.videoBlob);
    }
  };

  const handleModalClose = async (event: any, reason: string) => {
    if (reason === "backdropClick") {
      event.preventDefault();
    } else {
      props.handleClose();
    }
  };

  async function uploadToCloudinary(videoBlob: Blob) {
    const cloudinarySignature = await fetchCloudinarySignature(
      props.userInfo.userId
    );
    const formData = new FormData();
    formData.append("file", videoBlob);
    cloudinarySignature.publicId &&
      formData.append("public_id", cloudinarySignature.publicId);
    return await uploadVideoToCloudinary(
      cloudinarySignature.signature,
      cloudinarySignature.timestamp,
      formData
    );
  }
  async function saveVideoDetails(videoUrl: string, fileName: string) {
    //if user is admin get value for company id from props else value is empty string
    const companyId = isAdmin(userCustomClaim) ? "" : props.userInfo.companyId;

    let sharedWithCompanies;
    if (isAdmin(userCustomClaim)) {
      sharedWithCompanies = new Set([selectedCompany.id]);
    } else {
      sharedWithCompanies =
        selectOption === VideoUploadShareOptions.COMPANY
          ? new Set([selectedCompany.id])
          : new Set();
    }

    const sharedWithProjects =
      !isAdmin(userCustomClaim) &&
      selectOption === VideoUploadShareOptions.PROJECT
        ? new Set([selectedProject])
        : new Set();

    const sharedWithUsers =
      !isAdmin(userCustomClaim) &&
      selectOption === VideoUploadShareOptions.USERS
        ? new Set([...selectedUser])
        : new Set();

    const videoReturnObject: VideoInfoAxiosRequest = {
      videoName: fileName,
      title: videoInfo.title,
      ownerId: props.userInfo.userId,
      companyId: companyId,
      videoURL: videoUrl,
      sharedWithCompanies: Array.from(sharedWithCompanies) as string[],
      sharedWithProjects: Array.from(sharedWithProjects) as string[],
      sharedWithUsers: Array.from(sharedWithUsers) as string[],
    };
    const res = await postVideo(videoReturnObject);
    isSuccessCode(res.status) && props.handleClose();
  }

  return (
    <>
      <Modal
        keepMounted
        open={props.showModal}
        onClose={handleModalClose}
        className="my-24 h-[75%] w-full flex justify-center"
      >
        <Box className="rounded-lg bg-white min-w-[800px]">
          <div className="m-4">
            <div className="flex justify-between">
              <Typography variant="h6">
                {"Video Title and Sharing Options"}
              </Typography>
              <IconButton
                aria-label="delete"
                color="primary"
                onClick={(e) => handleModalClose(e, "close")}
              >
                <CloseIcon />
              </IconButton>
            </div>

            <div>
              <Divider className="border-1 border-black	" />
            </div>

            <div className="my-2">
              {props.videoBlob.length > 0 && (
                <>
                  <h2>Recorded Video</h2>
                  <video
                    controls
                    playsInline
                    preload="auto"
                    className="w-full h-[350px]"
                    src={URL.createObjectURL(
                      new Blob(props.videoBlob, { type: "video/webm" })
                    )}
                  />
                </>
              )}
              {/* <Form noValidate validated={validated} onSubmit={handleSubmit}> */}
              <form noValidate onSubmit={handleSubmit}>
                {/* <Form.Group className="mb-3" controlId="formBasicText"> */}
                <div className="my-3" id="formBasicText">
                  {/* <Form.Label className="text-black m-2">
                    Video Title
                  </Form.Label>
                  <Form.Control
                    type="text"
                    required={true}
                    placeholder="Enter Video Title"
                    isInvalid={!validated && !videoInfo.title}
                    onChange={(e) => {
                      setVideoInfo({ ...videoInfo, title: e.target.value });
                      setValidated(true);
                    }}
                  /> */}
                  <TextField
                    type="text"
                    size="small"
                    required={true}
                    id="video-title"
                    className="w-full"
                    label="Video Title"
                    placeholder="Enter Video Title"
                    error={!validated || !videoInfo.title}
                    onChange={(e) => {
                      setVideoInfo({ ...videoInfo, title: e.target.value });
                      setValidated(true);
                    }}
                  />
                  {/* <Form.Control.Feedback type="invalid">
                    Please enter a title
                  </Form.Control.Feedback> */}
                  {/* </Form.Group> */}
                </div>
                {isAdmin(userCustomClaim) && (
                  <>
                    {/* <Loader active={isLoading}> */}
                    {/* <InputLabel htmlFor="Share With" className="text-black m-2">
                      Choose a company to share with
                    </InputLabel>
                    <AppDropdown
                      saveObject={true}
                      list={companiesList}
                      setValue={setSelectedCompany}
                      title={selectedCompany.name || "Select Company"}
                    /> */}
                    <Select
                      className="w-full"
                      isMulti={false}
                      isSearchable={false}
                      placeholder="Company"
                      options={companiesList}
                      onChange={(e: any) => {
                        const companyObj = { id: e.value, name: e.label };
                        setSelectedCompany(companyObj);
                      }}
                    />
                    {/* </Loader> */}
                  </>
                )}

                {!isAdmin(userCustomClaim) && (
                  // <Loader active={isUserListLoading && isProjectListLoading}>
                  <>
                    <InputLabel htmlFor="Share With" className="text-black m-2">
                      Share With
                    </InputLabel>
                    <Grid container justifyContent={"start"}>
                      <Grid item xs={5}>
                        <Select
                          required={true}
                          isMulti={false}
                          isSearchable={true}
                          options={selectOptions}
                          onChange={(e: any) => {
                            setSelectOption(e.value);
                          }}
                          defaultValue={selectOptions[0]}
                        />
                      </Grid>

                      <Grid item xs={1} />

                      <Grid item xs={6}>
                        {selectOption === "users" && (
                          <Select
                            isMulti={true}
                            options={usersList}
                            isSearchable={true}
                            placeholder="Select User"
                            onChange={(e) => {
                              setSelectedUser(e.map((user: any) => user.value));
                            }}
                          />
                        )}
                        {selectOption === "project" && (
                          <Select
                            isMulti={false}
                            isSearchable={true}
                            options={projectsList}
                            placeholder="Select Project"
                            onChange={(e: any) => {
                              setSelectedProject(e.value);
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <br />
                  </>
                  // </Loader>
                )}
                <div className="flex justify-end">
                  <Button
                    type="submit"
                    disabled={isSubmitted}
                    className="btn btn-primary"
                  >
                    Upload
                  </Button>
                </div>
                {/* </Form> */}
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

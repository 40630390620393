import { Column } from "react-table";
import { sortByName } from "../../../../utils/helpers/sorting";

export const COLUMNS: Column[] | any = [
  {
    Header: "Page Title",
    accessor: "pageTitle",
    sortType: sortByName,
  },
  {
    Header: "Event Count",
    accessor: "eventCount",
  },
];

// import { IconButton } from "@mui/material";
// import MenuIcon from "@mui/icons-material/Menu";
// import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import DashboardHeader from "../DashboardHeader/DashboardHeader";

type Props = {
  userId: string;
  headerRouteName: string;
};

const Header = ({ userId, headerRouteName }: Props) => {
  return (
    <>
      <div className="flex">
        <div className="justify-start m-auto">
          <div className="w-max">
            <div className="flex flex-row">
              <span className="flex items-center">{headerRouteName}</span>
            </div>
          </div>
        </div>
        <div className="w-full"></div>
        <div className="justify-end">
          <div className="w-max">
            <div className="flex flex-row">
              <div className="flex flex-row justify-start">
                {userId && <DashboardHeader />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;

import { LOGOUT, PROJECT_UPDATE } from "../Constants";
import { IEventSelectProject } from "../model/ActionsInterface.d";
import { SelectedGlobalProject } from "../model/SelectedProject.d";

const initialState: SelectedGlobalProject = {
  projectId: "",
  projectName: "",
};

const SelectedProjectReducer = (
  state = initialState,
  action: IEventSelectProject
) => {
  switch (action.type) {
    case PROJECT_UPDATE:
      return {
        ...state,
        projectId: action.projectId,
        projectName: action.projectName,
      };
    case LOGOUT:
      return {
        ...state,
        projectId: "",
        projectName: "",
      };
    default:
      return state;
  }
};

export default SelectedProjectReducer;

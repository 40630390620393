import { AxiosResponse } from "axios";
import {
  ProjectPermissionModel,
  UserCompanyPermissionResponse,
} from "../../redux/model/UserState.d";
import { getData, updateData } from "../axios-calls/common-axios-service";
import { UserInformation } from "../../Components/Login/user";

export const updateUserName = async (
  userId: string,
  userName: string
): Promise<AxiosResponse> => {
  const res = await updateData(`user/${userId}/userName`, {
    name: userName,
  });
  return res as AxiosResponse;
};

export const getUserCompanyPermissionsData = async (
  userId: string
): Promise<UserCompanyPermissionResponse> => {
  const res = await getData(`user/${userId}/companyPermissions`);
  return res as UserCompanyPermissionResponse;
};

export const getUserProjectPermissionModelByUserId = async (
  userId: string
): Promise<ProjectPermissionModel[]> => {
  const fetchedPermissions = await getData(`user/${userId}/projectPermissions`);
  return fetchedPermissions;
};
export const getUserByUserId = async (
  userId: string
): Promise<UserInformation> => {
  const fetchedPermissions = await getData(`user/${userId}`);
  return fetchedPermissions;
};

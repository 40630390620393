import { useEffect, useState } from "react";

import { Column } from "react-table";

import { useSelector } from "react-redux";

import Select from "react-select";

import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";

import moment from "moment";
import HashMap from "hashmap";

import { Grid, Typography } from "@mui/material";

import {
  getDailyAttendance,
  updateDailyAttendance,
} from "../../../../../apis/services/attendance.service";
import { getAllCompaniesList } from "../../../../../apis/services/company.service";

import {
  getCompanyId,
  hasPermission,
  isAdmin,
  isCompanyAdmin,
  isSuccessCode,
} from "../../../../../Common/Common";
import { AttendanceStatus } from "../../../../../Common/Enums/dashboard";
import {
  DropdownItem,
  ReactSelect,
} from "../../../../../Common/Interfaces/dashboard";
import BasicTable from "../../../../Common/Table/BasicTable";
import { CompanyLevelPermissions } from "../../../../../Common/Enums/permissions";
import { Attendance } from "../../../../../Common/Interfaces/Attendance/attendance";
import AttendanceDropdown from "../../../../Common/AttendanceDropdown/AttendanceDropdown";

import { ReduxState } from "../../../../../redux/model/ReduxState.d";
import { UserCustomClaim } from "../../../../../redux/model/UserState.d";

interface UserObjFromResponse {
  userId: string;
  userName: string;
}

export default function DailyAttendance() {
  const [startDate, setStartDate] = useState<Date>(
    new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
  );
  const [endDate, setEndDate] = useState<Date>(
    new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
  );
  const [dates, setDates] = useState([] as Date[]);
  const [attendanceData, setAttendanceData] = useState([] as Attendance[]);
  const [companiesList, setCompaniesList] = useState([] as ReactSelect[]);
  const [selectedCompany, setSelectedCompany] = useState({} as DropdownItem);
  // const [isLoading, setIsLoading] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [showError, setShowError] = useState(
    moment(new Date()).format("ddd") !== "Mon"
  );
  const [dailyAttendanceData, setDailyAttendanceData] = useState([] as any);
  const [tableKey, setTableKey] = useState(0);

  const userCustomClaim = useSelector<ReduxState, UserCustomClaim>(
    (state: ReduxState) => state.UserReducer?.userPermission?.userCustomClaim
  );
  const userId = useSelector<ReduxState, string>(
    (state: ReduxState) => state.UserReducer.userId || ""
  );
  const [attendanceDataMap, setAttendanceDataMap] = useState(
    new HashMap<string, HashMap<string, AttendanceStatus>>()
  );

  useEffect(() => {
    setCanUpdate(
      hasPermission(userCustomClaim, CompanyLevelPermissions.UPDATE_ATTENDANCE)
    );
    if (
      hasPermission(userCustomClaim, CompanyLevelPermissions.UPDATE_ATTENDANCE)
    ) {
      getCompaniesList();
    }
  }, [userCustomClaim]);

  useEffect(() => {
    if (selectedCompany?.id?.length > 0) {
      getAttendance();
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (dailyAttendanceData?.length) {
      setTableKey((prevKey) => prevKey + 1);
    }
  }, [dailyAttendanceData]);

  const getDatesBetween = (sDate: Date, eDate: Date) => {
    const dates = [];
    const currentDate = new Date(sDate);
    while (currentDate < eDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    dates.push(eDate);
    return dates;
  };

  const datesUpdateHandler = (firstDate: Date, lastDate: Date) => {
    setStartDate(firstDate);
    setEndDate(lastDate);
    const datesArray = getDatesBetween(firstDate, lastDate);
    setDates(datesArray);
  };

  // Use the useCallback function as a dependency for a useEffect
  useEffect(() => {
    getAttendance();
  }, [dates]);

  const getCompaniesList = async () => {
    const companyId = getCompanyId(userCustomClaim);
    if (companyId.length === 0) {
      // setIsLoading(true);
      const companies = await getAllCompaniesList();
      const companiesDropDownData = companies.map(
        (company) => ({ value: company.id, label: company.name } as ReactSelect)
      );
      setCompaniesList(companiesDropDownData);
      if (companies && companies.length > 0) {
        setSelectedCompany(companies[0]);
      }
      // setIsLoading(false);
    } else {
      setSelectedCompany({ id: companyId, name: "" } as DropdownItem);
    }
  };

  const getAttendance = async () => {
    let companyId, currentUserId;
    if (selectedCompany?.id) {
      // setIsLoading(true);
      companyId = selectedCompany.id;
      currentUserId = hasPermission(
        userCustomClaim,
        CompanyLevelPermissions.GET_ATTENDANCE
      )
        ? ""
        : userId;
    } else {
      // setIsLoading(true);
      companyId = userCustomClaim?.companyId;
      currentUserId = userId;
    }

    if (companyId) {
      const fetchedAttendance = await getDailyAttendance(
        companyId,
        moment(startDate).format("MM/DD/yyyy"),
        moment(endDate).format("MM/DD/yyyy"),
        currentUserId
      );

      setAttendanceData(fetchedAttendance);

      const mappedData = new HashMap<
        string,
        HashMap<string, AttendanceStatus>
      >();
      fetchedAttendance.forEach((a: Attendance) => {
        const date = moment(a.date).format("MM/DD/yyyy");
        if (!mappedData.has(date)) {
          mappedData.set(
            date,
            new HashMap<string, AttendanceStatus>(a.userId, a.status)
          );
        } else {
          mappedData.get(date)?.set(a.userId, a.status);
        }
      });
      const users: DropdownItem[] = [];
      fetchedAttendance.forEach((x) => {
        if (users.findIndex((user) => user.id === x.userId) == -1) {
          users.push({ id: x.userId, name: x.userName } as DropdownItem);
        }
      });
      // generate table data
      const tableData = users.map((user) => {
        const userToUpdate = [];
        const userObject: UserObjFromResponse | any = {
          userId: user?.id,
          userName: user?.name,
        };
        dates.map((d) => {
          const status = mappedData
            .get(moment(d).format("MM/DD/yyyy"))
            ?.get(user.id);
          const dateString: any = moment(d).format("MM/DD/yyyy");
          if (status) {
            userObject[dateString] = status;
          } else {
            userObject[dateString] = "-";
          }
        });
        userToUpdate.push(userObject);
        return userToUpdate;
      });

      setDailyAttendanceData(tableData.flat(1));
      setAttendanceData(fetchedAttendance);
      setAttendanceDataMap(mappedData);
      // setIsLoading(false);
    }
  };

  const handleAttendanceStatusChange = async (
    userId: string,
    date: string,
    status: AttendanceStatus
  ) => {
    const attendance = attendanceData.filter(
      (x) => x.userId === userId && moment(x.date).format("MM/DD/yyyy") === date
    )[0];
    const res = await updateDailyAttendance(
      attendance.companyId,
      attendance.id,
      status
    );
    if (res && isSuccessCode(res.status)) {
      const amap = attendanceDataMap.clone();
      amap.get(date)?.set(userId, status);
      setAttendanceDataMap(amap);
    }
  };

  const generateColumns = () => {
    const columns: any = [];
    const uniqueColumnIds = new Set(); // Keep track of unique column identifiers

    if (dailyAttendanceData?.length > 0) {
      dailyAttendanceData.forEach((data: any) => {
        Object.keys(data).forEach((dataKey) => {
          if (dataKey !== "userId" && !uniqueColumnIds.has(dataKey)) {
            const column = {
              id: Math.random().toString(),
              Header:
                dataKey === "userName"
                  ? "Name"
                  : moment(dataKey).format("DD MMMM ddd"),
              accessor: dataKey,
              disableSortBy: true,
              Cell: ({ row }: any) => {
                const attendanceTableDataValue = row?.original?.[dataKey];

                if (
                  Object.values(AttendanceStatus).includes(
                    attendanceTableDataValue
                  ) &&
                  isCompanyAdmin(userCustomClaim)
                ) {
                  return (
                    <AttendanceDropdown
                      attendanceStatus={attendanceTableDataValue}
                      onChange={(e) =>
                        handleAttendanceStatusChange(
                          row?.original?.userId,
                          moment(dataKey).format("MM/DD/yyyy"),
                          e.value
                        )
                      }
                      target={document.body}
                      isDisabled={!canUpdate}
                      width="160px"
                    />
                  );
                } else {
                  return attendanceTableDataValue;
                }
              },
            };
            columns.push(column);
            uniqueColumnIds.add(dataKey);
          }
        });
      });
    }

    return columns;
  };

  const tableHooks = (hooks: any) => {
    hooks.visibleColumns.push((columns: any) => [...columns]);
  };

  const COLUMNS: Column[] | any = [...generateColumns()];

  return (
    <>
      {/* <Loader active={isLoading}> */}
      <div className="mt-5">
        <Grid container spacing={2}>
          <>
            {isAdmin(userCustomClaim) && (
              <Grid item xs={2} className="flex" justifyContent={"start"}>
                <Select
                  className="w-72"
                  options={companiesList}
                  placeholder="Company"
                  isMulti={false}
                  onChange={(e: any) => {
                    const companyObj = { id: e.value, name: e.label };
                    setSelectedCompany(companyObj);
                  }}
                  isSearchable={false}
                />
              </Grid>
            )}
            <Grid item xs={10} justifyContent={"start"}>
              <DateRangePickerComponent
                width={250}
                maxDays={14}
                max={new Date()}
                format="dd-MMM-yy"
                endDate={endDate}
                delayUpdate={false}
                startDate={startDate}
                showClearButton={false}
                onChange={(range: any) => {
                  if (moment(range.value[0]).format("ddd") === "Mon") {
                    setShowError(false);
                    datesUpdateHandler(range.value[0], range.value[1]);
                  }
                }}
              />
              {showError && (
                <Grid item xs={7}>
                  <Typography variant="caption" className="text-gray-700">
                    <strong>Note:</strong> Start Date should be Monday!
                  </Typography>
                </Grid>
              )}
            </Grid>
          </>
        </Grid>

        {!showError && (
          <Grid container spacing={1} className="my-2">
            <Grid item xs={12} className="w-[500px]">
              {dailyAttendanceData?.length > 0 && (
                <BasicTable
                  key={tableKey}
                  tableColumns={COLUMNS}
                  tableData={dailyAttendanceData}
                  headerColor="bg-gray-850"
                  maxHeight={500}
                  tableHooks={tableHooks}
                />
              )}
            </Grid>
          </Grid>
        )}
      </div>
      {/* </Loader> */}
    </>
  );
}

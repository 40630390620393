import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { getAllCompaniesList } from "../../../../apis/services/company.service";
import {
  addAllInDropdown,
  getCompanyId,
  isAdmin,
  isCompanyAdmin,
} from "../../../../Common/Common";
import {
  DropdownItem,
  MembersTabFilterProps,
  ReactSelect,
} from "../../../../Common/Interfaces/dashboard";
import { ReduxState } from "../../../../redux/model/ReduxState.d";
import { UserCustomClaim } from "../../../../redux/model/UserState.d";
import { FormControl, Button, Grid, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Select from "react-select";

export default function MembersTabFilters(props: MembersTabFilterProps) {
  const [companies, setCompanies] = useState([] as ReactSelect[]);
  const [members, setMembers] = useState(
    props.membersForFilter || ([] as ReactSelect[] | [] as DropdownItem[])
  );
  const [projects, setProjects] = useState(
    props.projectsForFilter || ([] as ReactSelect[] | [] as DropdownItem[])
  );
  const [companiesLoading, setCompaniesLoading] = useState(false);
  const userCustomClaim = useSelector<ReduxState, UserCustomClaim>(
    (state: ReduxState) => state.UserReducer?.userPermission?.userCustomClaim
  );

  useEffect(() => {
    const newMembers = addAllInDropdown(props.membersForFilter, "Members");
    const membersDropDownData = newMembers.map(
      (member) => ({ value: member.id, label: member.name } as ReactSelect)
    );
    setMembers(membersDropDownData as any);
  }, [props.membersForFilter]);

  useEffect(() => {
    const newProjects = addAllInDropdown(props.projectsForFilter, "Projects");
    setProjects(newProjects as any);
  }, [props.projectsForFilter]);

  useEffect(() => {
    if (userCustomClaim) {
      getCompanies();
    }
  }, [userCustomClaim]);

  const getCompanies = async () => {
    const userCompany = getCompanyId(userCustomClaim);
    if (userCompany.length > 0) {
      props.setSelectedCompany({ id: userCompany, name: "" });
    } else {
      setCompaniesLoading(true);
      const companiesInfo = await getAllCompaniesList();
      const companiesDropDownData = companiesInfo.map(
        (company) => ({ value: company.id, label: company.name } as ReactSelect)
      );
      setCompanies(companiesDropDownData);
      if (companiesInfo.length > 0) {
        props.setSelectedCompany(companiesInfo[0]);
      }
      setCompaniesLoading(false);
    }
  };

  return (
    <>
      <FormControl sx={{ m: 1, minWidth: "100%" }} size="small">
        <Grid container alignItems="center" spacing={2}>
          {isAdmin(userCustomClaim) && (
            <>
              <Grid item xs={12} md={3} className="text-center py-0">
                {companiesLoading && <Skeleton height={35} width={180} />}

                {!companiesLoading && (
                  <>
                    <Select
                      className="w-72"
                      options={companies}
                      placeholder="Companies"
                      isMulti={false}
                      onChange={(e: any) => {
                        const companyObj = { id: e.value, name: e.label };
                        props.setSelectedCompany(companyObj);
                      }}
                      isSearchable={false}
                    />
                  </>
                )}
              </Grid>

              <Grid item xs={12} md={3} className="text-center py-0">
                <>
                  <Select
                    className="w-72"
                    options={projects}
                    placeholder="Projects"
                    isMulti={false}
                    onChange={(e: any) => {
                      const companyObj = { id: e.value, name: e.label };
                      props.setSelectedProject(companyObj);
                    }}
                    isSearchable={false}
                  />
                </>
              </Grid>
            </>
          )}

          {isCompanyAdmin(userCustomClaim) && (
            <Grid
              item
              xs={12}
              md={isCompanyAdmin(userCustomClaim) ? 4 : 3}
              className="text-center py-0"
            >
              <>
                <Select
                  className="w-72"
                  options={projects}
                  placeholder="Projects"
                  isMulti={false}
                  onChange={(e: any) => {
                    const companyObj = { id: e.value, name: e.label };
                    props.setSelectedProject(companyObj);
                  }}
                  isSearchable={false}
                />
              </>
            </Grid>
          )}

          <Grid item xs={12} md={4} className="text-center py-0">
            <>
              <Select
                className="w-72"
                options={members}
                placeholder="Members"
                isMulti={false}
                onChange={(e: any) => {
                  const membersObj = { id: e.value, name: e.label };
                  props.setSelectedMember(membersObj);
                }}
                isSearchable={false}
              />
            </>
          </Grid>

          <Grid item xs={12} md={3} className="text-center py-0">
            {props.isLoading && <Skeleton height={35} width={180} />}

            {!props.isLoading && (
              <Button
                onClick={() => props.getMembers()}
                startIcon={<SearchIcon />}
                variant="contained"
              >
                Find Members
              </Button>
            )}
          </Grid>
        </Grid>

        <Grid container className="my-2">
          <Grid item>
            <Typography variant="caption" className="text-gray-600">
              {" "}
              Please click on "Find Members" to display results{" "}
            </Typography>
          </Grid>
        </Grid>
      </FormControl>
    </>
  );
}

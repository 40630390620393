import { useEffect, useState } from "react";

// import { Col, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import DomainAddIcon from "@mui/icons-material/DomainAdd";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Button, Grid, IconButton } from "@mui/material";

import {
  deleteCompany,
  getAllCompanies,
} from "../../../apis/services/company.service";

import {
  addDefaultSrc,
  getCompanyId,
  isAdmin,
  isCompanyAdmin,
  isSuccessCode,
} from "../../../Common/Common";
import { Company } from "../../../Common/Interfaces/dashboard";
import Alert from "../../Common/Alert/Alert";
import DeleteModal from "../../Common/Popup/DeleteModal";

import BasicTable from "../../Common/Table/BasicTable";
import AddEditCompanyModal from "./AddEditCompanyModal/AddEditCompanyModal";

import { ReduxState } from "../../../redux/model/ReduxState.d";
import { UserCustomClaim } from "../../../redux/model/UserState.d";

import { COLUMNS } from "./dataColumns";

import { MaxPageData } from "../../../Common/Enums/pagination";
import { useLogEvent } from "../Analytics/hooks/useLogEvent";
import "../Company/CompanyTab.scss";

export default function CompanyTab() {
  const [companies, setCompanies] = useState([] as Company[]);
  const [showModal, setShowModal] = useState(false);
  const [editCompany, setEditCompany] = useState({} as Company);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletedCompany, setDeletedCompany] = useState({} as Company);
  const userCustomClaim = useSelector<ReduxState, UserCustomClaim>(
    (state: ReduxState) => state.UserReducer?.userPermission?.userCustomClaim
  );
  const [pageNumber, setPageNumber] = useState(0);
  const [prePageNumber, setPrePageNumber] = useState(0);

  const handleClose = () => setShowModal(false);

  const handleDeleteCompany = (company: Company) => {
    setDeletedCompany(company);
    setShowDeleteModal(true);
  };

  const handleDelete = async () => {
    setShowDeleteModal(false);
    const res = await deleteCompany(deletedCompany.id);
    if (res && isSuccessCode(res?.status)) {
      deleteCompanyData(deletedCompany.id);
    }
  };
  const handleShow = (isEdit: boolean, company: Company) => {
    if (isEdit) {
      setEditCompany(company);
    } else {
      setEditCompany({
        id: "",
        name: "",
        email: "",
        profilePicture: "",
      } as Company);
    }
    setShowModal(true);
  };

  useEffect(() => {
    if (userCustomClaim) {
      getCompanies();
    }
  }, [userCustomClaim, pageNumber]);

  const getCompanies = async (startAt = "") => {
    let endBefore = "";
    if (startAt === "")
      if (pageNumber > prePageNumber)
        startAt = companies[companies.length - 1].id ?? "";
      else if (pageNumber < prePageNumber) {
        endBefore = companies[0].id ?? "";
      }
    setIsLoading(true);
    const companyId = getCompanyId(userCustomClaim);
    const companiesInfo = await getAllCompanies(companyId, startAt, endBefore);
    setCompanies(companiesInfo);
    pageNumber === 1 && pageNumber < prePageNumber
      ? setPrePageNumber(0)
      : setPrePageNumber(pageNumber);
    setIsLoading(false);
  };

  const addCompany = (updatedCompany: Company, isEdit = false) => {
    if (!isEdit) {
      if (companies.length < 6) {
        const companiesList = [...companies, updatedCompany];
        setCompanies(companiesList);
      }
    } else {
      const updatedCompanies = companies.map((company: Company) => {
        if (company.id === editCompany.id) {
          return {
            id: editCompany.id,
            name: updatedCompany.name,
            email: updatedCompany.email,
            profilePicture: updatedCompany.profilePicture,
          } as Company;
        } else {
          return company;
        }
      });
      setCompanies(updatedCompanies);
    }
    setShowModal(false);
  };
  const deleteCompanyData = (companyId: string) => {
    if (companies.length === 1) setPageNumber((pageNumber) => pageNumber - 1);
    else {
      if (companies.length === 6) {
        companies[0].id === companyId
          ? getCompanies(companies[1].id)
          : getCompanies(companies[0].id);
      } else {
        const companiesList = [...companies];
        for (let i = 0; i < companiesList.length; i++) {
          if (companiesList[i].id === companyId) {
            i === 0
              ? getCompanies(companiesList[1].id)
              : companiesList.splice(i, 1);
          }
        }
        setCompanies(companiesList);
      }
    }
  };
  const companiesAvailable = (): boolean => {
    if (!companies || (companies && companies.length === 0)) {
      return false;
    } else {
      return true;
    }
  };
  useLogEvent("page_view", "Company");

  const tableHooks = (hooks: any) => {
    hooks.visibleColumns.push((columns: any) => [
      {
        id: "companylogo",
        Header: "Logo",
        accessor: "profilePicture",
        disableSortBy: true,
        Cell: ({ row }: any) => {
          return (
            <img
              src={row.original.profilePicture}
              onError={(e) => addDefaultSrc(e)}
              alt="Picture"
              className="w-8 h-8"
            />
          );
        },
      },
      ...columns,
      {
        id: "companyactions",
        Header: "Actions",
        accessor: "actions",
        disableSortBy: true,
        Cell: ({ row }: any) => {
          return (
            <>
              {(isAdmin(userCustomClaim) ||
                isCompanyAdmin(userCustomClaim)) && (
                <IconButton
                  aria-label="Edit"
                  color="primary"
                  onClick={() => handleShow(true, row.original)}
                >
                  <EditIcon fontSize="medium" />
                </IconButton>
              )}
              {(isAdmin(userCustomClaim) ||
                isCompanyAdmin(userCustomClaim)) && (
                <IconButton
                  aria-label="Delete"
                  color="error"
                  onClick={() => handleDeleteCompany(row.original)}
                >
                  <DeleteIcon fontSize="medium" />
                </IconButton>
              )}
            </>
          );
        },
      },
    ]);
  };

  return (
    <>
      <div className="mt-4">
        {/* <Loader active={isLoading}> */}
        <Grid container justifyContent={"right"} className="float-right">
          <Grid item xs={6}>
            {isAdmin(userCustomClaim) && !isLoading && companiesAvailable() && (
              <Button
                aria-label="add"
                color="primary"
                onClick={() => handleShow(false, {} as Company)}
                variant="contained"
                className="my-3"
              >
                <DomainAddIcon fontSize="medium" /> &nbsp;
                <span className="text-sm">Add New Company</span>
              </Button>
            )}
          </Grid>
        </Grid>

        {isLoading && (
          <>
            <Skeleton height={50} />
            <Skeleton height={50} />
            <Skeleton height={50} />
          </>
        )}

        {!isLoading && !companiesAvailable() && (
          <Alert message={"No Companies Available"} />
        )}
        <Grid container justifyContent={"center"} className="float-right">
          <Grid item xs={12}>
            {!isLoading && companiesAvailable() && (
              <BasicTable
                tableColumns={COLUMNS}
                tableData={companies}
                headerColor="bg-gray-850"
                maxHeight={550}
                tableHooks={tableHooks}
              />
            )}
          </Grid>
        </Grid>
        {!isLoading && companiesAvailable() && (
          <div className="inline-flex float-right my-2">
            {pageNumber === 0 && (
              <span>
                <IconButton
                  aria-label="left"
                  color="primary"
                  disabled={pageNumber === 0}
                >
                  <NavigateBeforeIcon fontSize="large" />
                </IconButton>
              </span>
            )}
            {pageNumber > 0 && (
              <span>
                <IconButton aria-label="left" color="primary">
                  <NavigateBeforeIcon
                    fontSize="large"
                    onClick={() => {
                      setPageNumber((pageNumber) => pageNumber - 1);
                    }}
                  />
                </IconButton>
              </span>
            )}
            {companies.length !== MaxPageData.DATA_SIZE && (
              <span>
                <IconButton aria-label="right" color="primary" disabled>
                  <NavigateNextIcon fontSize="large" />
                </IconButton>
              </span>
            )}
            {companies.length === MaxPageData.DATA_SIZE && (
              <span>
                <IconButton aria-label="right" color="primary">
                  <NavigateNextIcon
                    fontSize="large"
                    onClick={() => {
                      setPageNumber((pageNumber) => pageNumber + 1);
                    }}
                  />
                </IconButton>
              </span>
            )}
          </div>
        )}
        {/* </Loader> */}
      </div>

      {showModal && (
        <AddEditCompanyModal
          showModal={showModal}
          handleClose={handleClose}
          editCompany={editCompany}
          addCompany={addCompany}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          showModal={showDeleteModal}
          handleClose={() => setShowDeleteModal(false)}
          // selectedCompany={deleteCompany}
          handleDelete={handleDelete}
          message="Are you sure you want to delete this Member from Company?"
        />
      )}
    </>
  );
}

import { Card, Col, Modal, Row } from "react-bootstrap";
import { WorklogDetailModalProps } from "../../../../../Common/Interfaces/dashboard";
import Button from "../../../../Common/Button/Button";
import TextField from "../../../../Common/TextField/TextField";

export default function WorklogDetailModal(props: WorklogDetailModalProps) {
  return (
    <Modal
      size="lg"
      show={props.showModal}
      onHide={() => props.handleClose(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Worklog Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <label className="fw-bold">Project Name</label>
            <TextField
              id={"projectNameField"}
              type={"text"}
              placeholder={""}
              width={""}
              defaultValue={props.worklog?.projectName}
              fieldName={"Project Name"}
              disabled={true}
            ></TextField>
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="fw-bold">User Name</label>
            <TextField
              id={"userNameField"}
              type={"text"}
              placeholder={""}
              width={""}
              defaultValue={props.worklog?.userName}
              fieldName={"User Name"}
              disabled={true}
            ></TextField>
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <label className="fw-bold">Ticket Name</label>
            <TextField
              id={"ticketNameField"}
              type={"text"}
              placeholder={""}
              width={""}
              defaultValue={props.worklog?.ticketName}
              fieldName={"Ticket Name"}
              disabled={true}
            ></TextField>
          </Col>
          <Col>
            <label className="fw-bold">Ticket Url</label>
            <TextField
              id={"ticketUrlField"}
              type={"text"}
              placeholder={""}
              width={""}
              defaultValue={props.worklog?.ticketUrl}
              fieldName={"Ticket URL"}
              disabled={true}
            ></TextField>
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="fw-bold">Description</label>
            <Card>
              <Card.Body>{props.worklog?.ticketDescription}</Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <label className="fw-bold">Time</label>
            <TextField
              id={"worklogMinutesField"}
              type={"text"}
              placeholder={""}
              width={""}
              defaultValue={props.worklog?.worklogMinutes}
              fieldName={"Time"}
              disabled={true}
            ></TextField>
          </Col>
          <Col>
            <label className="fw-bold">Billable</label>
            <TextField
              id={"billableField"}
              type={"text"}
              placeholder={""}
              width={""}
              defaultValue={props.worklog?.billable ? "Yes" : "No"}
              fieldName={"Billable"}
              disabled={true}
            ></TextField>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => props.handleClose(false)}
          text="Close"
          additionalClasses={"gray-button"}
        />
      </Modal.Footer>
    </Modal>
  );
}

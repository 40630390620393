import moment from "moment";
import { Column } from "react-table";

export const COLUMNS: Column[] | any = [
  {
    Header: "Title",
    accessor: "title",
    Cell: ({ row }: any) => {
      return (
        <div>
          <p>
            <a href={row.original.videoURL} target="_blank">
              {row.original.title}
            </a>
          </p>
        </div>
      );
    },
  },
  {
    Header: "Created At",
    accessor: "createdAt",
    Cell: ({ value }: any) => {
      return (
        <div>
          <p>{moment(value).format("DD MMMM, yyyy")}</p>
        </div>
      );
    },
  },
];

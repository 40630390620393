import { useSelector } from "react-redux";
import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";

import MyVideos from "./VideoTypes/my-videos";
import RecordVideo from "./VideoTypes/record-video";
import SharedWithMe from "./VideoTypes/shared-with-me";

import { isAdmin } from "../../../Common/Common";

import { ReduxState } from "../../../redux/model/ReduxState.d";
import { UserCustomClaim } from "../../../redux/model/UserState.d";

import { Tab, Tabs } from "@mui/material";
import OndemandVideoOutlinedIcon from "@mui/icons-material/OndemandVideoOutlined";
import VideoCameraFrontOutlinedIcon from "@mui/icons-material/VideoCameraFrontOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";

import "../Videos/Videos.scss";

export default function VideoTab() {
  const navigate = useNavigate();
  const userCustomClaim = useSelector<ReduxState, UserCustomClaim>(
    (state: ReduxState) => state.UserReducer?.userPermission?.userCustomClaim
  );

  const handleTabChange = (event: React.SyntheticEvent, tabValue: string) => {
    navigate(tabValue);
  };

  return (
    <>
      <Tabs
        value={location.pathname.split("/").pop()}
        onChange={handleTabChange}
        className="h-[60px]"
      >
        <Tab
          label="Record Video"
          value="record-a-video"
          iconPosition="start"
          icon={<OndemandVideoOutlinedIcon />}
        />
        <Tab
          label="My Videos"
          value="my-videos"
          iconPosition="start"
          icon={<VideoCameraFrontOutlinedIcon />}
        />
        {!isAdmin(userCustomClaim) && (
          <Tab
            label="Shared With Me"
            value="shared-with-me"
            iconPosition="start"
            icon={<ShareOutlinedIcon />}
          />
        )}
      </Tabs>
      <Outlet />
      <Routes>
        <Route path="record-a-video" element={<RecordVideo />} />
        <Route path="my-videos" element={<MyVideos />} />
        <Route path="/" element={<Navigate replace to="record-a-video" />} />
        {!isAdmin(userCustomClaim) && (
          <Route path="shared-with-me" element={<SharedWithMe />} />
        )}
      </Routes>
    </>
  );
}

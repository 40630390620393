import { useEffect, useState } from "react";

import Select from "react-select";
import { useSelector } from "react-redux";

import HashMap from "hashmap";

import EditIcon from "@mui/icons-material/Edit";
import { Grid, IconButton, Button } from "@mui/material";
import FastfoodOutlinedIcon from "@mui/icons-material/FastfoodOutlined";

import {
  getCompanyId,
  hasPermission,
  isAdmin,
  isSuccessCode,
} from "../../../../Common/Common";
import Alert from "../../../Common/Alert/Alert";
import {
  DropdownItem,
  ReactSelect,
} from "../../../../Common/Interfaces/dashboard";
import BasicTable from "../../../Common/Table/BasicTable";
import { DayNumber } from "../../../../Common/Enums/day-number";
import { Menu } from "../../../../Common/Interfaces/Lunch/menu-item";
import { CompanyLevelPermissions } from "../../../../Common/Enums/permissions";
import { MenuIdResponse } from "../../../../Common/Interfaces/Lunch/menu-id.res";

import { ReduxState } from "../../../../redux/model/ReduxState.d";
import { UserCustomClaim } from "../../../../redux/model/UserState.d";

import {
  getWeeklyMenu,
  updateMenuItem,
} from "../../../../apis/services/menus.service";
import { getAllCompaniesList } from "../../../../apis/services/company.service";

import { COLUMNS } from "./dataColumns";

import EditItemModal from "./EditItemModal";
import AddMenuItemModal from "./AddEditMenuItemModal";

export default function MenuTabNew() {
  const [menuData, setMenuData] = useState([] as Menu[]);
  const [showModal, setShowModal] = useState(false);
  const [companiesList, setCompaniesList] = useState([] as ReactSelect[]);
  const [selectedCompany, setSelectedCompany] = useState({} as DropdownItem);
  const userCustomClaim = useSelector<ReduxState, UserCustomClaim>(
    (state: ReduxState) => state.UserReducer?.userPermission?.userCustomClaim
  );
  const [textFieldValuesMap, setTextFieldValuesMap] = useState(
    new HashMap<DayNumber, string>()
  );
  const [showTextFieldMap, setShowTextFieldMap] = useState(
    new HashMap<DayNumber, boolean>()
  );

  const [menuEditModal, setMenuEditModal] = useState({
    row: {},
    displayModal: false,
  });

  useEffect(() => {
    if (userCustomClaim) {
      getCompaniesList();
    }
  }, [userCustomClaim]);

  useEffect(() => {
    selectedCompany?.id && getMenuItems();
  }, [selectedCompany?.id]);

  const getCompaniesList = async () => {
    const companyId = getCompanyId(userCustomClaim);
    if (companyId.length === 0) {
      const companies = await getAllCompaniesList();
      const companiesDropDownData = companies.map(
        (company) => ({ value: company.id, label: company.name } as ReactSelect)
      );
      setCompaniesList(companiesDropDownData);
      if (companies && companies.length > 0) {
        setSelectedCompany(companies[0]);
      }
    } else {
      setSelectedCompany({ id: companyId, name: "" } as DropdownItem);
    }
  };

  const getMenuItems = async () => {
    if (selectedCompany && selectedCompany.id) {
      const fetchedMenuItems = await getWeeklyMenu(selectedCompany.id);
      setMenuData(fetchedMenuItems);
    }
  };

  const editMenuItemOnView = (updatedMenuItem: Menu) => {
    const updatedMenu = menuData.map((m: Menu) => {
      if (m.dayNumber === updatedMenuItem.dayNumber) {
        return {
          id: m.id,
          dayNumber: m.dayNumber,
          itemName: updatedMenuItem.itemName,
        } as Menu;
      } else {
        return m;
      }
    });
    setMenuData(updatedMenu);
  };

  const addMenuItemOnView = (
    menuItemsByDateMap: HashMap<DayNumber, string>,
    menuResponseData: MenuIdResponse[]
  ) => {
    const menu: Menu[] = [...menuData];
    menuItemsByDateMap.forEach(function (value, key) {
      menu.push({
        id: menuResponseData.find((x) => x.dayNumber === key)?.id || "",
        dayNumber: key,
        itemName: value,
      } as Menu);
    });
    setMenuData(menu);
  };

  const handleEdit = async (
    menuId: string,
    dayNumber: DayNumber,
    itemName: string
  ) => {
    const menuBody = {
      itemName: itemName,
      dayNumber: dayNumber,
    } as Menu;
    const res = await updateMenuItem(selectedCompany.id, menuId, menuBody);
    if (res && isSuccessCode(res?.status)) {
      editMenuItemOnView(menuBody);
      const amap = showTextFieldMap.clone();
      amap.set(dayNumber, false);
      setShowTextFieldMap(amap);
      setMenuEditModal({ row: {}, displayModal: false });

      // Update menu data on successful edit
      const updatedMenuData = menuData.map((item) => {
        if (item.id === menuId && item.dayNumber === dayNumber) {
          item.itemName = itemName;
        }
        return item;
      });
      setMenuData(updatedMenuData);
    }
  };

  const canEdit = (dayNumber: DayNumber): boolean => {
    if (menuData && menuData.findIndex((x) => x.dayNumber === dayNumber) >= 0) {
      return false;
    } else {
      return true;
    }
  };

  const menuDataExists = (): boolean => {
    if (menuData && menuData.length > 0) return true;
    else return false;
  };

  const tableHooks = (hooks: any) => {
    hooks.visibleColumns.push((columns: any) => [
      ...columns,
      {
        id: `${Math.random()}`,
        Header: "Item",
        accessor: "itemName",
        disableSortBy: true,
        Cell: ({ row }: any) => {
          return <span>{row?.original?.itemName}</span>;
        },
      },
      {
        id: "menuactions",
        Header: "Actions",
        accessor: "actions",
        disableSortBy: true,
        Cell: ({ row }: any) => {
          return (
            <>
              {hasPermission(
                userCustomClaim,
                CompanyLevelPermissions.UPDATE_LUNCH_MENU
              ) && (
                <td className="text-center">
                  <IconButton
                    aria-label="Delete"
                    color="primary"
                    onClick={() => {
                      const amap = showTextFieldMap.clone();
                      amap.set(
                        row?.original?.dayNumber,
                        !amap.get(row?.original?.dayNumber)
                      );
                      setShowTextFieldMap(amap);
                      setMenuEditModal({
                        row: row?.original,
                        displayModal: true,
                      });
                    }}
                  >
                    <EditIcon fontSize="medium" />
                  </IconButton>
                </td>
              )}
            </>
          );
        },
      },
    ]);
  };

  return (
    <>
      <div className="mt-2">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            {isAdmin(userCustomClaim) && (
              <Select
                className="w-72"
                options={companiesList}
                placeholder="Company"
                isMulti={false}
                onChange={(e: any) => {
                  const companyObj = { id: e.value, name: e.label };
                  setSelectedCompany(companyObj);
                }}
                isSearchable={false}
                styles={{
                  container: (provided) => ({
                    ...provided,
                    marginTop: 0,
                  }),
                }}
              />
            )}
          </Grid>

          <Grid item xs={6} className="flex justify-end">
            {hasPermission(
              userCustomClaim,
              CompanyLevelPermissions.CREATE_LUNCH_MENU
            ) && (
              <Button
                aria-label="add"
                color="primary"
                onClick={() => setShowModal(true)}
                variant="contained"
                className="my-3"
              >
                <FastfoodOutlinedIcon fontSize="medium" /> &nbsp;
                <span className="text-sm">Add Item</span>
              </Button>
            )}
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <div className="table-responsive">
              {menuData?.length > 0 && (
                <BasicTable
                  maxHeight={550}
                  tableData={menuData}
                  tableColumns={COLUMNS}
                  tableHooks={tableHooks}
                  headerColor="bg-gray-850"
                />
              )}
              {!menuDataExists() && <Alert message={"No Menu Added yet"} />}
            </div>
          </Grid>
        </Grid>
      </div>

      <div>
        {menuEditModal.displayModal && (
          <EditItemModal
            row={menuEditModal.row}
            handleClose={() =>
              setMenuEditModal({ ...menuEditModal, displayModal: false })
            }
            handleEdit={handleEdit}
            showModal={menuEditModal.displayModal}
            textFieldValuesMap={textFieldValuesMap}
            setTextFieldValuesMap={setTextFieldValuesMap}
          />
        )}

        {showModal && (
          <AddMenuItemModal
            showModal={showModal}
            handleClose={() => setShowModal(false)}
            addMenuItem={addMenuItemOnView}
            selectedCompany={selectedCompany}
            canEdit={canEdit}
          />
        )}
      </div>
    </>
  );
}

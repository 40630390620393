// import { DashboardTab } from "../../Common/Enums/dashboard";

const fetchRoute = (pathIndex: number) => {
  switch (pathIndex) {
    case 0:
      return "Company";
    case 1:
      return "Projects";
    case 2:
      return "Members";
    case 3:
      return "Time Logs";
    case 4:
      return "Attendance";
    case 5:
      return "Allocation";
    case 6:
      return "Lunch";
    case 7:
      return "Feedback";
    case 8:
      return "Salary";
    case 9:
      return "Video";
    case 10:
      return "Analytics";
    default:
      return null;
  }
};

export default fetchRoute;

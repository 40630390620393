import { doc } from "@firebase/firestore";
import {
  deleteUser,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  updateProfile,
} from "firebase/auth";
import { getDoc } from "firebase/firestore";
import { addNotificationToApp } from "../../Common/Common";
import { analytics, auth, db, logEvent } from "../../config/firebase.includes";

const googleProvider = new GoogleAuthProvider();

const login = async (email: string, pass: string): Promise<boolean> => {
  try {
    await signInWithEmailAndPassword(auth, email, pass);
    logEvent(analytics, "login", { method: "signInWithEmailAndPassword" });

    return true;
  } catch (e: any) {
    addNotificationToApp("Error", "Invalid Credentials", "danger");
    return false;
  }
};

const loginWithGoogle = async (): Promise<boolean> => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    let userExists = false;
    const docData = await getDoc(doc(db, "users", res.user.uid));
    userExists = docData.data() ? true : false;

    if (!userExists) {
      logOut();
      deleteUser(res.user);
      addNotificationToApp("Error", "Invalid Credentials", "danger");
    }
    return userExists;
  } catch (e: any) {
    return false;
  }
};

const logOut = async (): Promise<void> => {
  try {
    await auth.signOut();
  } catch (e: any) {
    console.log(e.message);
  }
};

const forgotPassword = async (emailAddress: string): Promise<void> => {
  try {
    await sendPasswordResetEmail(auth, emailAddress);
    addNotificationToApp("Success", "Email sent successfully!", "success");
  } catch (e: any) {
    addNotificationToApp("Error", "Email not found!", "danger");
  }
};

const getToken = async (): Promise<string | undefined> => {
  const user = auth.currentUser;
  const token = await user?.getIdToken(false);
  return token;
};

const updateDisplayName = async (name: string): Promise<void> => {
  const user = auth.currentUser;
  await updateProfile(user!, {
    displayName: name,
  });
};

export {
  login,
  logOut,
  loginWithGoogle,
  getToken,
  forgotPassword,
  updateDisplayName,
};

import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/esm/Modal";
import { useState } from "react";
import { ModalProps } from "../../../Common/Interfaces/dashboard";
// import TextField from "../../Common/TextField/TextField";
import Button from "../../Common/Button/Button";
import { forgotPassword } from "../../../apis/auth/auth-service";
import { z } from "zod";
import { ForgotPasswordSchema } from "../../../Common/ValidationSchema/ForgotPasswordSchema";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { spinner } from "../../../Common/ButtonSpiner/ButtonSpiner";
import { IForgotPassword } from "../../../Common/Interfaces/Auth/IForgotPassword";
import { InputLabel } from "@mui/material";

type ValidationSchema = z.infer<typeof ForgotPasswordSchema>;

export default function ForgotPasswordModal(props: ModalProps) {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ValidationSchema>({
    resolver: zodResolver(ForgotPasswordSchema),
    mode: "all",
  });
  const submit = async (data: IForgotPassword) => {
    const userEmail = data.email;
    setIsSubmitted(true);
    await forgotPassword(userEmail);
    setIsSubmitted(false);
    props.handleClose();
  };

  return (
    <>
      <Modal
        className="container-modal"
        size="lg"
        centered={true}
        show={props.showModal}
        onHide={() => props.handleClose()}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Forgot Password</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <form onSubmit={handleSubmit(submit)}>
              <Col>
                <InputLabel htmlFor="email" className="text-black m-2">
                  Email
                </InputLabel>
                <input
                  type="text"
                  className={`form-control rounded-pill ${
                    errors?.email && "is-invalid"
                  }`}
                  id="email"
                  placeholder="Enter email"
                  {...register("email")}
                />
                <p className="mt-1 ml-3 text-xs text-red-600">
                  {errors?.email?.message}
                </p>
              </Col>
              <Col>
                <Button
                  type="submit"
                  text="Submit"
                  icon={isValid && isSubmitted ? spinner() : null}
                  disabled={!isValid || isSubmitted}
                  additionalClasses={`h-10 mt-3 bg-blue-600 text-white w-full mb-2 ${
                    (!isValid || isSubmitted) && "opacity-25 cursor-not-allowed"
                  }`}
                />
                <Button
                  onClick={() => props.handleClose()}
                  text="Close"
                  additionalClasses={
                    "h-10 mt-3 bg-gray-600 text-white w-full mb-2"
                  }
                />
              </Col>
            </form>
          </Row>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}

import { CompanyLevelPermissions } from "./Enums/permissions";

// Permissions w.r.t roles
export const AllowedCompanyMemberPermissions = [
  CompanyLevelPermissions.CREATE_WORKLOG,
  CompanyLevelPermissions.UPDATE_USER,
  CompanyLevelPermissions.GET_USER,
  CompanyLevelPermissions.UPDATE_WORKLOG,
  CompanyLevelPermissions.GET_WORKLOG,
  CompanyLevelPermissions.DELETE_WORKLOG,
  CompanyLevelPermissions.GET_USER_PROJECT,
  CompanyLevelPermissions.GET_USER_ATTENDANCE,
  CompanyLevelPermissions.GET_USER_FOOD_ORDER,
  CompanyLevelPermissions.UPDATE_FOOD_ORDER,
  CompanyLevelPermissions.CREATE_FEEDBACK,
  CompanyLevelPermissions.GET_ALLOCATION,
  CompanyLevelPermissions.GET_COMPANY_USERS,
];

import { Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/esm/Modal";
import { EditUserModalProps } from "../../../../Common/Interfaces/dashboard";
import TextField from "../../../Common/TextField/TextField";
import { useState } from "react";
import "./EditUserModal.scss";
import Button from "../../../Common/Button/Button";
import { updateUserName } from "../../../../apis/services/users.service";
import { isSuccessCode } from "../../../../Common/Common";
import { updateGlobalUser } from "../../../../redux/actions/UserActions";
import { UpdatedGlobalUser } from "../../../../redux/model/UpdatedUser.d";
import { useDispatch } from "react-redux";

export default function EditMemberModal(props: EditUserModalProps) {
  const [userId] = useState(props.editUser?.id || "");
  const [userName, setUserName] = useState(props.editUser?.userName || "");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const dispatch = useDispatch();

  const isFormValid = () => {
    if (userName && userName.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const updateUserNameOnly = async () => {
    const res = await updateUserName(userId, userName);
    if (res && isSuccessCode(res?.status)) {
      props.handleClose();
    }
  };

  const handleSave = () => {
    setIsSubmitted(true);
    if (isFormValid()) {
      updateUserNameOnly();
      dispatch(
        updateGlobalUser({
          userName: userName,
          userId: userId,
        } as UpdatedGlobalUser)
      );
    }
  };

  return (
    <>
      <Modal
        className="add-edit-user-modal-container "
        show={props.showModal}
        onHide={() => props.handleClose()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Profile Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <label className="fw-bold">User Name</label>
                <TextField
                  id={"userNameField"}
                  type={"text"}
                  placeholder={""}
                  width={""}
                  setValue={setUserName}
                  defaultValue={userName}
                  additionalClasses={
                    !userName && userName.length === 0 && isSubmitted
                      ? "is-invalid"
                      : ""
                  }
                  fieldName={"User Name"}
                ></TextField>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => props.handleClose()}
            text="Close"
            additionalClasses={"gray-button"}
          />
          <Button
            onClick={() => handleSave()}
            text="Save Changes"
            additionalClasses={"purple-button"}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}

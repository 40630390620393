import { Card, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import Modal from "react-bootstrap/esm/Modal";
import {
  AddEditCompanyModalProps,
  CompanyUserRole,
} from "../../../../Common/Interfaces/dashboard";
import { useEffect, useMemo, useState } from "react";
import { storage } from "../../../../config/firebase.includes";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import "./AddEditCompanyModal.scss";
import Button from "../../../Common/Button/Button";
import FilePicker from "../../../Common/FilePicker/FilePicker";
import {
  enumStringToText,
  getRoleFromCompanyPermissions,
  hasPermission,
  isAdmin,
  isSuccessCode,
} from "../../../../Common/Common";
import { UserRole } from "../../../../Common/Enums/dashboard";
import Table from "../../../Common/Table/Table";
import Alert from "../../../Common/Alert/Alert";
import AppDropdown from "../../../Common/Dropdown/Dropdown";
import {
  addUserInCompany,
  deleteUserFromCompany,
  getCompanyUsersWithRoles,
  postCompany,
  updateCompany,
  updateUserRoleInCompany,
} from "../../../../apis/services/company.service";
import { CompanyLevelPermissions } from "../../../../Common/Enums/permissions";
import { useSelector } from "react-redux";
import { AllowedCompanyMemberPermissions } from "../../../../Common/AllowedPermissions";
import Skeleton from "react-loading-skeleton";
import ReactTooltip from "react-tooltip";
import { ReduxState } from "../../../../redux/model/ReduxState.d";
import { UserCustomClaim } from "../../../../redux/model/UserState.d";
import DeleteModal from "../../../Common/Popup/DeleteModal";
import { forgotPassword } from "../../../../apis/auth/auth-service";
import { z } from "zod";
import {
  AddEditCompanyMemberSchema,
  AddEditCompanySchema,
} from "../../../../Common/ValidationSchema/AddEditCompanySchema";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  IAddEditCompany,
  ICompanyMember,
} from "../../../../Common/Interfaces/Company/IAddEditCompany";

type ValidationSchema = z.infer<typeof AddEditCompanySchema>;
type ValidationSchemaMembers = z.infer<typeof AddEditCompanyMemberSchema>;

export default function AddEditCompanyModal(props: AddEditCompanyModalProps) {
  const [companyId] = useState(props.editCompany?.id || "");
  const [companyPictureUrl, setCompanyPictureUrl] = useState(
    props.editCompany?.profilePicture || ""
  );
  const [fileSelected, setFileSelected] = useState({} as File);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletecompanyUser, setDeleteCompanyUser] = useState(
    {} as CompanyUserRole
  );
  const [showSaveButton, setShowSaveButton] = useState(true);
  const [isEdit] = useState(
    props.editCompany &&
      props.editCompany?.id &&
      props.editCompany?.id.length > 0
      ? true
      : false
  );
  const [showAddMemberFields, setShowAddMemberFields] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPermissions, setselectedPermissions] = useState([] as any[]);
  const [companyUsers, setCompanyUsers] = useState([] as CompanyUserRole[]);
  const permission_options = Object.keys(CompanyLevelPermissions).map(
    (permission) => new Option(enumStringToText(permission), permission)
  );
  const userCustomClaim = useSelector<ReduxState, UserCustomClaim>(
    (state: ReduxState) => state.UserReducer?.userPermission?.userCustomClaim
  );
  const [updateUser, setUpdateUser] = useState<CompanyUserRole>(
    {} as CompanyUserRole
  );
  const [selectedUpdatePermissions, setSelectedUpdatePermissions] = useState(
    [] as any[]
  );
  const [updateRole, setUpdateRole] = useState<string>(UserRole.TEAM_MEMBER);

  const [role, setRole] = useState<string>(UserRole.TEAM_MEMBER);
  const roles = [
    {
      id: UserRole.COMPANY_ADMIN,
      name: enumStringToText(UserRole.COMPANY_ADMIN),
    },
    { id: UserRole.TEAM_MEMBER, name: enumStringToText(UserRole.TEAM_MEMBER) },
    { id: UserRole.CUSTOM, name: enumStringToText(UserRole.CUSTOM) },
  ];
  const companyAdminRole = [
    {
      id: UserRole.COMPANY_ADMIN,
      name: enumStringToText(UserRole.COMPANY_ADMIN),
    },
  ];
  //by default, file validity is true, when invalid file is picked , then set validity to false
  const [isValidFile, setIsValidFile] = useState<boolean>(true);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ValidationSchema>({
    defaultValues: {
      companyName: props.editCompany?.name || "",
      companyEmail: props.editCompany?.email || "",
    },
    resolver: zodResolver(AddEditCompanySchema),
    mode: "all",
  });
  const {
    register: membersRegister,
    handleSubmit: handleMembersSubmit,
    reset,
    formState: { errors: errorsMembers, isValid: membersIsValid },
  } = useForm<ValidationSchemaMembers>({
    resolver: zodResolver(AddEditCompanyMemberSchema),
    mode: "all",
  });
  useEffect(() => {
    getCompanyUsers();
  }, [companyId]);

  useEffect(() => {
    if (role === UserRole.COMPANY_ADMIN) {
      setselectedPermissions(
        Object.keys(CompanyLevelPermissions).map(
          (permission) => new Option(enumStringToText(permission), permission)
        )
      );
    } else if (role === UserRole.TEAM_MEMBER) {
      setselectedPermissions(
        AllowedCompanyMemberPermissions.map(
          (permission) => new Option(enumStringToText(permission), permission)
        )
      );
    } else if (role === UserRole.CUSTOM) {
      setselectedPermissions([]);
    }
  }, [role]);

  useEffect(() => {
    if (updateRole === UserRole.COMPANY_ADMIN) {
      setSelectedUpdatePermissions(
        Object.keys(CompanyLevelPermissions).map(
          (permission) => new Option(enumStringToText(permission), permission)
        )
      );
    } else if (updateRole === UserRole.TEAM_MEMBER) {
      setSelectedUpdatePermissions(
        AllowedCompanyMemberPermissions.map(
          (permission) => new Option(enumStringToText(permission), permission)
        )
      );
    } else if (updateRole === UserRole.CUSTOM) {
      setSelectedUpdatePermissions([]);
    }
  }, [updateRole]);

  const saveCompany = async (url: string, companyData: IAddEditCompany) => {
    const company: any = {
      name: companyData.companyName,
      email: companyData.companyEmail,
      profilePicture: url,
    };
    if (!isEdit) {
      const res = await postCompany(company);
      if (res && isSuccessCode(res?.status)) {
        company.id = res.data.id;
        props.addCompany(company);
      }
    } else {
      const res = await updateCompany(companyId, company);
      if (res && isSuccessCode(res?.status)) {
        props.addCompany(company, true);
      }
    }
  };

  const getCompanyUsers = async () => {
    if (companyId) {
      setIsLoading(true);
      const fetchedCompanyUsers = await getCompanyUsersWithRoles(companyId);
      setCompanyUsers(fetchedCompanyUsers);
      setIsLoading(false);
    }
  };

  const resetNewMember = () => {
    reset();
    setShowAddMemberFields(false);
    setIsAdded(false);
  };

  const handleAddNewMember = async (data: ICompanyMember) => {
    setIsAdded(true);
    const name = data.memberName;
    const email = data.memberEmail;
    const password = data.memberPassword;
    const memberBody = {
      companyId: companyId,
      name,
      email,
      password,
      userCompanyPermissions: selectedPermissions.map(
        (permission) => permission.value
      ),
      profilePicture: "https://www.defaultPic.com/",
    };
    setIsLoading(true);
    const res = await addUserInCompany(memberBody);
    setIsLoading(false);
    if (res && isSuccessCode(res?.status)) {
      await forgotPassword(email);
      setCompanyUsers([
        ...companyUsers,
        {
          name,
          userCompanyPermissions: selectedPermissions.map(
            (permission) => permission.value
          ),
        } as CompanyUserRole,
      ]);
      resetNewMember();
    }
  };

  const handleEditMember = async () => {
    const memberBody = {
      companyId: companyId,
      userCompanyPermissions: selectedUpdatePermissions.map(
        (permission) => permission.value
      ),
    };
    setIsLoading(true);
    const res = await updateUserRoleInCompany(updateUser.id, memberBody);
    setIsLoading(false);
    if (res && isSuccessCode(res?.status)) {
      const updatedMembers = companyUsers.map(
        (companyUser: CompanyUserRole) => {
          if (companyUser.id === updateUser.id) {
            return {
              id: updateUser.id,
              name: updateUser.name,
              userCompanyPermissions: selectedUpdatePermissions.map(
                (permission) => permission.value
              ),
            } as CompanyUserRole;
          } else {
            return companyUser;
          }
        }
      );
      setCompanyUsers(updatedMembers);
      setUpdateUser({} as CompanyUserRole);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Role",
        accessor: "userCompanyPermissions",
        Cell: (propes: any) => {
          if (propes && propes.value) {
            return (
              <>
                <a data-tip data-for={`permissions${propes.value.length}`}>
                  {enumStringToText(
                    getRoleFromCompanyPermissions(propes.value)
                  )}
                </a>
                <ReactTooltip
                  id={`permissions${propes.value.length}`}
                  backgroundColor="#7A6AF6"
                >
                  <span>{enumStringToText(propes.value.join(", "))}</span>
                </ReactTooltip>
              </>
            );
          } else {
            return <> </>;
          }
        },
      },
    ],
    []
  );

  const isFileSelected = (): boolean => {
    if (fileSelected && fileSelected.name) {
      return true;
    } else if (companyPictureUrl && companyPictureUrl.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleSave = (companyData: IAddEditCompany) => {
    setIsSubmitted(true);
    if (isFileSelected() && fileSelected?.name) {
      handleUpload(companyData);
    } else {
      saveCompany(companyPictureUrl, companyData);
      props.handleClose();
    }
  };

  const handleUpload = (companyData: IAddEditCompany) => {
    try {
      setIsUploading(true);
      const storageRef = ref(storage, "company/" + fileSelected.name);
      const uploadTask = uploadBytesResumable(storageRef, fileSelected);

      uploadTask.on(
        "state_changed",
        (snapshot: { bytesTransferred: number; totalBytes: number }) => {
          const progress =
            Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 1000
            ) / 10;
          setUploadProgress(progress);
        },
        (error: any) => {
          console.log(error);
        },
        async () => {
          try {
            const url = await getDownloadURL(storageRef);
            setCompanyPictureUrl(url);
            saveCompany(url, companyData);
            props.handleClose();
          } catch (error: any) {
            console.log(error);
          }
        }
      );
    } catch (ex) {
      console.log("Error while uploading file :: ", ex);
    }
  };
  const deleteCompanyMemberData = (id: string) => {
    const companyUsersCopy = [...companyUsers];
    for (let i = 0; i < companyUsersCopy.length; i++) {
      if (companyUsersCopy[i].id === id) companyUsersCopy.splice(i, 1);
    }
    setCompanyUsers(companyUsersCopy);
  };
  const handleDeleteRole = async (user: CompanyUserRole) => {
    setDeleteCompanyUser(user);
    setShowDeleteModal(true);
  };
  const handleDelete = async () => {
    setShowDeleteModal(false);

    const res = await deleteUserFromCompany(companyId, deletecompanyUser.id);
    if (res && isSuccessCode(res?.status)) {
      deleteCompanyMemberData(deletecompanyUser.id);
    }
  };

  const handleEditRole = (user: CompanyUserRole) => {
    setUpdateUser(user);
    setUpdateRole(getRoleFromCompanyPermissions(user.userCompanyPermissions));
  };

  const handleSelect = (eventKey: any) => {
    if (eventKey === "details") setShowSaveButton(true);
    else setShowSaveButton(false);
  };

  const disableFields = () => {
    if (
      !isValid ||
      isSubmitted ||
      !isFileSelected() ||
      !isValidFile ||
      !isAdmin(userCustomClaim)
    )
      return true;
  };
  return (
    <>
      <Modal
        className="add-edit-company-modal-container"
        show={props.showModal}
        onHide={() => props.handleClose()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? "Edit Company" : "Add Company"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            defaultActiveKey="details"
            onSelect={handleSelect}
            id="editModelTab"
            className="mb-3"
          >
            <Tab eventKey="details" title="Details">
              <Form onSubmit={handleSubmit(handleSave)}>
                <Row>
                  <Col>
                    <label className="fw-bold">Company Name</label>
                    <input
                      type="text"
                      id={"companyName"}
                      className={`form-control rounded-pill ${
                        errors?.companyName && "is-invalid"
                      }`}
                      {...register("companyName")}
                      disabled={!isAdmin(userCustomClaim) && true}
                    />
                    <p className="mt-1 ml-3 text-xs text-red-600">
                      {errors?.companyName?.message}
                    </p>
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col>
                    <label className="fw-bold">Company Email</label>
                    <input
                      type="text"
                      id={"companyEmail"}
                      disabled={!isAdmin(userCustomClaim) && true}
                      className={`form-control rounded-pill ${
                        errors?.companyName && "is-invalid"
                      }`}
                      {...register("companyEmail")}
                    />
                    <p className="mt-1 ml-3 text-xs text-red-600">
                      {errors?.companyEmail?.message}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <label className="fw-bold">Company Logo</label>

                  <FilePicker
                    fileSelected={fileSelected}
                    setFileSelected={setFileSelected}
                    isUploading={isUploading}
                    uploadProgress={uploadProgress}
                    pictureUrl={companyPictureUrl}
                    name={"companyLogo"}
                    setIsValidFile={setIsValidFile}
                    userCustomClaim={userCustomClaim}
                  />

                  <div
                    className={`text-danger ${
                      !companyPictureUrl && !fileSelected.name && isSubmitted
                        ? ""
                        : "d-none"
                    }`}
                  >
                    <small>Company Picture is required</small>
                  </div>
                  {!isValidFile && (
                    <small className="text-danger">
                      Only .jpg, .jpeg, .png files are accepted.
                    </small>
                  )}
                </Row>
                {showSaveButton && (
                  <Button
                    type="submit"
                    text={isEdit ? "Update Company" : "Save Company"}
                    disabled={disableFields()}
                    additionalClasses={`h-10 mt-3 bg-blue-600 text-white w-full mb-2 ${
                      disableFields() && "opacity-25 cursor-not-allowed"
                    }`}
                  />
                )}
                <Button
                  onClick={() => props.handleClose()}
                  text="Close"
                  additionalClasses={
                    "h-10 mt-3 bg-gray-600 text-white w-full mb-2"
                  }
                />
              </Form>
            </Tab>

            {hasPermission(
              userCustomClaim,
              CompanyLevelPermissions.CREATE_COMPANY_USERS
            ) && (
              <Tab eventKey="members" title="Members">
                <Row>
                  <Col sm={5}>
                    <Button
                      onClick={() => setShowAddMemberFields(true)}
                      text={"Add Member"}
                      iconPath={"fab fa fa-plus"}
                      additionalClasses={"gray-button"}
                    />
                  </Col>
                </Row>

                {showAddMemberFields && (
                  <>
                    <Form onSubmit={handleMembersSubmit(handleAddNewMember)}>
                      <Row className="my-3">
                        <Col>
                          <label className="fw-bold">Name</label>
                          <input
                            type="text"
                            id={"memberName"}
                            className={`form-control rounded-pill ${
                              errorsMembers?.memberName && "is-invalid"
                            }`}
                            {...membersRegister("memberName")}
                          />
                          <p className="mt-1 ml-3 text-xs text-red-600">
                            {errorsMembers?.memberName?.message}
                          </p>
                        </Col>
                        <Col>
                          <label className="fw-bold">Role</label>

                          <AppDropdown
                            title={
                              userCustomClaim.isAdmin
                                ? enumStringToText(UserRole.COMPANY_ADMIN)
                                : enumStringToText(role)
                            }
                            setValue={setRole}
                            list={
                              userCustomClaim.isAdmin ? companyAdminRole : roles
                            }
                            saveObject={false}
                          />
                        </Col>

                        {role === UserRole.CUSTOM && (
                          <Row className="my-3">
                            <Col>
                              <label className="fw-bold">Permissions</label>

                              <MultiSelect
                                options={permission_options}
                                value={selectedPermissions}
                                onChange={setselectedPermissions}
                                labelledBy="Select"
                              />
                            </Col>
                          </Row>
                        )}
                      </Row>
                      <Row className="my-3">
                        <Col>
                          <label className="fw-bold">Email</label>
                          <input
                            type="text"
                            id={"memberEmail"}
                            className={`form-control rounded-pill ${
                              errorsMembers?.memberEmail && "is-invalid"
                            }`}
                            {...membersRegister("memberEmail")}
                          />
                          <p className="mt-1 ml-3 text-xs text-red-600">
                            {errorsMembers?.memberEmail?.message}
                          </p>
                        </Col>
                        <Col>
                          <label className="fw-bold">Password</label>
                          <input
                            type="password"
                            id={"memberPassword"}
                            className={`form-control rounded-pill ${
                              errorsMembers?.memberPassword && "is-invalid"
                            }`}
                            {...membersRegister("memberPassword")}
                          />
                          <p className="mt-1 ml-3 text-xs text-red-600">
                            {errorsMembers?.memberPassword?.message}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Button
                          type="submit"
                          text="Save Member"
                          disabled={isAdded || !membersIsValid}
                          additionalClasses={`h-10 mt-3 bg-blue-600 text-white w-full mb-2 ${
                            (isAdded || !membersIsValid) &&
                            "opacity-25 cursor-not-allowed"
                          }`}
                        />
                      </Row>
                    </Form>
                  </>
                )}

                {companyUsers && companyUsers.length > 0 && (
                  <Row className="mt-4">
                    {!isLoading && (
                      <Table
                        data={companyUsers}
                        columns={columns}
                        handleEditRole={handleEditRole}
                        handleDelete={handleDeleteRole}
                      />
                    )}
                  </Row>
                )}

                {isLoading && (
                  <Row className="mt-4">
                    <Skeleton height={100} />
                  </Row>
                )}

                {!isLoading && companyUsers && companyUsers.length === 0 && (
                  <Alert message={"No user added yet"} />
                )}

                {/* ************************************************ UPDATE USER ROLE ************************************************************** */}
                {updateUser.id && updateUser.id.length > 0 && (
                  <Card className="my-3">
                    <Card.Header>
                      <h5>
                        Update{" "}
                        <span className="purple-color">{updateUser.name}</span>
                        's role:
                      </h5>
                    </Card.Header>
                    <Row className="my-3 mx-2">
                      <Col>
                        <label className="fw-bold">Role</label>
                        <br />
                        <AppDropdown
                          title={enumStringToText(updateRole)}
                          setValue={setUpdateRole}
                          list={
                            userCustomClaim.isAdmin ? companyAdminRole : roles
                          }
                          saveObject={false}
                        />
                      </Col>

                      {updateRole === UserRole.CUSTOM && (
                        <Col>
                          <label className="fw-bold">Permissions</label>

                          <MultiSelect
                            options={permission_options}
                            value={selectedUpdatePermissions}
                            onChange={setSelectedUpdatePermissions}
                            labelledBy="Select"
                          />
                        </Col>
                      )}

                      <Col>
                        <Button
                          onClick={() => handleEditMember()}
                          text="Update Member"
                          additionalClasses={
                            "h-10 mt-3 bg-blue-600 text-white w-full mb-2"
                          }
                        />
                      </Col>
                    </Row>
                  </Card>
                )}
                {/* ****************************************************DELETE USER **************************************************************************** */}
                {showDeleteModal && (
                  <DeleteModal
                    showModal={showDeleteModal}
                    handleClose={() => setShowDeleteModal(false)}
                    handleDelete={handleDelete}
                    message="Are you sure you want to delete this User from Company?"
                  />
                )}
              </Tab>
            )}
          </Tabs>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}

import CubeLoader from "./CubeLoader";
import "./loader.scss";
export default function Loader(props: any) {
  return (
    <>
      {props.children}
      {props.active && <CubeLoader />}
    </>
  );
}

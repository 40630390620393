import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { Bar, Line, Pie } from "react-chartjs-2";

import moment from "moment";

import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars/src";

import { Card, CardContent, Grid, Typography } from "@mui/material";

import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
  LineElement,
  PointElement,
} from "chart.js";

import {
  getCompanyId,
  lineGraph,
  pieGraphStyleOptions,
  userEngagementGraphStyleOptions,
  userLoginGraphStyleOptions,
  userRetentionGraphStyleOptions,
} from "../../../Common/Common";

import {
  getEventReports,
  getLoginUserReports,
  getScreeViewRecords,
  getUserEngagements,
} from "../../../apis/services/analytics.service";

import { ReduxState } from "../../../redux/model/ReduxState.d";
import { UserCustomClaim } from "../../../redux/model/UserState.d";

import BasicTable from "../../Common/Table/BasicTable";

import { COLUMNS as eventsDataColumns } from "./TableColumns/eventsDataColumns";
import { COLUMNS as viewScreenDataColumns } from "./TableColumns/viewScreenDataColumns";

import "../Members/MembersTab.scss";

ChartJS.register(
  Title,
  Legend,
  Tooltip,
  BarElement,
  ArcElement,
  LinearScale,
  LineElement,
  PointElement,
  CategoryScale
);

export default function AnalyticsTab() {
  const [userRetentionRecords, setUserRetentionRecords] = useState([]);
  const [screenViewRecords, setScreenViewRecords] = useState<any>([]);
  const [screenViewTableRecords, setScreenViewTableRecords] = useState<any>([]);
  const [userLoginRecords, setUserLoginRecords] = useState<any>([]);
  const [loading, setLoading] = useState<any>(true);
  const [userEngagementRecords, setUserEngagementRecords] = useState(lineGraph);
  const [eventsRecord, setEventsRecord] = useState([]);
  const userCustomClaim = useSelector<ReduxState, UserCustomClaim>(
    (state: ReduxState) => state.UserReducer?.userPermission?.userCustomClaim
  );
  const initialStartDate = new Date("2023-09-01T00:00:00.000Z");
  const [rangeDate, setRangeDate] = useState({
    startDate: new Date(initialStartDate.toISOString()),
    endDate: new Date(),
  });

  useEffect(() => {
    fetchrecords();
  }, [userCustomClaim, rangeDate]);

  const fetchrecords = async () => {
    const companyId = getCompanyId(userCustomClaim);
    setLoading(true);
    const startDateString = rangeDate.startDate.toISOString().slice(0, 10);
    const endDateString = rangeDate.endDate.toISOString().slice(0, 10);

    const userEngagementData = await getUserEngagements(
      startDateString,
      endDateString,
      companyId
    );
    if (userEngagementData?.length > 0)
      userEngagementData.sort((a: any, b: any) => a.date - b.date);
    setUserEngagementRecords({
      labels: userEngagementData?.map((d: any) =>
        moment(d.date).format("YYYY-MM-DD")
      ),
      datasets: [
        {
          label: "Engagement Percentage",
          data: userEngagementData?.map((d: any) => d.percentage),
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    });
    const events = await getEventReports(
      startDateString,
      endDateString,
      companyId
    );
    setEventsRecord(events);
    const screenViewData = await getScreeViewRecords(
      startDateString,
      endDateString,
      companyId
    );
    setScreenViewTableRecords(screenViewData);
    setScreenViewRecords({
      labels: screenViewData?.map((d: any) => d.pageTitle),
      datasets: [
        {
          label: "counts",
          data: screenViewData?.map((d: any) => d.eventCount),
          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 206, 86, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(153, 102, 255, 0.2)",
            "rgba(255, 159, 64, 0.2)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
          ],
          borderWidth: 1,
        },
      ],
    });

    const loginUserData = await getLoginUserReports(
      startDateString,
      endDateString,
      companyId
    );
    if (userLoginRecords?.length > 0)
      loginUserData.sort((a: any, b: any) => a.date - b.date);

    setUserLoginRecords({
      labels: loginUserData?.map((d: any) =>
        moment(d.date).format("YYYY-MM-DD")
      ),
      datasets: [
        {
          label: "login Counts",
          data: loginUserData?.map((d: any) => d.eventCount),
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    });
    setUserRetentionRecords([]);

    setLoading(false);
  };

  const viewScreenTableHooks = (hooks: any) => {
    hooks.visibleColumns.push((columns: any) => [...columns]);
  };

  const eventsTableHooks = (hooks: any) => {
    hooks.visibleColumns.push((columns: any) => [...columns]);
  };

  return (
    <>
      <div className="reports-tab">
        <Grid container>
          <Grid item xs={12} className="flex" justifyContent={"end"}>
            <DateRangePickerComponent
              format="dd-MMM-yy"
              onChange={(range: any) => {
                setRangeDate({
                  startDate: range.value[0],
                  endDate: range.value[1],
                });
              }}
              showClearButton={false}
              startDate={rangeDate.startDate}
              endDate={rangeDate.endDate}
              delayUpdate={true}
              width={250}
            />
          </Grid>
        </Grid>
        {loading ? (
          <>
            <div>
              <div className="my-4">
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    className="flex my-2"
                    justifyContent={"center"}
                  >
                    <Typography variant="h6" gutterBottom>
                      Fetching ...
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </div>
          </>
        ) : screenViewTableRecords?.length === 0 &&
          userEngagementRecords?.labels?.length === 0 &&
          eventsRecord?.length === 0 &&
          userLoginRecords?.labels?.length === 0 &&
          userRetentionRecords?.length === 0 ? (
          <>
            <div>
              <div className="my-4">
                <Grid container>
                  <Grid item xs={12} className="flex" justifyContent={"start"}>
                    <Typography variant="caption" gutterBottom>
                      <b>Note:</b> Apply filters to unlock valuable insights and
                      statistics about the application and user interactivity
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className="flex my-2"
                    justifyContent={"center"}
                  >
                    <Typography variant="h6" gutterBottom>
                      No Data Found
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="h-[750px] overflow-auto scrollbar-hide">
              <div className="my-2 ">
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      Tabs Visited
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    {screenViewTableRecords?.length > 0 ? (
                      <BasicTable
                        maxHeight={380}
                        headerColor="bg-gray-850"
                        tableHooks={viewScreenTableHooks}
                        tableData={screenViewTableRecords}
                        tableColumns={viewScreenDataColumns}
                      />
                    ) : (
                      "No Table Data"
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <div className="ml-4">
                      <Card className="max-w-[400px]">
                        <CardContent>
                          {screenViewTableRecords?.length === 0 ? (
                            "No Data Found"
                          ) : (
                            <Pie
                              data={screenViewRecords || []}
                              options={pieGraphStyleOptions}
                            />
                          )}
                        </CardContent>
                      </Card>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className="my-2">
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      Users Activity
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {userEngagementRecords?.labels?.length === 0 ? (
                      "No Data Found"
                    ) : (
                      <Card>
                        <CardContent>
                          <Line
                            height={"50%"}
                            data={userEngagementRecords || []}
                            options={userEngagementGraphStyleOptions}
                          />
                        </CardContent>
                      </Card>
                    )}
                  </Grid>
                </Grid>
              </div>
              <div className="my-2">
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      Events Recorded
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    {eventsRecord?.length > 0 ? (
                      <BasicTable
                        maxHeight={380}
                        tableData={eventsRecord}
                        headerColor="bg-gray-850"
                        tableHooks={eventsTableHooks}
                        tableColumns={eventsDataColumns}
                      />
                    ) : (
                      "No Table Data"
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <div className="ml-4">
                      <Card>
                        <CardContent>
                          {userLoginRecords?.labels?.length === 0 ? (
                            "No Data Found"
                          ) : (
                            <Bar
                              height={300}
                              data={userLoginRecords || []}
                              options={userLoginGraphStyleOptions}
                            />
                          )}
                        </CardContent>
                      </Card>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className="my-2">
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      User Retentions
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {userRetentionRecords?.length === 0 ? (
                      "No Data Found"
                    ) : (
                      <Card>
                        <CardContent>
                          <Line
                            data={lineGraph || []}
                            options={userRetentionGraphStyleOptions}
                          />
                        </CardContent>
                      </Card>
                    )}
                  </Grid>
                </Grid>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

import Moment from "moment";

import {
  Card,
  CardHeader,
  Typography,
  CardContent,
  CardActions,
  IconButton,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import LaunchIcon from "@mui/icons-material/Launch";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { convertTime } from "../../../../Common/Common";

const WorklogCardNew = ({
  key,
  worklog,
  handleShow,
  toggleDescriptionModalHandler,
}: any) => {
  return (
    <div className="w-webkitfill h-full" key={key}>
      <Card className="h-full">
        <CardHeader
          title={
            <>
              <Typography variant="subtitle2" gutterBottom>
                {worklog.projectName}
              </Typography>
            </>
          }
          subheader={
            <>
              <span className="grid">
                <Typography variant="caption" gutterBottom>
                  Starting date: {Moment(worklog.date).format("DD MMMM, yyyy")}
                </Typography>
                <span className="flex justify-between">
                  <span>
                    {worklog?.billable && (
                      <Tooltip title="Billable">
                        <CreditCardOutlinedIcon
                          className="text-green-700"
                          fontSize="small"
                        />
                      </Tooltip>
                    )}
                  </span>
                  <span>
                    <>
                      <Tooltip title="Time">
                        <AccessTimeOutlinedIcon
                          className="text-blue-550"
                          fontSize="small"
                        />
                      </Tooltip>{" "}
                      <span className="text-xs">
                        {convertTime(worklog?.worklogMinutes ?? 0)}
                      </span>
                    </>
                  </span>
                </span>
              </span>
            </>
          }
        />
        <CardContent>
          <Typography variant="subtitle2" color="text.secondary">
            <b>Role: </b> {worklog?.userName}
          </Typography>
          <br />
          <Typography variant="subtitle2" color="text.secondary">
            <b>Ticket: </b>
            <span className="text-blue-550">{worklog?.ticketName}</span>{" "}
            <a target="_blank" href={worklog.ticketUrl} className="text-muted">
              <span className="text-blue-550">
                <LaunchIcon fontSize="small" />
              </span>
            </a>
          </Typography>
          <Typography variant="subtitle2" color="text.secondary">
            <b>Ticket Type: </b> {worklog?.ticketType}
          </Typography>
          <Typography variant="subtitle2" color="text.secondary">
            <b>Description: </b>{" "}
            {worklog.ticketDescription.length > 20
              ? `${worklog.ticketDescription.slice(0, 20)}`
              : worklog.ticketDescription}
            {worklog.ticketDescription.length > 20 ? (
              <IconButton
                disableRipple
                aria-label="delete"
                size="small"
                onClick={() =>
                  toggleDescriptionModalHandler(true, worklog.ticketDescription)
                }
                className="text-blue-550"
              >
                <MoreHorizIcon />
              </IconButton>
            ) : (
              <></>
            )}
          </Typography>
        </CardContent>
        <CardActions disableSpacing className="flex justify-end">
          <span>
            <IconButton
              aria-label="Edit"
              color="primary"
              onClick={() => handleShow(true, worklog)}
            >
              <EditIcon />
            </IconButton>
          </span>
        </CardActions>
      </Card>
    </div>
  );
};

export default WorklogCardNew;

import axios, { AxiosResponse } from "axios";
import {
  deleteData,
  getData,
  postData,
  updateData,
} from "../axios-calls/common-axios-service";
import { VideoInfoAxiosRequest } from "../../Common/Interfaces/Videos/record-video";
import { Video } from "../../Common/Interfaces/Videos/my-videos";
import { VideoEditAxiosRequest } from "../../Common/Interfaces/Videos/edit-video-modal-props";
import {
  CloudinaryConfigVariables,
  fetchUploadURL,
} from "../../Common/cloudinary";
import { addNotificationToApp } from "../../Common/Common";

export const postVideo = async (
  videoInfo: VideoInfoAxiosRequest
): Promise<AxiosResponse> => {
  const res = await postData(`video`, videoInfo);
  return res as AxiosResponse;
};

export const fetchCloudinarySignature = async (
  userId: string
): Promise<CloudinaryConfigVariables> =>
  await getData(`video/signature/${userId}`);

export async function uploadVideoToCloudinary(
  signature: string,
  timestamp: string,
  formData: FormData
) {
  const upload_url = fetchUploadURL({
    apiKey: process.env.REACT_APP_CLOUDINARY_API_KEY,
    signature: signature,
    timestamp: timestamp,
  });
  const response = await axios.post(upload_url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  if (response.status !== 200) {
    addNotificationToApp("Error", "Upload Failed", "danger");
  }
  return response.data;
}

export const getVideosByUserId = async (
  startAfter: string,
  endBefore: string
): Promise<Video[]> => {
  const res = await getData(
    `video/user?startAfter=${startAfter}&endBefore=${endBefore}`
  );
  return res;
};

export const getSharedVideosByUserId = async (
  startAfter: string,
  endBefore: string
): Promise<Video[]> => {
  const res = await getData(
    `video/shared/user?startAfter=${startAfter}&endBefore=${endBefore}`
  );
  return res;
};

export const getSharedVideosByCompanyId = async (
  comapnyId: string,
  startAfter: string,
  endBefore: string
): Promise<Video[]> => {
  const res = await getData(
    `video/shared/company/${comapnyId}?startAfter=${startAfter}&endBefore=${endBefore}`
  );
  return res;
};

export const getSharedVideosByProjectId = async (
  projectId: string,
  startAfter: string,
  endBefore: string
): Promise<Video[]> => {
  const res = await getData(
    `video/shared/project/${projectId}startAfter=${startAfter}&endBefore=${endBefore}`
  );
  return res;
};

export const updateVideo = async (
  videoId: string,
  videoInfo: VideoEditAxiosRequest
): Promise<AxiosResponse> => {
  const res = await updateData(`video/${videoId}`, videoInfo);
  return res as AxiosResponse;
};

export const deleteVideo = async (videoId: string): Promise<AxiosResponse> => {
  const res = await deleteData(`video/${videoId}`);
  return res;
};

import { useState, ChangeEvent, FormEvent, useRef } from "react";

import { Button, Grid, Card, CardHeader } from "@mui/material";

import { addNotificationToApp } from "../../../Common/Common";
import { ISalaryBody } from "../../../Common/Interfaces/Salary/ISalaryItems";

import { postSalaryData } from "../../../apis/services/salary.service";

import generateZipFile from "../../../utils/helpers/generateZipFile";
import getBase64FromFile from "../../../utils/helpers/getBase64FromFile";

const Salary = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [buttonName, setButtonName] = useState("Download");
  const [uploadToDrive, setUploadToDrive] = useState(false);
  const [salaryFile, setSalaryFile] = useState<File | null>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setSalaryFile(file || null);
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    if (isChecked) setButtonName("Upload");
    else setButtonName("Download");

    setUploadToDrive(event.target.checked);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (salaryFile) {
      const fileExt = salaryFile?.name?.split(".")[1];
      if (fileExt !== "xlsx") {
        addNotificationToApp(
          "Error",
          "Please upload valid file with xlsx extention",
          "danger"
        );
        return;
      }
      let salarySheetXls = "";
      // setIsLoading(true);
      getBase64FromFile(salaryFile, async (result: string) => {
        salarySheetXls = result;
        const salaryBody = {
          data: salarySheetXls?.replace(
            "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
            ""
          ),
          fileName: salaryFile?.name,
          bUploadToDrive: uploadToDrive,
          mimetype: salaryFile?.type,
        };

        await saveSalaryData(salaryBody);
        resetForm();
      });
    } else {
      addNotificationToApp("Error", "Please select a file to upload", "danger");
    }
  };

  const saveSalaryData = async (data: ISalaryBody) => {
    try {
      const response = await postSalaryData(data);
      if (response?.data && !uploadToDrive) {
        generateZipFile(response?.data);
      }
    } catch (error) {
      addNotificationToApp("Error", "Failed to add data", "danger");
    }
  };
  const resetForm = () => {
    setSalaryFile(null);
    setUploadToDrive(false);
    // setIsLoading(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setButtonName("Download");
  };

  return (
    <div className="salary-module">
      <Card className="my-3 salary-card">
        <CardHeader
          title={
            <div className="my-2">
              <h5 className="text-white">Upload/Download Salary File(s)</h5>
            </div>
          }
          className="bg-gray-850 h-[50px] text-white"
        />
        <Grid container justifyContent="center" className="my-3 mx-2">
          <Grid item xs={12}>
            <div className="m-4">
              <form onSubmit={handleSubmit} className="px-4 mt-4">
                <div className="mb-3 d-flex justify-content-center align-items-end">
                  <label
                    htmlFor="fileInput"
                    className="form-label upload-drive-label"
                  >
                    File:
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    id="fileInput"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    accept=".xlsx"
                  />
                </div>
                <div className="mb-3 form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="upload-to-drive"
                    checked={uploadToDrive}
                    onChange={handleCheckboxChange}
                  />
                  <label className="form-check-label" htmlFor="upload-to-drive">
                    Upload to drive
                  </label>
                </div>

                <Button
                  type="submit"
                  aria-label="add"
                  color="primary"
                  variant="contained"
                >
                  <span className="text-sm">{buttonName}</span>
                </Button>
              </form>
            </div>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default Salary;

import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useEffect, useMemo, useState } from "react";
import { Card, Col, Row, Tab, Tabs } from "react-bootstrap";
import Modal from "react-bootstrap/esm/Modal";
import Skeleton from "react-loading-skeleton";
import { MultiSelect } from "react-multi-select-component";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import Select from "react-select";
import { getCompanyUsersList } from "../../../../apis/services/company.service";
import {
  addMemberInProject,
  deleteUserFromProject,
  postProject,
  updateMemberRoleInProject,
  updateProject,
} from "../../../../apis/services/projects.service";
import { getProjectMembersAndRoles } from "../../../../apis/services/user-projects.service";
import {
  enumStringToText,
  getRoleFromProjectPermissions,
  hasPermission,
  isSuccessCode,
} from "../../../../Common/Common";
import { Status, UserRole } from "../../../../Common/Enums/dashboard";
import {
  CompanyLevelPermissions,
  ProjectLevelPermissions,
} from "../../../../Common/Enums/permissions";
import {
  AddEditProjectModalProps,
  MemberRole,
  ProjectDetail,
  ReactSelect,
} from "../../../../Common/Interfaces/dashboard";
import { storage } from "../../../../config/firebase.includes";
import { ReduxState } from "../../../../redux/model/ReduxState.d";
import { UserCustomClaim } from "../../../../redux/model/UserState.d";
import Alert from "../../../Common/Alert/Alert";
import Button from "../../../Common/Button/Button";
import FilePicker from "../../../Common/FilePicker/FilePicker";
import DeleteModal from "../../../Common/Popup/DeleteModal";
import Table from "../../../Common/Table/Table";
import "./AddEditProjectModal.scss";
import {
  AddEditProjectMembersSchema,
  AddEditProjectSchema,
} from "../../../../Common/ValidationSchema/AddEditProjectSchema";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { IAddEditProject } from "../../../../Common/Interfaces/Project/IAddEditProject";

type ValidationSchema = z.infer<typeof AddEditProjectSchema>;
type ValidationSchemaMembers = z.infer<typeof AddEditProjectMembersSchema>;

export default function AddEditProjectModal(props: AddEditProjectModalProps) {
  const [projectId] = useState(props.editProject?.id || "");
  const [projectPictureUrl, setProjectPictureUrl] = useState(
    props.editProject?.profilePicture || ""
  );
  const [fileSelected, setFileSelected] = useState({} as File);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [showAddMemberFields, setShowAddMemberFields] =
    useState<boolean>(false);
  const [members, setMembers] = useState([] as MemberRole[]);
  const [deletemember, setDeleteMember] = useState({} as MemberRole);
  const [companyUsers, setCompanyUsers] = useState([] as ReactSelect[]);

  const [teamMembers] = useState(props.editProject?.teammates || 0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(true);
  const [isEdit] = useState(
    props.editProject &&
      props.editProject?.id &&
      props.editProject?.id.length > 0
      ? true
      : false
  );
  const [isLoading, setIsLoading] = useState(false);
  const permission_options = Object.keys(ProjectLevelPermissions).map(
    (permission) => new Option(enumStringToText(permission), permission)
  );
  const [selectedPermissions, setselectedPermissions] = useState([] as any[]);
  const [updateUserProject, setUpdateUserProject] = useState<MemberRole>(
    {} as MemberRole
  );
  const [selectedUpdatePermissions, setSelectedUpdatePermissions] = useState(
    [] as any[]
  );
  const [updateRole, setUpdateRole] = useState<string>(UserRole.TEAM_MEMBER);
  const [role, setRole] = useState<string>(UserRole.TEAM_MEMBER);
  const [isAddRemoveMember, setIsAddRemoveMember] = useState(false);

  const roles = [
    {
      value: UserRole.PROJECT_MANAGER,
      label: enumStringToText(UserRole.PROJECT_MANAGER),
    },
    {
      value: UserRole.TEAM_MEMBER,
      label: enumStringToText(UserRole.TEAM_MEMBER),
    },
    { value: UserRole.CUSTOM, label: enumStringToText(UserRole.CUSTOM) },
  ];
  const userCustomClaim = useSelector<ReduxState, UserCustomClaim>(
    (state: ReduxState) => state.UserReducer?.userPermission?.userCustomClaim
  );

  //by default, file validity is true, when invalid file is picked , then set validity to false
  const [isValidFile, setIsValidFile] = useState<boolean>(true);
  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors, isValid },
  } = useForm<ValidationSchema>({
    defaultValues: {
      projectName: props.editProject?.name || "",
      clientName: props.editProject?.clientName || "",
      clientEmail: props.editProject?.clientEmail || "",
      projectDate:
        new Date(props?.editProject?.startingDate as any) || new Date(),
      projectBoardUrl: props.editProject?.projectBoardUrl || "",
    },
    resolver: zodResolver(AddEditProjectSchema),
    mode: "all",
  });
  const {
    handleSubmit: handleMembersSubmit,
    control: membersControl,
    resetField,
    formState: { errors: projectMembersErr, isValid: membersIsValid },
  } = useForm<ValidationSchemaMembers>({
    resolver: zodResolver(AddEditProjectMembersSchema),
    mode: "all",
  });
  useEffect(() => {
    if (role === UserRole.PROJECT_MANAGER) {
      setselectedPermissions(
        Object.keys(ProjectLevelPermissions).map(
          (permission) => new Option(enumStringToText(permission), permission)
        )
      );
    } else if (role === UserRole.TEAM_MEMBER) {
      setselectedPermissions([]);
    } else if (role === UserRole.CUSTOM) {
      setselectedPermissions([]);
    }
  }, [role]);
  useEffect(() => {
    if (updateRole === UserRole.PROJECT_MANAGER) {
      setSelectedUpdatePermissions(
        Object.keys(ProjectLevelPermissions).map(
          (permission) => new Option(enumStringToText(permission), permission)
        )
      );
    } else if (updateRole === UserRole.TEAM_MEMBER) {
      setSelectedUpdatePermissions([]);
    } else if (updateRole === UserRole.CUSTOM) {
      setSelectedUpdatePermissions([]);
    }
  }, [updateRole]);
  const saveProject = async (url: string, projectData: IAddEditProject) => {
    const project: ProjectDetail = {
      name: projectData.projectName,
      clientEmail: projectData.clientEmail,
      profilePicture: url,
      clientName: projectData.clientName,
      projectBoardUrl: projectData.projectBoardUrl,
      teammates: teamMembers,
      startingDate: projectData.projectDate,
    };
    if (!isEdit) {
      const res = await postProject(props.selectedCompanyId, project);
      if (res && isSuccessCode(res?.status)) {
        project.id = res.data.id;
        props.addProject(project);
      } else {
        setIsSubmitted(false);
      }
    } else {
      const res = await updateProject(
        props.selectedCompanyId,
        projectId,
        project
      );
      if (res && isSuccessCode(res?.status)) {
        props.addProject(project, true);
      }
    }
  };
  const isFileSelected = (): boolean => {
    if (fileSelected && fileSelected.name) {
      return true;
    } else if (projectPictureUrl && projectPictureUrl.length > 0) {
      return true;
    } else {
      return false;
    }
  };
  const handleSave = (projectData: IAddEditProject) => {
    setIsSubmitted(true);
    if (isFileSelected() && fileSelected?.name) {
      handleUpload(projectData);
    } else {
      saveProject(projectPictureUrl, projectData);
    }
  };

  const handleUpload = (projectData: IAddEditProject) => {
    setIsUploading(true);
    const storageRef = ref(storage, "project/" + fileSelected.name);
    const uploadTask = uploadBytesResumable(storageRef, fileSelected);

    uploadTask.on(
      "state_changed",
      (snapshot: { bytesTransferred: number; totalBytes: number }) => {
        const progress =
          Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 1000) /
          10;
        setUploadProgress(progress);
      },
      (error: any) => {
        console.log(error);
      },
      async () => {
        try {
          const url = await getDownloadURL(storageRef);
          setProjectPictureUrl(url);
          saveProject(url, projectData);
        } catch (error: any) {
          console.log(error);
        }
      }
    );
  };
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "userName",
      },
      {
        Header: "Role",
        accessor: "userProjectPermissions",
        Cell: (props: any) => {
          return (
            <>
              <a data-tip data-for={`permissions${props.value.length}`}>
                {enumStringToText(getRoleFromProjectPermissions(props.value))}
              </a>
              <ReactTooltip
                id={`permissions${props.value.length}`}
                backgroundColor="#7A6AF6"
              >
                <span>{enumStringToText(props.value.join(", "))}</span>
              </ReactTooltip>
            </>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    getCompanyUsers();
  }, [props.selectedCompany]);

  useEffect(() => {
    getMembers();
  }, [projectId]);

  const getMembers = async () => {
    if (
      props.selectedCompany &&
      props.selectedCompany.id &&
      projectId &&
      projectId.length > 0
    ) {
      setIsLoading(true);
      const fetchedUsers = await getProjectMembersAndRoles(
        props.selectedCompany.id,
        "",
        projectId ?? ""
      );
      setMembers(fetchedUsers);
      setIsLoading(false);
    }
  };

  const getCompanyUsers = async () => {
    if (props.selectedCompany && props.selectedCompany.id) {
      setIsLoading(true);
      const fetchedCompanyUsers = await getCompanyUsersList(
        props.selectedCompany.id
      );
      const companyUsersData = fetchedCompanyUsers.map(
        (company) => ({ value: company.id, label: company.name } as ReactSelect)
      );
      setCompanyUsers(companyUsersData);
      setIsLoading(false);
    }
  };

  const handleAddNewMember = async (projectMembersData: any) => {
    const selectedUser = projectMembersData?.projectMember?.[0];
    const memberBody = {
      projectId: projectId,
      userId: selectedUser?.value,
      userProjectPermissions: selectedPermissions.map(
        (permission) => permission.value
      ),
      status: Status.ACTIVE,
    };
    setIsLoading(true);
    const res = await addMemberInProject(props.selectedCompany.id, memberBody);
    if (res && isSuccessCode(res?.status)) {
      setMembers([
        ...members,
        {
          userName: selectedUser?.label,
          userId: selectedUser?.value,
          userProjectPermissions: selectedPermissions.map(
            (permission) => permission.value
          ),
        } as MemberRole,
      ]);
    }
    setIsAddRemoveMember(true);
    setIsLoading(false);
  };

  const handleEditMember = async () => {
    const memberBody = {
      projectId: projectId,
      userId: updateUserProject.userId,
      userProjectPermissions: selectedUpdatePermissions.map(
        (permission) => permission.value
      ),
      status: Status.ACTIVE,
    };
    setIsLoading(true);
    const res = await updateMemberRoleInProject(
      props.selectedCompany.id,
      updateUserProject.id,
      memberBody
    );
    if (res && isSuccessCode(res?.status)) {
      const updatedMembers = members.map((projectUser: MemberRole) => {
        if (projectUser.id === updateUserProject.id) {
          return {
            userId: updateUserProject.userId,
            id: updateUserProject.id,
            userName: updateUserProject.userName,
            userProjectPermissions: selectedUpdatePermissions.map(
              (permission) => permission.value
            ),
          } as MemberRole;
        } else {
          return projectUser;
        }
      });
      setMembers(updatedMembers);
      setUpdateUserProject({} as MemberRole);
    }
    setIsLoading(false);
  };

  const deleteProjectMemberData = (id: string) => {
    const membersCopy = [...members];
    for (let i = 0; i < membersCopy.length; i++) {
      if (membersCopy[i].id === id) membersCopy.splice(i);
    }
    setMembers(membersCopy);
  };
  const handleDeleteRole = async (userProject: MemberRole) => {
    setDeleteMember(userProject);
    setShowDeleteModal(true);
  };
  const handleDelete = async () => {
    setShowDeleteModal(false);
    const projectId = props.editProject?.id || "";
    const res = await deleteUserFromProject(
      props.selectedCompanyId,
      projectId,
      deletemember.userId
    );
    if (res && isSuccessCode(res?.status)) {
      deleteProjectMemberData(deletemember.id);
      //
      const indexOfObject = members.findIndex((object) => {
        return object.userId == deletemember.userId;
      });
      members.splice(indexOfObject, 1);
      setMembers([...members]);
      setIsAddRemoveMember(true);
    }
  };
  const handleEditRole = (userProject: MemberRole) => {
    setUpdateUserProject(userProject);
    setUpdateRole(
      getRoleFromProjectPermissions(userProject.userProjectPermissions)
    );
  };

  const handleSelect = (eventKey: any) => {
    if (eventKey === "details") setShowSaveButton(true);
    else setShowSaveButton(false);
  };

  const disableFields = () => {
    if (!isValid || isSubmitted || !isFileSelected() || !isValidFile)
      return true;
  };
  const projectDefaultValues = () => {
    const defaultValues = getValues();
    const project: ProjectDetail = {
      name: defaultValues.projectName,
      clientEmail: defaultValues.clientEmail,
      profilePicture: projectPictureUrl,
      clientName: defaultValues.clientName,
      projectBoardUrl: defaultValues.projectBoardUrl,
      teammates: members.length,
      startingDate: defaultValues.projectDate,
    };
    return project;
  };

  return (
    <>
      <Modal
        className="container-modal"
        size="lg"
        show={props.showModal}
        onHide={() => {
          props.handleClose(projectDefaultValues(), isAddRemoveMember);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? "Edit Project" : "Add Project"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            defaultActiveKey="details"
            id="editModelTab"
            className="mb-3"
            onSelect={handleSelect}
          >
            <Tab eventKey="details" title="Details">
              <form onSubmit={handleSubmit(handleSave)}>
                <Row>
                  <Col>
                    <label className="fw-bold">Project Name</label>

                    <input
                      type="text"
                      id={"projectName"}
                      className={`form-control rounded-pill ${
                        errors?.projectName && "is-invalid"
                      }`}
                      {...register("projectName")}
                    />
                    <p className="mt-1 ml-3 text-xs text-red-600">
                      {errors?.projectName?.message}
                    </p>
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col>
                    <label className="fw-bold">Client Name</label>

                    <input
                      type="text"
                      id={"clientName"}
                      className={`form-control rounded-pill ${
                        errors?.clientName && "is-invalid"
                      }`}
                      {...register("clientName")}
                    />
                    <p className="mt-1 ml-3 text-xs text-red-600">
                      {errors?.clientName?.message}
                    </p>
                  </Col>
                  <Col>
                    <label className="fw-bold">Client Email</label>

                    <input
                      type="email"
                      id={"clientEmail"}
                      className={`form-control rounded-pill ${
                        errors?.clientEmail && "is-invalid"
                      }`}
                      {...register("clientEmail")}
                    />
                    <p className="mt-1 ml-3 text-xs text-red-600">
                      {errors?.clientEmail?.message}
                    </p>
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col>
                    <label className="fw-bold">Starting Date</label>
                    <Controller
                      control={control}
                      name="projectDate"
                      render={({ field }) => (
                        <DatePickerComponent
                          placeholder="Choose a date"
                          value={field.value}
                          format="dd-MMM-yy"
                          onChange={(e: any) => field.onChange(e)}
                        ></DatePickerComponent>
                      )}
                    />
                    <p className="mt-1 ml-3 text-xs text-red-600">
                      {errors?.projectDate?.message}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label className="fw-bold">Project Board Url</label>
                    <input
                      type="text"
                      id={"projectBoardUrl"}
                      className={`form-control rounded-pill ${
                        errors?.projectBoardUrl && "is-invalid"
                      }`}
                      {...register("projectBoardUrl")}
                    />
                    <p className="mt-1 ml-3 text-xs text-red-600">
                      {errors?.projectBoardUrl?.message}
                    </p>
                  </Col>
                </Row>

                <Row>
                  <label className="fw-bold">Project Logo</label>
                  <FilePicker
                    fileSelected={fileSelected}
                    setFileSelected={setFileSelected}
                    isUploading={isUploading}
                    uploadProgress={uploadProgress}
                    pictureUrl={projectPictureUrl}
                    name={"projectLogo"}
                    setIsValidFile={setIsValidFile}
                    userCustomClaim={userCustomClaim}
                  />
                  <div
                    className={`text-danger ${
                      !isFileSelected() && isSubmitted ? "" : "d-none"
                    }`}
                  >
                    <small>Project Picture is required</small>
                  </div>
                  {!isValidFile && (
                    <small className="text-danger">
                      Only .jpg, .jpeg, .png files are accepted.
                    </small>
                  )}
                </Row>
                {showSaveButton && (
                  <Button
                    type="submit"
                    text={isEdit ? "Update Project" : "Save Project"}
                    disabled={disableFields()}
                    additionalClasses={`h-10 mt-3 bg-blue-600 text-white w-full mb-2 ${
                      disableFields() && "opacity-25 cursor-not-allowed"
                    }`}
                  />
                )}
                <Button
                  onClick={() => {
                    props.handleClose(
                      projectDefaultValues(),
                      isAddRemoveMember
                    );
                  }}
                  text="Close"
                  additionalClasses={
                    "h-10 mt-3 bg-gray-600 text-white w-full mb-2"
                  }
                />
              </form>
            </Tab>

            {isEdit &&
              hasPermission(
                userCustomClaim,
                CompanyLevelPermissions.CREATE_USER_PROJECT
              ) && (
                <Tab eventKey="member" title="Members">
                  <Row>
                    <Col sm={3}>
                      <Button
                        onClick={() => setShowAddMemberFields(true)}
                        text={"Add Member"}
                        iconPath={"fab fa fa-plus"}
                        additionalClasses={"gray-button"}
                      />
                    </Col>
                    {showAddMemberFields ? (
                      <form onSubmit={handleMembersSubmit(handleAddNewMember)}>
                        <Col sm={3}>
                          {!isLoading && (
                            <>
                              <Controller
                                name="projectMember"
                                control={membersControl}
                                render={({ field }) => (
                                  <Select
                                    className="w-48"
                                    options={companyUsers}
                                    placeholder={"Select Member"}
                                    isMulti={false}
                                    onChange={(e) => {
                                      field.onChange([e]);
                                    }}
                                    isSearchable={false}
                                  />
                                )}
                              />
                              <p className="mt-1 ml-3 text-xs text-red-600">
                                {projectMembersErr?.projectMember?.message}
                              </p>
                            </>
                          )}
                        </Col>
                        <Col>
                          <label className="fw-bold">Role</label>

                          <Controller
                            name="memberRole"
                            control={membersControl}
                            render={({ field }) => (
                              <Select
                                options={roles}
                                className="w-48"
                                placeholder="Select Role"
                                isMulti={false}
                                onChange={(e) => {
                                  field.onChange(e?.value);
                                  if (e?.value) setRole(e?.value);
                                  resetField("customRole");
                                }}
                                isSearchable={false}
                              />
                            )}
                          />
                          <p className="mt-1 ml-3 text-xs text-red-600">
                            {projectMembersErr?.memberRole?.message}
                          </p>
                        </Col>

                        {role === UserRole.CUSTOM && (
                          <Row>
                            <Col>
                              <label className="fw-bold">Permissions</label>
                              <Controller
                                name="customRole"
                                control={membersControl}
                                render={({ field }) => (
                                  <MultiSelect
                                    options={permission_options}
                                    value={selectedPermissions}
                                    onChange={(e: any) => {
                                      field.onChange(e);
                                      setselectedPermissions(e);
                                      if (e?.lenght === 0)
                                        resetField("customRole");
                                    }}
                                    labelledBy="Select"
                                  />
                                )}
                              />
                              <p className="mt-1 ml-3 text-xs text-red-600">
                                {projectMembersErr?.customRole?.message}
                              </p>
                            </Col>
                          </Row>
                        )}
                        <Col sm={2}>
                          <Button
                            type="submit"
                            text="Save Member"
                            disabled={isLoading || !membersIsValid}
                            additionalClasses={`h-10 mt-3 bg-blue-600 text-white w-full mb-2 ${
                              (isLoading || !membersIsValid) &&
                              "opacity-25 cursor-not-allowed"
                            }`}
                          />
                        </Col>
                      </form>
                    ) : null}
                  </Row>
                  <Row className="mt-4">
                    <>
                      {!isLoading && (
                        <Table
                          data={members}
                          columns={columns}
                          handleEditRole={handleEditRole}
                          handleDelete={handleDeleteRole}
                        />
                      )}
                      {isLoading && (
                        <>
                          <Skeleton height={100} />
                        </>
                      )}
                    </>
                  </Row>
                  {!isLoading && members.length === 0 && (
                    <Alert message={"No member added yet"} />
                  )}

                  {/* ************************************************ UPDATE USER ROLE ************************************************************** */}
                  {updateUserProject.userId && (
                    <Card className="my-3">
                      <Card.Header>
                        <h5>
                          Update{" "}
                          <span className="purple-color">
                            {updateUserProject.userName}
                          </span>
                          's role:
                        </h5>
                      </Card.Header>
                      <Row className="my-3 mx-2">
                        <Col>
                          <label className="fw-bold">Role</label>
                          <br />
                          <Select
                            options={roles}
                            className="w-48"
                            placeholder={enumStringToText(updateRole)}
                            isMulti={false}
                            onChange={(e) => {
                              if (e?.value) setUpdateRole(e?.value);
                            }}
                            isSearchable={false}
                          />
                        </Col>

                        {updateRole === UserRole.CUSTOM && (
                          <Col>
                            <label className="fw-bold">Permissions</label>

                            <MultiSelect
                              options={permission_options}
                              value={selectedUpdatePermissions}
                              onChange={setSelectedUpdatePermissions}
                              labelledBy="Select"
                            />
                          </Col>
                        )}

                        <Col>
                          <Button
                            onClick={() => handleEditMember()}
                            text="Update Member"
                            additionalClasses={
                              "h-10 mt-3 bg-blue-600 text-white w-full mb-2"
                            }
                          />
                        </Col>
                      </Row>
                    </Card>
                  )}
                  {/* ******************************************************************************************************************************** */}
                  {showDeleteModal && (
                    <DeleteModal
                      showModal={showDeleteModal}
                      handleClose={() => setShowDeleteModal(false)}
                      handleDelete={handleDelete}
                      message="Are you sure you want to delete this Member from project?"
                    />
                  )}
                </Tab>
              )}
          </Tabs>
        </Modal.Body>
      </Modal>
    </>
  );
}

import { useEffect, useState } from "react";
import { Video } from "../../../../Common/Interfaces/Videos/my-videos";
import {
  deleteVideo,
  getVideosByUserId,
} from "../../../../apis/services/videos.service";
// import Loader from "../../../Common/Loader/Loader";
import { Col } from "react-bootstrap";
import { IconButton } from "@mui/material";
import Alert from "../../../Common/Alert/Alert";
import BasicTable from "../../../Common/Table/BasicTable";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useSelector } from "react-redux";
import { ReduxState } from "../../../../redux/model/ReduxState.d";
import { UserCustomClaim } from "../../../../redux/model/UserState.d";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { COLUMNS } from "./my-video-data-columns";
import { MaxPageData } from "../../../../Common/Enums/pagination";
import { isSuccessCode } from "../../../../Common/Common";
import EditVideoModal from "../EditVideoModal/EditVideoModal";
import DeleteModal from "../../../Common/Popup/DeleteModal";

export default function MyVideos() {
  const userCustomClaim = useSelector<ReduxState, UserCustomClaim>(
    (state: ReduxState) => state.UserReducer?.userPermission?.userCustomClaim
  );

  const userId = useSelector<ReduxState, string>(
    (state: ReduxState) => state.UserReducer.userId || ""
  );
  const [tableKey, setTableKey] = useState(0);
  const [videos, setVideos] = useState([] as Video[]);
  const [showModal, setShowModal] = useState(false);
  const [editVideo, setEditVideo] = useState({} as Video);

  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [prePageNumber, setPrePageNumber] = useState(0);

  const handleClose = () => setShowModal(false);

  const handleShow = (video: Video) => {
    setEditVideo(video);

    setShowModal(true);
  };

  const handleDelete = async (video: Video) => {
    setShowDeleteModal(false);

    const res = await deleteVideo(video.id);
    if (res && isSuccessCode(res?.status)) {
      deleteVideoData(video.id);
    }
  };
  useEffect(() => {
    if (videos?.length) {
      setTableKey((prevKey) => prevKey + 1);
    }
  }, [videos]);

  useEffect(() => {
    if (userCustomClaim) {
      getVideos();
    }
  }, [pageNumber, userCustomClaim]);

  const getVideos = async (startAfter = "") => {
    let endBefore = "";
    if (startAfter === "")
      if (pageNumber > prePageNumber)
        startAfter = videos[videos.length - 1].createdAt.toString() ?? "";
      else if (pageNumber < prePageNumber) {
        endBefore = videos[0].createdAt.toString() ?? "";
      }
    setIsLoading(true);
    const videosInfo = await getVideosByUserId(startAfter, endBefore);
    if (videosInfo) {
      setVideos(videosInfo);
    }
    pageNumber === 1 && pageNumber < prePageNumber
      ? setPrePageNumber(0)
      : setPrePageNumber(pageNumber);
    setIsLoading(false);
  };

  const videosAvailable = (): boolean => {
    if (videos?.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const deleteVideoData = (videoId: string) => {
    if (videos.length === 1) setPageNumber((pageNumber) => pageNumber - 1);
    else {
      if (videos.length === 6) {
        videos[0].id === videoId
          ? getVideos(videos[1].id)
          : getVideos(videos[0].id);
      } else {
        const videosList = [...videos];
        for (let i = 0; i < videosList.length; i++) {
          if (videosList[i].id === videoId) {
            i === 0 ? getVideos(videosList[1].id) : videosList.splice(i, 1);
          }
        }
        setVideos(videosList);
      }
    }
  };

  const tableHooks = (hooks: any) => {
    hooks.visibleColumns.push((columns: any) => [
      ...columns,
      {
        id: "videoactions",
        Header: "Actions",
        accessor: "actions",
        disableSortBy: true,
        Cell: ({ row }: any) => {
          return (
            <>
              <IconButton
                aria-label="Edit"
                color="primary"
                onClick={() => handleShow(row.original)}
              >
                <EditIcon fontSize="medium" />
              </IconButton>

              <IconButton
                aria-label="Delete"
                color="error"
                onClick={() => handleDelete(row.original)}
              >
                <DeleteIcon fontSize="medium" />
              </IconButton>
            </>
          );
        },
      },
    ]);
  };

  return (
    <>
      <div className="mt-4">
        {/* <Loader active={isLoading}> */}
        {!isLoading && !videosAvailable() && (
          <Alert message={"No Videos Available"} />
        )}
        {!isLoading && videosAvailable() && (
          <BasicTable
            tableColumns={COLUMNS}
            tableData={videos}
            headerColor="bg-gray-850"
            maxHeight={800}
            tableHooks={tableHooks}
            key={tableKey}
          />
        )}
        {!isLoading && videosAvailable() && (
          <div className="inline-flex float-right my-2">
            {pageNumber === 0 && (
              <Col>
                <IconButton
                  aria-label="left"
                  color="primary"
                  disabled={pageNumber === 0}
                >
                  <NavigateBeforeIcon fontSize="large" />
                </IconButton>
              </Col>
            )}
            {pageNumber > 0 && (
              <Col>
                <IconButton aria-label="left" color="primary">
                  <NavigateBeforeIcon
                    fontSize="large"
                    onClick={() => {
                      setPageNumber((pageNumber) => pageNumber - 1);
                    }}
                  />
                </IconButton>
              </Col>
            )}
            {videos?.length !== MaxPageData.DATA_SIZE && (
              <Col>
                <IconButton aria-label="right" color="primary" disabled>
                  <NavigateNextIcon fontSize="large" />
                </IconButton>
              </Col>
            )}
            {videos?.length === MaxPageData.DATA_SIZE && (
              <Col>
                <IconButton aria-label="right" color="primary">
                  <NavigateNextIcon
                    fontSize="large"
                    onClick={() => {
                      setPageNumber((pageNumber) => pageNumber + 1);
                    }}
                  />
                </IconButton>
              </Col>
            )}
          </div>
        )}
        {/* </Loader> */}
      </div>
      {showModal && (
        <EditVideoModal
          showModal={showModal}
          handleClose={handleClose}
          editVideo={editVideo}
          userInfo={{ companyId: userCustomClaim.companyId, userId: userId }}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          showModal={showDeleteModal}
          handleClose={() => setShowDeleteModal(false)}
          handleDelete={handleDelete}
          message="Are you sure you want to delete this video?"
        />
      )}
    </>
  );
}

import { Table } from "react-bootstrap";
import Modal from "react-bootstrap/esm/Modal";
import { useState } from "react";
import Button from "../../../Common/Button/Button";
import { AddMenuItemModalProps } from "../../../../Common/Interfaces/Lunch/add-menu-item-modal-props";
import TextField from "../../../Common/TextField/TextField";
import HashMap from "hashmap";
import {
  enumStringToText,
  getDayFromDayNumber,
  getDayNumberFromIndex,
  isSuccessCode,
} from "../../../../Common/Common";
import { postMenu } from "../../../../apis/services/menus.service";
import { Menu } from "../../../../Common/Interfaces/Lunch/menu-item";
import { DayOfWeek } from "../../../../Common/Enums/day";
import { DayNumber } from "../../../../Common/Enums/day-number";

export default function AddMenuItemModal(props: AddMenuItemModalProps) {
  const [menuItemsByDateMap, setMenuItemsByDateMap] = useState(
    new HashMap<DayNumber, string>()
  ); // {DAY_1 : 'Daal Lobia'...}

  const days = Object.keys(DayNumber).map(
    (dayNumber) => dayNumber as DayNumber
  );
  const weekend: DayOfWeek[] = [DayOfWeek.SATURDAY, DayOfWeek.SUNDAY];

  const handleSave = async () => {
    const items = [] as Menu[];
    menuItemsByDateMap.forEach(function (value, key) {
      items.push({
        dayNumber: key,
        itemName: value,
        companyId: props.selectedCompany.id,
      } as Menu);
    });
    const res = await postMenu(props.selectedCompany.id, items);
    if (res && isSuccessCode(res?.status)) {
      props.addMenuItem(menuItemsByDateMap, res.data);
      props.handleClose();
    }
  };

  return (
    <>
      <Modal
        className="container-modal"
        size="lg"
        show={props.showModal}
        onHide={() => props.handleClose()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Menu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="table-responsive"
            style={{ height: "45vh", overflow: "auto" }}
          >
            <Table striped bordered hover className="table mt-2 text-center">
              <tbody>
                {days &&
                  days.map(function (d, index) {
                    if (!weekend.includes(getDayFromDayNumber(d))) {
                      return (
                        <tr key={index}>
                          <td>
                            {getDayFromDayNumber(d)}
                            <br />
                            {"(" + enumStringToText(d) + ")"}
                          </td>
                          <td>
                            {" "}
                            <TextField
                              id={"menuItemField"}
                              type={"text"}
                              placeholder={"Menu Item"}
                              width={""}
                              setValue={(e: any) => {
                                const amap = menuItemsByDateMap.clone();
                                amap.set(getDayNumberFromIndex(index), e);
                                setMenuItemsByDateMap(amap);
                              }}
                              defaultValue={
                                menuItemsByDateMap.get(
                                  getDayNumberFromIndex(index)
                                ) || ""
                              }
                              disabled={
                                !props.canEdit(getDayNumberFromIndex(index))
                              }
                              additionalClasses={""}
                              fieldName={"Menu Item"}
                            ></TextField>
                          </td>
                        </tr>
                      );
                    }
                  })}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => props.handleClose()}
            text="Close"
            additionalClasses={"gray-button"}
          />
          <Button
            onClick={() => handleSave()}
            text="Save Menu"
            additionalClasses={"purple-button"}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}

import { z } from "zod";

export const AddEditTimeLogSchema = z.object({
  projectName: z
    .array(
      z.object({
        value: z.string(),
        label: z.string(),
      })
    )
    .nonempty({ message: "Please select member" })
    .refine((projectName) => {
      if (projectName[0].value === "") return false;
      else return true;
    }),
  ticketUrl: z
    .string()
    .nonempty({ message: "Ticket url is required" })
    .url({ message: "Url format should be like http://www.google.com" }),
  ticketDate: z.date({
    required_error: "Please select date",
    invalid_type_error: "Please select valid date!",
  }),
  workLogMinutes: z.coerce
    .number()
    .positive({ message: "Time must be a number" }),
  ticketName: z.string().nonempty({ message: "Ticket Name is required" }),
  ticketDescription: z
    .string()
    .nonempty({ message: "Ticket description is required" }),
});

export const sortByName = (rowA: any, rowB: any, columnId: any) => {
  const priorityA = rowA.original[columnId];
  const priorityB = rowB.original[columnId];

  // Convert the priority values to uppercase for consistent comparison
  const priorityAUpper = priorityA.toUpperCase();
  const priorityBUpper = priorityB.toUpperCase();

  // Compare the initials of the priority values
  if (priorityAUpper < priorityBUpper) {
    return -1;
  }
  if (priorityAUpper > priorityBUpper) {
    return 1;
  }

  // If initials are the same, compare the full values as numbers
  const priorityANumber = parseInt(priorityA, 10);
  const priorityBNumber = parseInt(priorityB, 10);

  return priorityANumber - priorityBNumber;
};

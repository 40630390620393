import { Column } from "react-table";
import { sortByName } from "../../../utils/helpers/sorting";
import { convertTime, getClickableLink } from "../../../Common/Common";
import moment from "moment";

export const COLUMNS: Column[] | any = [
  {
    Header: "Project Name",
    accessor: "projectName",
    sortType: sortByName,
  },
  {
    Header: "Name",
    accessor: "userName",
    sortType: sortByName,
  },
  {
    Header: "Description",
    accessor: "ticketDescription",
    disableSortBy: true,
  },
  {
    Header: "Date",
    accessor: "date",
    disableSortBy: true,
    Cell: ({ row }: any) => {
      const formattedDate = moment(row?.original?.date).format("DD MMMM, yyyy");
      return <span>{formattedDate}</span>;
    },
  },
  {
    Header: "Time",
    accessor: "worklogMinutes",
    disableSortBy: true,
    Cell: ({ row }: any) => {
      return <span>{convertTime(row?.original?.worklogMinutes ?? 0)}</span>;
    },
  },
  {
    Header: "Ticket URL",
    accessor: "ticketUrl",
    disableSortBy: true,
    Cell: ({ row }: any) => {
      return (
        <a
          href={getClickableLink(row?.original?.ticketUrl)}
          target="_blank"
          rel="noreferrer"
        >
          {row?.original?.ticketUrl.substring(0, 20) + "..."}
        </a>
      );
    },
  },
  {
    id: "worklogbillable",
    Header: "Billable",
    accessor: "billable",
    disableSortBy: true,
    Cell: ({ row }: any) => {
      return (
        <span className="px-4">{row?.original?.billable ? "Yes" : "No"}</span>
      );
    },
  },
];

import { useSelector } from "react-redux";

import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import EventSeatIcon from "@mui/icons-material/EventSeat";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import FeedbackIcon from "@mui/icons-material/Feedback";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import VideocamIcon from "@mui/icons-material/Videocam";
import { Box, IconButton, Link, ListItemButton } from "@mui/material";

import {
  getProjectPermissions,
  hasPermission,
  isAdmin,
} from "../../../Common/Common";
import { DashboardTab } from "../../../Common/Enums/dashboard";
import {
  CompanyLevelPermissions,
  ProjectLevelPermissions,
} from "../../../Common/Enums/permissions";

import { ReduxState } from "../../../redux/model/ReduxState.d";
import { UserCustomClaim } from "../../../redux/model/UserState.d";

import AuroraLogo from "../../../Common/logo/AuroraLogo";
import AuroraLogoFull from "../../../Common/logo/AuroraLogoFull";

import classNames from "classnames";

// add NavItem prop to component prop
type Props = {
  collapsed: boolean;
  navigateHandler: (tabName: string, tabValue: number) => void;
  selectedIndex: number;
  setCollapsed(collapsed: boolean): void;
};
const SidebarTW = ({
  collapsed,
  navigateHandler,
  selectedIndex,
  setCollapsed,
}: Props) => {
  const userCustomClaim = useSelector<ReduxState, UserCustomClaim>(
    (state: ReduxState) => state.UserReducer?.userPermission?.userCustomClaim
  );
  const projectState = useSelector(
    (state: ReduxState) => state.SelectedProjectReducer
  );

  const isAuthorizedForMembers = () => {
    if (
      isAdmin(userCustomClaim) ||
      hasPermission(userCustomClaim, CompanyLevelPermissions.GET_COMPANY_USERS)
    ) {
      return true;
    }
    return getProjectPermissions(userCustomClaim, projectState).includes(
      ProjectLevelPermissions.GET_PROJECT_USERS
    );
  };

  const handleNavigateClick = (tabName: string, tabValue: number) => {
    navigateHandler(tabName, tabValue);
  };

  return (
    <div
      className={classNames({
        // Existing classes
        "bg-gradient-to-b from-gray-850 to-gray-850 drop-shadow-2xl": true,
        "bg-indigo-700 text-zinc-50 fixed md:static md:translate-x-0 z-20":
          true,
        "w-[80%]": !collapsed,
        "w-auto": collapsed,
        "-translate-x-full": !collapsed,
        "w-60": !collapsed,

        // Add transition classes
        "transition-all duration-300 ease-in-out": true,
      })}
    >
      <div
        className={classNames({
          "flex flex-col justify-between h-screen sticky inset-0 w-full": true,
        })}
      >
        {/* <div className="float-right">
          <IconButton
            className="grid place-content-center hover:bg-indigo-800 w-10 h-10 rounded-full md:opacity-100"
            onClick={() => setCollapsed(!collapsed)}
          >
            {collapsed ? (
              <MenuOpenIcon className="text-white" />
            ) : (
              <MenuIcon className="text-white" />
            )}
          </IconButton>
        </div> */}
        <nav className="flex-grow">
          <ul
            className={classNames({
              "my-2 flex flex-col gap-2 items-stretch p-0": true,
            })}
          >
            {/* Logo */}
            {!collapsed && (
              <span className="w-52 m-3">
                <AuroraLogoFull />
                <div className="float-right m-y-[-1px] mx-[-30px]">
                  <IconButton
                    className="grid place-content-center hover:bg-indigo-800 w-10 h-10 rounded-full md:opacity-100 bg-gray-850"
                    onClick={() => setCollapsed(!collapsed)}
                  >
                    <NavigateBeforeIcon
                      fontSize="large"
                      className="text-white"
                    />
                  </IconButton>
                </div>
              </span>
            )}
            {collapsed && (
              <span className="w-52 m-3">
                <AuroraLogo />
                <div className="float-right my-auto mx-[150px] transition-[grid-template-columns] duration-300 ease-in-out">
                  <IconButton
                    className="grid place-content-center hover:bg-indigo-800 w-10 h-10 rounded-full md:opacity-100 bg-gray-850"
                    onClick={() => setCollapsed(!collapsed)}
                  >
                    <NavigateNextIcon fontSize="large" className="text-white" />
                  </IconButton>
                </div>
              </span>
            )}

            {/* <hr className="my-1/2 mx-1 border-2 border-white" /> */}
            {hasPermission(
              userCustomClaim,
              CompanyLevelPermissions.GET_COMPANY
            ) && (
              <Box
                className={`p-0 ${
                  selectedIndex === 0
                    ? "rounded-lg bg-blue-550 my-0	mx-2"
                    : "rounded-none bg-transparent"
                }`}
              >
                <ListItemButton
                  className="p-0"
                  selected={selectedIndex === 0}
                  onClick={() => handleNavigateClick("company", 0)}
                >
                  <li
                    className={classNames({
                      "text-indigo-100 hover:bg-indigo-900 flex": true, //colors
                      "transition-colors duration-300": true, //animation
                      "rounded-md p-2 mx-3 gap-4 ": !collapsed,
                      "rounded-full p-2 m-auto w-10 h-10": collapsed,
                    })}
                  >
                    <Link
                      underline="none"
                      key={DashboardTab.COMPANY}
                      className="flex gap-2 no-underline"
                    >
                      <CorporateFareIcon className="text-white" />{" "}
                      <span className="text-white text-xl flex justify-center	items-center">
                        {!collapsed && "Company"}
                      </span>
                    </Link>
                  </li>
                </ListItemButton>
              </Box>
            )}
            {hasPermission(
              userCustomClaim,
              CompanyLevelPermissions.GET_COMPANY_PROJECTS
            ) && (
              <Box
                className={`p-0 ${
                  selectedIndex === 1
                    ? "rounded-lg bg-blue-550 my-0	mx-2"
                    : "rounded-none bg-transparent"
                }`}
              >
                <ListItemButton
                  className="p-0"
                  selected={selectedIndex === 1}
                  onClick={() => handleNavigateClick("projects", 1)}
                >
                  <li
                    className={classNames({
                      "text-indigo-100 hover:bg-indigo-900 flex": true, //colors
                      "transition-colors duration-300": true, //animation
                      "rounded-md p-2 mx-3 gap-4 ": !collapsed,
                      "rounded-full p-2 m-auto w-10 h-10": collapsed,
                    })}
                  >
                    <Link
                      underline="none"
                      key={DashboardTab.PROJECTS}
                      className="flex gap-2"
                    >
                      <AccountTreeIcon className="text-white" />{" "}
                      <span className="text-white text-xl flex justify-center	items-center">
                        {!collapsed && "Projects"}
                      </span>
                    </Link>
                  </li>
                </ListItemButton>
              </Box>
            )}
            {isAuthorizedForMembers() && (
              <Box
                className={`p-0 ${
                  selectedIndex === 2
                    ? "rounded-lg bg-blue-550 my-0	mx-2"
                    : "rounded-none bg-transparent"
                }`}
              >
                <ListItemButton
                  className="p-0"
                  selected={selectedIndex === 2}
                  onClick={() => handleNavigateClick("members", 2)}
                >
                  <li
                    className={classNames({
                      "text-indigo-100 hover:bg-indigo-900 flex": true, //colors
                      "transition-colors duration-300": true, //animation
                      "rounded-md p-2 mx-3 gap-4 ": !collapsed,
                      "rounded-full p-2 m-auto w-10 h-10": collapsed,
                    })}
                  >
                    <Link
                      underline="none"
                      key={DashboardTab.MEMBERS}
                      className="flex gap-2"
                    >
                      <Diversity3Icon className="text-white" />{" "}
                      <span className="text-white text-xl flex justify-center	items-center">
                        {!collapsed && "Members"}
                      </span>
                    </Link>
                  </li>
                </ListItemButton>
              </Box>
            )}
            {hasPermission(
              userCustomClaim,
              CompanyLevelPermissions.CREATE_WORKLOG
            ) && (
              <Box
                className={`p-0 ${
                  selectedIndex === 3
                    ? "rounded-lg bg-blue-550 my-0	mx-2"
                    : "rounded-none bg-transparent"
                }`}
              >
                <ListItemButton
                  className="p-0"
                  selected={selectedIndex === 3}
                  onClick={() => handleNavigateClick("timelogs", 3)}
                >
                  <li
                    className={classNames({
                      "text-indigo-100 hover:bg-indigo-900 flex": true, //colors
                      "transition-colors duration-300": true, //animation
                      "rounded-md p-2 mx-3 gap-4 ": !collapsed,
                      "rounded-full p-2 m-auto w-10 h-10": collapsed,
                    })}
                  >
                    <Link
                      underline="none"
                      key={DashboardTab.TIMELOGS}
                      className="flex gap-2"
                    >
                      <MoreTimeIcon className="text-white" />{" "}
                      <span className="text-white text-xl flex justify-center	items-center">
                        {!collapsed && "Time Logs"}
                      </span>
                    </Link>
                  </li>
                </ListItemButton>
              </Box>
            )}
            {hasPermission(
              userCustomClaim,
              CompanyLevelPermissions.GET_USER_ATTENDANCE
            ) && (
              <Box
                className={`p-0 ${
                  selectedIndex === 4
                    ? "rounded-lg bg-blue-550 my-0	mx-2"
                    : "rounded-none bg-transparent"
                }`}
              >
                <ListItemButton
                  className="p-0"
                  selected={selectedIndex === 4}
                  onClick={() => handleNavigateClick("attendance", 4)}
                >
                  <li
                    className={classNames({
                      "text-indigo-100 hover:bg-indigo-900 flex": true, //colors
                      "transition-colors duration-300": true, //animation
                      "rounded-md p-2 mx-3 gap-4 ": !collapsed,
                      "rounded-full p-2 m-auto w-10 h-10": collapsed,
                    })}
                  >
                    <Link
                      underline="none"
                      key={DashboardTab.ATTENDANCE}
                      className="flex gap-2"
                    >
                      <EditCalendarIcon className="text-white" />{" "}
                      <span className="text-white text-xl flex justify-center	items-center">
                        {!collapsed && "Attendance"}
                      </span>
                    </Link>
                  </li>
                </ListItemButton>
              </Box>
            )}
            {hasPermission(
              userCustomClaim,
              CompanyLevelPermissions.GET_ALLOCATION
            ) && (
              <Box
                className={`p-0 ${
                  selectedIndex === 5
                    ? "rounded-lg bg-blue-550 my-0	mx-2"
                    : "rounded-none bg-transparent"
                }`}
              >
                <ListItemButton
                  className="p-0"
                  selected={selectedIndex === 5}
                  onClick={() => handleNavigateClick("allocation", 5)}
                >
                  <li
                    className={classNames({
                      "text-indigo-100 hover:bg-indigo-900 flex": true, //colors
                      "transition-colors duration-300": true, //animation
                      "rounded-md p-2 mx-3 gap-4 ": !collapsed,
                      "rounded-full p-2 m-auto w-10 h-10": collapsed,
                    })}
                  >
                    <Link
                      underline="none"
                      key={DashboardTab.ALLOCATION}
                      className="flex gap-2"
                    >
                      <EventSeatIcon className="text-white" />{" "}
                      <span className="text-white text-xl flex justify-center	items-center">
                        {!collapsed && "Allocation"}
                      </span>
                    </Link>
                  </li>
                </ListItemButton>
              </Box>
            )}
            {hasPermission(
              userCustomClaim,
              CompanyLevelPermissions.GET_USER_FOOD_ORDER
            ) && (
              <Box
                className={`p-0 ${
                  selectedIndex === 6
                    ? "rounded-lg bg-blue-550 my-0	mx-2"
                    : "rounded-none bg-transparent"
                }`}
              >
                <ListItemButton
                  className="p-0"
                  selected={selectedIndex === 6}
                  onClick={() => handleNavigateClick("lunch", 6)}
                >
                  <li
                    className={classNames({
                      "text-indigo-100 hover:bg-indigo-900 flex": true, //colors
                      "transition-colors duration-300": true, //animation
                      "rounded-md p-2 mx-3 gap-4 ": !collapsed,
                      "rounded-full p-2 m-auto w-10 h-10": collapsed,
                    })}
                  >
                    <Link
                      underline="none"
                      key={DashboardTab.LUNCH}
                      className="flex gap-2"
                    >
                      <FastfoodIcon className="text-white" />{" "}
                      <span className="text-white text-xl flex justify-center	items-center">
                        {!collapsed && "Lunch"}
                      </span>
                    </Link>
                  </li>
                </ListItemButton>
              </Box>
            )}
            {hasPermission(
              userCustomClaim,
              CompanyLevelPermissions.GET_FEEDBACK
            ) && (
              <Box
                className={`p-0 ${
                  selectedIndex === 7
                    ? "rounded-lg bg-blue-550 my-0	mx-2"
                    : "rounded-none bg-transparent"
                }`}
              >
                <ListItemButton
                  className="p-0"
                  selected={selectedIndex === 7}
                  onClick={() => handleNavigateClick("feedback", 7)}
                >
                  <li
                    className={classNames({
                      "text-indigo-100 hover:bg-indigo-900 flex": true, //colors
                      "transition-colors duration-300": true, //animation
                      "rounded-md p-2 mx-3 gap-4 ": !collapsed,
                      "rounded-full p-2 m-auto w-10 h-10": collapsed,
                    })}
                  >
                    <Link
                      underline="none"
                      key={DashboardTab.FEEDBACK}
                      className="flex gap-2"
                    >
                      <FeedbackIcon className="text-white" />{" "}
                      <span className="text-white text-xl flex justify-center	items-center">
                        {!collapsed && "Feedbacks"}
                      </span>
                    </Link>
                  </li>
                </ListItemButton>
              </Box>
            )}
            {isAdmin(userCustomClaim) && (
              <Box
                className={`p-0 ${
                  selectedIndex === 8
                    ? "rounded-lg bg-blue-550 my-0	mx-2"
                    : "rounded-none bg-transparent"
                }`}
              >
                <ListItemButton
                  className="p-0"
                  selected={selectedIndex === 8}
                  onClick={() => handleNavigateClick("salary", 8)}
                >
                  <li
                    className={classNames({
                      "text-indigo-100 hover:bg-indigo-900 flex": true, //colors
                      "transition-colors duration-300": true, //animation
                      "rounded-md p-2 mx-3 gap-4 ": !collapsed,
                      "rounded-full p-2 m-auto w-10 h-10": collapsed,
                    })}
                  >
                    <Link
                      underline="none"
                      key={DashboardTab.SALARY}
                      className="flex gap-2"
                    >
                      <MonetizationOnOutlinedIcon className="text-white" />{" "}
                      <span className="text-white text-xl flex justify-center	items-center">
                        {!collapsed && "Salary"}
                      </span>
                    </Link>
                  </li>
                </ListItemButton>
              </Box>
            )}

            <Box
              className={`p-0 ${
                selectedIndex === 9
                  ? "rounded-lg bg-blue-550 my-0	mx-2"
                  : "rounded-none bg-transparent"
              }`}
            >
              <ListItemButton
                className="p-0"
                selected={selectedIndex === 9}
                onClick={() => handleNavigateClick("videos", 9)}
              >
                <li
                  className={classNames({
                    "text-indigo-100 hover:bg-indigo-900 flex": true, //colors
                    "transition-colors duration-300": true, //animation
                    "rounded-md p-2 mx-3 gap-4 ": !collapsed,
                    "rounded-full p-2 m-auto w-10 h-10": collapsed,
                  })}
                >
                  <Link
                    underline="none"
                    key={DashboardTab.VIDEOS}
                    className="flex gap-2"
                  >
                    <VideocamIcon className="text-white" />{" "}
                    <span className="text-white text-xl flex justify-center	items-center">
                      {!collapsed && "Videos"}
                    </span>
                  </Link>
                </li>
              </ListItemButton>
            </Box>
            {hasPermission(
              userCustomClaim,
              CompanyLevelPermissions.GET_COMPANY
            ) && (
              <Box
                className={`p-0 ${
                  selectedIndex === 10
                    ? "rounded-lg bg-blue-550 my-0	mx-2"
                    : "rounded-none bg-transparent"
                }`}
              >
                <ListItemButton
                  className="p-0"
                  selected={selectedIndex === 10}
                  onClick={() => handleNavigateClick("analytics", 10)}
                >
                  <li
                    className={classNames({
                      "text-indigo-100 hover:bg-indigo-900 flex": true, //colors
                      "transition-colors duration-300": true, //animation
                      "rounded-md p-2 mx-3 gap-4 ": !collapsed,
                      "rounded-full p-2 m-auto w-10 h-10": collapsed,
                    })}
                  >
                    <Link
                      underline="none"
                      key={DashboardTab.ANALYTICS}
                      className="flex gap-2"
                    >
                      <AssessmentOutlinedIcon className="text-white" />{" "}
                      <span className="text-white text-xl flex justify-center	items-center">
                        {!collapsed && "Analytics"}
                      </span>
                    </Link>
                  </li>
                </ListItemButton>
              </Box>
            )}
          </ul>
        </nav>
        {/* <div
          className={classNames({
            "grid place-content-stretch p-4 ": true,
          })}
        >
          <div className="flex gap-4 items-center h-11 overflow-hidden">
            <img
              src={
                "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              }
              height={36}
              width={36}
              alt="profile image"
              className="rounded-full"
            />
            {!collapsed && (
              <div className="flex flex-col ">
                <span className="text-indigo-50 my-0">Tom Cook</span>
                <Link href="/" className="text-indigo-200 text-sm">
                  View Profile
                </Link>
              </div>
            )}
          </div>
        </div> */}
      </div>
    </div>
  );
};
export default SidebarTW;

export enum CompanyLevelPermissions {
  // BY DEFAULT GIVEN TO EVERYONE with only that companyId acessible - Company Tab will only be visible on frontend
  GET_COMPANY = "GET_COMPANY",

  CREATE_COMPANY_USERS = "CREATE_COMPANY_USERS",
  DELETE_COMPANY_USERS = "DELETE_COMPANY_USERS",

  // BY DEFAULT GIVEN TO EVERYONE with only that companyId acessible - Project Tab will only be visible on frontend
  GET_COMPANY_PROJECTS = "GET_COMPANY_PROJECTS",
  //Permissions for PROJECT Functionality
  CREATE_PROJECTS = "CREATE_PROJECTS",
  UPDATE_PROJECTS = "UPDATE_PROJECTS",
  DELETE_PROJECTS = "DELETE_PROJECTS",

  //Permission for USER-PROJECT Functionality
  // BY DEFAULT GIVEN TO EVERYONE with only that companyId acessible - Main Members Tab OR Project Members TAb will only be visible on frontend
  GET_COMPANY_USERS = "GET_COMPANY_USERS",
  CREATE_USER_PROJECT = "CREATE_USER_PROJECT",
  UPDATE_USER_PROJECT = "UPDATE_USER_PROJECT",
  DELETE_USER_PROJECT = "DELETE_USER_PROJECT",

  //Permission for WORKLOGS Functionality For COMPANY ADMIN
  // BY DEFAULT GIVEN TO EVERYONE with only that companyId acessible - Worklog Tab will only be visible on frontend
  CREATE_WORKLOG = "CREATE_WORKLOG",
  GET_COMPANY_USERS_WORKLOG = "GET_COMPANY_USERS_WORKLOG",

  //Permissions for USER Functionality - only his self profile
  UPDATE_USER = "UPDATE_USER",
  GET_USER = "GET_USER",

  //Permission for USER-PROJECT Functionality
  GET_USER_PROJECT = "GET_USER_PROJECT",

  //Permission for WORKLOGS Functionality For self
  UPDATE_WORKLOG = "UPDATE_WORKLOG",
  GET_WORKLOG = "GET_WORKLOG",
  DELETE_WORKLOG = "DELETE_WORKLOG",

  //Permissions for Holiday
  CREATE_HOLIDAY = "CREATE_HOLIDAY",
  UPDATE_HOLIDAY = "UPDATE_HOLIDAY",
  GET_HOLIDAY = "GET_HOLIDAY",
  DELETE_HOLIDAY = "DELETE_HOLIDAY",

  //Permissions for Attendance
  UPDATE_ATTENDANCE = "UPDATE_ATTENDANCE",
  GET_ATTENDANCE = "GET_ATTENDANCE",
  DELETE_ATTENDANCE = "DELETE_ATTENDANCE",
  GET_USER_ATTENDANCE = "GET_USER_ATTENDANCE",

  //Permissions for Groups
  CREATE_GROUP = "CREATE_GROUP",
  UPDATE_GROUP = "UPDATE_GROUP",
  GET_GROUP = "GET_GROUP",
  DELETE_GROUP = "DELETE_GROUP",

  //Permissions for editing next Allocation
  CREATE_NEXT_ALLOCATION = "CREATE_NEXT_ALLOCATION",
  UPDATE_NEXT_ALLOCATION = "UPDATE_NEXT_ALLOCATION",
  GET_NEXT_ALLOCATION = "GET_NEXT_ALLOCATION",

  //Permissions for Getting allocation for self
  GET_ALLOCATION = "GET_ALLOCATION",
  //Permissions for FoodOrder
  UPDATE_FOOD_ORDER = "UPDATE_FOOD_ORDER",
  GET_FOOD_ORDER = "GET_FOOD_ORDER",
  //Permission for updating FoodOrder Functionality For self
  GET_USER_FOOD_ORDER = "GET_USER_FOOD_ORDER",

  //Permissions for LunchMenu
  CREATE_LUNCH_MENU = "CREATE_LUNCH_MENU",
  UPDATE_LUNCH_MENU = "UPDATE_LUNCH_MENU",
  GET_LUNCH_MENU = "GET_LUNCH_MENU",

  //Permissions for Feedback
  CREATE_FEEDBACK = "CREATE_FEEDBACK",
  GET_FEEDBACK = "GET_FEEDBACK",
}

export enum ProjectLevelPermissions {
  //Permission for USER-PROJECT Functionality
  GET_PROJECT_USERS = "GET_PROJECT_USERS",

  //Permission for WORKLOGS Functionality For PROJECT MANAGER
  GET_PROJECT_MEMBER_WORKLOG = "GET_PROJECT_MEMBER_WORKLOG",

  GET_COMPANY_USERS = "GET_COMPANY_USERS",
}

export enum AllUserProjectPermissions {
  ProjectLevelPermissions,
  UserLevelPermissions,
}

export enum AllUserPermissions {
  SuperAdminPermissions,
  CompanyLevelPermissions,
  ProjectLevelPermissions,
  UserLevelPermissions,
}

import { useSelector } from "react-redux";
import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import { Tab, Tabs } from "@mui/material";

import { ReduxState } from "../../../redux/model/ReduxState.d";
import { UserCustomClaim } from "../../../redux/model/UserState.d";

import { hasPermission } from "../../../Common/Common";
import { CompanyLevelPermissions } from "../../../Common/Enums/permissions";

import DailyAttendance from "./AttendanceTypes/DailyAttendance/DailyAttendance";
import HolidayAttendanceList from "./AttendanceTypes/HolidayAttendance/HolidayAttendance";

import { useLogEvent } from "../Analytics/hooks/useLogEvent";
import "../Attendance/Attendance.scss";

export default function AttendanceTab() {
  useLogEvent("page_view", "Attendance");
  const navigate = useNavigate();
  const userCustomClaim = useSelector<ReduxState, UserCustomClaim>(
    (state: ReduxState) => state.UserReducer?.userPermission?.userCustomClaim
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(newValue);
  };

  return (
    <div>
      <Tabs
        value={location.pathname.split("/").pop()}
        aria-label="basic tabs example"
        onChange={handleTabChange}
        className="h-[60px]"
      >
        <Tab
          label="Daily Attendance"
          value="daily"
          iconPosition="start"
          icon={<CalendarMonthIcon />}
        />
        {hasPermission(
          userCustomClaim,
          CompanyLevelPermissions.GET_HOLIDAY
        ) && (
          <Tab
            label="Holiday Attendance"
            value="holiday"
            iconPosition="start"
            icon={<InsertInvitationIcon />}
          />
        )}
      </Tabs>
      <Outlet />
      <Routes>
        <Route path="daily" element={<DailyAttendance />} />
        <Route path="holiday" element={<HolidayAttendanceList />} />
        <Route path="/" element={<Navigate replace to="daily" />} />
      </Routes>
    </div>
  );
}

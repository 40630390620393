import { useEffect, useState } from "react";
import { Col, Dropdown, SplitButton } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../../apis/auth/auth-service";
import { getUserProjects } from "../../../apis/services/user-projects.service";
import {
  // defaultPic,
  getCompanyId,
  hasPermission,
  isAdmin,
  isCompanyAdmin,
  projectsAvailable,
} from "../../../Common/Common";
import { CompanyLevelPermissions } from "../../../Common/Enums/permissions";
import { DropdownItem, User } from "../../../Common/Interfaces/dashboard";
import {
  logout as projectLogout,
  selectGlobalProject,
} from "../../../redux/actions/ProjectActions";
import { logout } from "../../../redux/actions/UserActions";
import { ReduxState } from "../../../redux/model/ReduxState.d";
import { SelectedGlobalProject } from "../../../redux/model/SelectedProject.d";
import { UserCustomClaim } from "../../../redux/model/UserState.d";
import EditMemberModal from "./EditUserModel/EditUserModel";

export default function DashboardHeader() {
  const userCustomClaim = useSelector<ReduxState, UserCustomClaim>(
    (state: ReduxState) => state.UserReducer?.userPermission?.userCustomClaim
  );
  const userId = useSelector<ReduxState, string>(
    (state: ReduxState) => state.UserReducer.userId || ""
  );
  const userName = useSelector<ReduxState, string>(
    (state: ReduxState) => state.UserReducer.userName || ""
  );
  const [filtersLoading, setFiltersLoading] = useState(true);

  const [projects, setProjects] = useState([] as DropdownItem[]);
  const [selectedProject, setSelectedProject] = useState({} as DropdownItem);
  const [editMember, setEditMember] = useState({
    id: userId,
    userName: userName,
  } as User);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!showModal) {
      setEditMember({
        id: userId,
        userName: userName,
      } as User);
    }
  }, [showModal]);

  useEffect(() => {
    getProjects();
  }, [userCustomClaim]);

  const selectProject = (key: string) => {
    const proj = projects.find((x) => x.id === key) || ({} as DropdownItem);
    setSelectedProject(proj);
    dispatch(
      selectGlobalProject({
        projectName: proj.name,
        projectId: proj.id,
      } as SelectedGlobalProject)
    );
  };

  const handleClose = () => setShowModal(false);
  const handleShow = (isEdit: boolean, member: User) => {
    setShowModal(true);
    setEditMember(member);
  };

  const getProjects = async () => {
    const companyId = getCompanyId(userCustomClaim);
    if (
      hasPermission(
        userCustomClaim,
        CompanyLevelPermissions.GET_USER_PROJECT
      ) &&
      !isCompanyAdmin(userCustomClaim) &&
      companyId.length > 0 &&
      projectsAvailable(userCustomClaim)
    ) {
      setFiltersLoading(true);
      const fetchedProjects = await getUserProjects(companyId, userId);
      // we add the none option due non project work-log
      fetchedProjects.push({ id: "", name: "None" });
      setProjects(fetchedProjects);
      if (fetchedProjects.length > 0) {
        setSelectedProject(fetchedProjects[0]);
        dispatch(
          selectGlobalProject({
            projectName: fetchedProjects[0].name,
            projectId: fetchedProjects[0].id,
          } as SelectedGlobalProject)
        );
      }
      setFiltersLoading(false);
    }
  };
  const isProjectsDropDown =
    !isAdmin(userCustomClaim) &&
    !isCompanyAdmin(userCustomClaim) &&
    window.location.pathname.includes("/timelogs") &&
    projectsAvailable(userCustomClaim);
  return (
    <>
      <div className="my-auto mx-0">
        {isProjectsDropDown && (
          <>
            <Col sm={1} md={1} lg={1} xl={1} className="text-center px-2">
              {!filtersLoading && (
                <>
                  <h5 className="purple-color">Project: </h5>
                  <SplitButton
                    variant="default"
                    className="w3-container border-purple"
                    title={selectedProject.name ?? ""}
                    onSelect={(key) => selectProject(key ?? "")}
                  >
                    {projects?.map((project: DropdownItem) => {
                      return (
                        <Dropdown.Item key={project.id} eventKey={project.id}>
                          {project.name}
                        </Dropdown.Item>
                      );
                    })}
                  </SplitButton>
                </>
              )}
              {filtersLoading && <Skeleton height={35} width={180} />}
            </Col>
          </>
        )}
      </div>

      <Col sm={1} md={1} lg={1} xl={1}>
        <Dropdown>
          <Dropdown.Toggle
            id="dropdown-button-dark-example1"
            variant="outline-light"
          >
            {/* <img
              className="rounded-circle"
              src={defaultPic}
              alt="profile"
              data-holder-rendered="true"
              style={{ width: "10vh" }}
            ></img> */}
          </Dropdown.Toggle>

          <Dropdown.Menu variant="light">
            <Dropdown.Header className="purple-color fw-bold text-center">
              {" "}
              {userName}{" "}
            </Dropdown.Header>
            <Dropdown.Divider />
            <Dropdown.Item onClick={() => handleShow(true, editMember)}>
              <i className="bi bi-person-circle"></i>
              &emsp;My Profile
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                logOut();
                dispatch(logout());
                dispatch(projectLogout());
              }}
            >
              <i className="bi bi-box-arrow-right"></i>
              &emsp;Log Out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>

      {showModal && (
        <EditMemberModal
          showModal={showModal}
          handleClose={handleClose}
          editUser={editMember}
        />
      )}
    </>
  );
}

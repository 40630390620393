import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { useEffect, useState } from "react";
import { Col, Dropdown, Modal, Row, SplitButton } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getAllProjectsList } from "../../../../../apis/services/projects.service";
import {
  getProjectMembersAndRoles,
  getUserProjects,
} from "../../../../../apis/services/user-projects.service";
import {
  postWorklog,
  updateWorklog,
} from "../../../../../apis/services/worklogs.service";
import {
  enumStringToText,
  //getProjectPermissions,
  hasPermission,
  isAdmin,
  isSuccessCode,
} from "../../../../../Common/Common";
import { Billable, TicketType } from "../../../../../Common/Enums/dashboard";
import {
  CompanyLevelPermissions,
  //ProjectLevelPermissions,
} from "../../../../../Common/Enums/permissions";
import {
  AddEditWorklogModalProps,
  DropdownItem,
  MemberRole,
  ReactSelect,
  Worklog,
} from "../../../../../Common/Interfaces/dashboard";
import { ReduxState } from "../../../../../redux/model/ReduxState.d";
import { UserCustomClaim } from "../../../../../redux/model/UserState.d";
import Button from "../../../../Common/Button/Button";
import AppDropdown from "../../../../Common/Dropdown/Dropdown";
import { AddEditTimeLogSchema } from "../../../../../Common/ValidationSchema/AddEditTimeLogSchema";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Select from "react-select";

type ValidationSchema = z.infer<typeof AddEditTimeLogSchema>;

export default function AddWorklogModal(props: AddEditWorklogModalProps) {
  const [worklogId] = useState(props.editWorkLog?.id || "");
  const [members, setMembers] = useState([] as MemberRole[]);
  const [selectedMember, setSelectedMember] = useState({} as DropdownItem);
  const [projects, setProjects] = useState([] as ReactSelect[]);
  const [ticketType, setTicketType] = useState<string>(
    props.editWorkLog?.ticketType || TicketType.TASK
  );
  const [billable, setBillable] = useState<string>(
    props.editWorkLog?.billable ? Billable.YES : Billable.NO
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit] = useState(
    props.editWorkLog &&
      props.editWorkLog?.id &&
      props.editWorkLog?.id.length > 0
      ? true
      : false
  );
  const [isSubmitted, setIsSubmitted] = useState(false);
  const userCustomClaim = useSelector<ReduxState, UserCustomClaim>(
    (state: ReduxState) => state.UserReducer?.userPermission?.userCustomClaim
  );

  const userId = useSelector<ReduxState, string>(
    (state: ReduxState) => state.UserReducer.userId || ""
  );
  const userName = useSelector<ReduxState, string>(
    (state: ReduxState) => state.UserReducer.userName || ""
  );
  const billables = [
    { id: Billable.YES, name: enumStringToText(Billable.YES) },
    { id: Billable.NO, name: enumStringToText(Billable.NO) },
  ];
  const ticketTypes = [
    { id: TicketType.BUG, name: enumStringToText(TicketType.BUG) },
    { id: TicketType.TASK, name: enumStringToText(TicketType.TASK) },
  ];
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
    getValues,
  } = useForm<ValidationSchema>({
    defaultValues: {
      projectName: [
        {
          value: props.editWorkLog?.projectId || "",
          label: props.editWorkLog?.projectName || "Select Project",
        },
      ],
      ticketUrl: props.editWorkLog?.ticketUrl || "",
      ticketDate: new Date(props.editWorkLog?.date) || new Date(),
      workLogMinutes: props?.editWorkLog?.worklogMinutes || 0,
      ticketName: props.editWorkLog?.ticketName || "",
      ticketDescription: props.editWorkLog?.ticketDescription || "",
    },
    resolver: zodResolver(AddEditTimeLogSchema),
    mode: "all",
  });
  useEffect(() => {
    if (props.selectedCompany.id && props.selectedCompany.id.length > 0) {
      getProjects();
    }
  }, [props.selectedCompany]);

  useEffect(() => {
    const selectedProject = getValues().projectName;
    if (selectedProject[0].value && selectedProject[0].value.length > 0) {
      getMembers();
    }
  }, [getValues().projectName[0].value]);

  const getMembers = async () => {
    if (props.selectedCompany && props.selectedCompany.id) {
      setSelectedMember(
        ({
          id: props.editWorkLog?.userId,
          name: props.editWorkLog?.userName,
        } as DropdownItem) || ({} as DropdownItem)
      );
      setIsLoading(true);
      const fetchedUsers = await getProjectMembersAndRoles(
        props.selectedCompany.id,
        "",
        getValues().projectName[0].value ?? ""
      );
      setMembers(fetchedUsers);
      setIsLoading(false);
    }
  };

  const getProjects = async () => {
    if (props.selectedCompany && props.selectedCompany.id) {
      let fetchedProjects = [] as DropdownItem[];
      if (
        hasPermission(
          userCustomClaim,
          CompanyLevelPermissions.GET_COMPANY_PROJECTS
        )
      ) {
        fetchedProjects = await getAllProjectsList(props.selectedCompany.id);
      } else if (
        hasPermission(userCustomClaim, CompanyLevelPermissions.GET_USER_PROJECT)
      ) {
        fetchedProjects = await getUserProjects(
          props.selectedCompany.id,
          userId
        );
      }
      const projectsList = fetchedProjects.map(
        (projects) =>
          ({ value: projects.id, label: projects.name } as ReactSelect)
      );
      setProjects(projectsList);
    }
  };

  const selectMember = (member: MemberRole) => {
    setSelectedMember({
      id: member.userId,
      name: member.userName,
    });
  };

  const saveWorklog = async (data: any) => {
    const projectData = data.projectName[0];
    const workLog: Worklog = {
      projectId: projectData.value || "",
      projectName: projectData.label || "",
      userId: selectedMember.id || userId || "",
      userName: selectedMember.name || userName || "",
      date: data.ticketDate,
      ticketType: ticketType,
      worklogMinutes: data.workLogMinutes,
      ticketName: data.ticketName,
      ticketDescription: data.ticketDescription,
      ticketUrl: data.ticketUrl,
      billable: billable === Billable.YES ? true : false,
    };

    if (!isEdit) {
      const res = await postWorklog(props.selectedCompany.id, workLog);
      if (res && isSuccessCode(res?.status)) {
        workLog.id = res.data.id;
        props.addWorklog(workLog);
      }
    } else {
      const res = await updateWorklog(
        props.selectedCompany.id,
        worklogId,
        workLog
      );
      if (res && isSuccessCode(res?.status)) {
        props.addWorklog(workLog, true);
      }
    }
  };

  const isFormValid = () => {
    if (isAuthorizedForProjectMembers()) {
      return selectedMember.id ? true : false;
    } else {
      return true;
    }
  };

  const handleSave = (data: any) => {
    setIsSubmitted(true);
    if (isFormValid()) {
      saveWorklog(data);
    }
  };

  const isAuthorizedForProjectMembers = () => {
    if (isAdmin(userCustomClaim)) {
      return true;
    }
    return false;
  };

  return (
    <Modal
      size="lg"
      show={props.showModal}
      onHide={() => props.handleClose(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>{isEdit ? "Edit Worklog" : "Add Worklog"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(handleSave)}>
          <Row>
            <Col>
              <label className="fw-bold">Project Name</label>
              <Controller
                control={control}
                name="projectName"
                render={({ field }) => {
                  console.log({ field });
                  return (
                    <Select
                      className="w-48"
                      defaultValue={field.value}
                      options={projects}
                      isMulti={false}
                      onChange={(e) => {
                        field.onChange([e]);
                      }}
                      isSearchable={false}
                    />
                  );
                }}
              />
              <p className="mt-1 ml-3 text-xs text-red-600">
                {errors?.projectName?.message}
              </p>
            </Col>
          </Row>
          <Row className="my-3">
            {isAuthorizedForProjectMembers() && (
              <Col>
                <label className="fw-bold">Member Name</label>
                <SplitButton
                  variant="default"
                  className="w3-container border-purple mb-2"
                  title={selectedMember.name || "Select Member"}
                  onSelect={(key) =>
                    selectMember(members.filter((x) => x.userId === key)[0])
                  }
                >
                  {members &&
                    members.map((member: MemberRole) => {
                      return (
                        <Dropdown.Item
                          key={member.userId}
                          eventKey={member.userId}
                        >
                          {member.userName}
                        </Dropdown.Item>
                      );
                    })}
                </SplitButton>
                <div
                  className={`text-danger ${
                    (!selectedMember.id ||
                      getValues().projectName[0].value.length === 0) &&
                    isSubmitted
                      ? ""
                      : "d-none"
                  }`}
                >
                  <small>Member Name is required</small>
                </div>
              </Col>
            )}

            <Col>
              <label className="fw-bold">Ticket URL</label>
              <input
                type="text"
                id={"ticketUrl"}
                className={`form-control rounded-pill ${
                  errors?.ticketUrl && "is-invalid"
                }`}
                {...register("ticketUrl")}
              />
              <p className="mt-1 ml-3 text-xs text-red-600">
                {errors?.ticketUrl?.message}
              </p>
            </Col>
          </Row>
          <Row className="my-3">
            <Col>
              <label className="fw-bold">Date</label>
              <Controller
                control={control}
                name="ticketDate"
                render={({ field }) => (
                  <DatePickerComponent
                    placeholder="Choose a date"
                    value={field.value}
                    format="dd-MMM-yy"
                    onChange={(e: any) => field.onChange(e)}
                  ></DatePickerComponent>
                )}
              />
              <p className="mt-1 ml-3 text-xs text-red-600">
                {errors?.ticketDate?.message}
              </p>
            </Col>
          </Row>
          <Row className="my-3">
            <Col>
              <label className="fw-bold">Time (minutes)</label>
              <input
                type="number"
                id={"workLogMinutes"}
                className={`form-control rounded-pill ${
                  errors?.workLogMinutes && "is-invalid"
                }`}
                {...register("workLogMinutes")}
              />
              <p className="mt-1 ml-3 text-xs text-red-600">
                {errors?.workLogMinutes?.message}
              </p>
            </Col>
            <Col>
              <label className="fw-bold">Billable</label>
              <AppDropdown
                title={billable}
                setValue={setBillable}
                list={billables}
                saveObject={false}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="fw-bold">Ticket Name</label>
              <input
                type="text"
                id={"ticketName"}
                className={`form-control rounded-pill ${
                  errors?.ticketName && "is-invalid"
                }`}
                {...register("ticketName")}
              />
              <p className="mt-1 ml-3 text-xs text-red-600">
                {errors?.ticketName?.message}
              </p>
            </Col>
            <Col>
              <label className="fw-bold">Ticket Type</label>
              <AppDropdown
                title={ticketType}
                setValue={setTicketType}
                list={ticketTypes}
                saveObject={false}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="fw-bold">Description</label>
              <textarea
                className={`form-control row-3 ${
                  errors.ticketDescription ? "is-invalid" : ""
                }`}
                {...register("ticketDescription")}
              />
              <p className="mt-1 ml-3 text-xs text-red-600">
                {errors?.ticketDescription?.message}
              </p>
            </Col>
          </Row>
          <Button
            type="submit"
            text={isEdit ? "Update Worklog" : "Save Worklog"}
            disabled={!isValid || isSubmitted}
            additionalClasses={`h-10 mt-3 bg-blue-600 text-white w-full mb-2 ${
              (!isValid || isSubmitted) && "opacity-25 cursor-not-allowed"
            }`}
          />
          <Button
            onClick={() => props.handleClose(false)}
            text="Close"
            additionalClasses={"h-10 mt-3 bg-gray-600 text-white w-full mb-2"}
          />
        </form>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

import { AxiosResponse } from "axios";
import { Attendance } from "../../Common/Interfaces/Attendance/attendance";
import { getData, updateData } from "../axios-calls/common-axios-service";

export const getDailyAttendance = async (
  companyId: string,
  attendanceStartingDate: string,
  attendanceEndingDate: string,
  userId = ""
): Promise<Attendance[]> => {
  const companiesInfo = await getData(
    `companies/${companyId}/attendance?attendanceStartingDate=${attendanceStartingDate}&attendanceEndingDate=${attendanceEndingDate}&userId=${userId}`
  );
  return companiesInfo[0];
};

export const updateDailyAttendance = async (
  companyId: string,
  attendanceId: string,
  status: string
): Promise<AxiosResponse> => {
  const res = await updateData(
    `companies/${companyId}/attendance/${attendanceId}`,
    { status: status }
  );
  return res as AxiosResponse;
};

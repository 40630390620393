import { Tab, Tabs } from "@mui/material";
import { useSelector } from "react-redux";
import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import { hasPermission } from "../../../Common/Common";
import { CompanyLevelPermissions } from "../../../Common/Enums/permissions";
import { ReduxState } from "../../../redux/model/ReduxState.d";
import { UserCustomClaim } from "../../../redux/model/UserState.d";
// import CalendarTab from "./CalendarTab.ts/CalendarTab";
// import NextAllocationTab from "./NextAllocationTab.ts/NextAllocationTab";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import EditCalendarOutlinedIcon from "@mui/icons-material/EditCalendarOutlined";
import { useLogEvent } from "../Analytics/hooks/useLogEvent";
import CalendarTabNew from "./CalendarTab.ts/CalendarTabNew";
import NextAllocationTabNew from "./NextAllocationTab.ts/NextAllocationTabNew";

export default function AllocationTab() {
  const navigate = useNavigate();
  const userCustomClaim = useSelector<ReduxState, UserCustomClaim>(
    (state: ReduxState) => state.UserReducer?.userPermission?.userCustomClaim
  );

  const handleTabChange = (event: React.SyntheticEvent, tabValue: string) => {
    navigate(tabValue);
  };
  useLogEvent("page_view", "Allocations");

  return (
    <>
      <Tabs
        value={location.pathname.split("/").pop()}
        aria-label="basic tabs example"
        onChange={handleTabChange}
        className="h-[60px]"
      >
        {hasPermission(
          userCustomClaim,
          CompanyLevelPermissions.GET_ALLOCATION
        ) && (
          <Tab
            label="Calendar"
            value="calendar"
            iconPosition="start"
            icon={<CalendarMonthOutlinedIcon />}
          />
        )}
        {hasPermission(
          userCustomClaim,
          CompanyLevelPermissions.GET_NEXT_ALLOCATION
        ) && (
          <Tab
            label="Next Allocation"
            value="nextAllocation"
            iconPosition="start"
            icon={<EditCalendarOutlinedIcon />}
          />
        )}
      </Tabs>
      <Outlet />
      <Routes>
        <Route path="calendar" element={<CalendarTabNew />} />
        <Route path="nextAllocation" element={<NextAllocationTabNew />} />
        <Route path="/" element={<Navigate replace to="calendar" />} />
      </Routes>
    </>
  );
}

/* eslint-disable @typescript-eslint/ban-types */
import { postFeedback } from "../../apis/services/feedback.service";
import store from "../../redux/store/store";
import { enumStringToText, getCompanyId } from "../Common";
import { FeedbackType } from "../Enums/feedback-type";
import { ActionProviderState } from "../Interfaces/Feedback/action-provider-state";
import { Feedback } from "../Interfaces/Feedback/feedback";

class ActionProvider {
  createChatBotMessage: Function;
  setState: Function;
  createClientMessage: Function;
  stateRef: ActionProviderState;
  constructor(
    createChatBotMessage: Function,
    setStateFunc: Function,
    createClientMessage: Function,
    stateRef: ActionProviderState
  ) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
    this.createClientMessage = createClientMessage;
    this.stateRef = stateRef;
  }

  addFeedbackType = (type: FeedbackType): void => {
    const messages = this.createChatBotMessage(
      `Please type your feedback/complaint. Please make sure that your feedback would be shared as ${enumStringToText(
        type
      )}`
    );

    this.setState((state: any) => ({
      ...state,
      feedback: {
        companyId: "",
        userId: "",
        userName: "",
        date: new Date(),
        feedback: "",
        type: type,
      } as Feedback,
    }));

    this.addMessageToBotState(messages);
  };

  addFeedback = (message: string): void => {
    const messages = this.createChatBotMessage(
      `Thankyou for sharing your feedback. We will try our best to help you!
      Please select the type of feedback in case you have any other feedback.`,
      {
        widget: "options",
      }
    );
    this.setState((state: any) => ({
      ...state,
      feedback: { ...state.feedback, feedback: message },
    }));

    const reduxState = store.getState();
    const userCustomClaim =
      reduxState.UserReducer.userPermission.userCustomClaim;
    const userId = reduxState.UserReducer.userId;
    const userName = reduxState.UserReducer.userName;
    const currentDate = new Date();

    const feedbackBody = {
      companyId: getCompanyId(userCustomClaim),
      userId: "",
      userName: "",
      date: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        0,
        0,
        0
      ),
      feedback: message,
    } as Feedback;

    if (this.stateRef.feedback.type === FeedbackType.NON_ANONYMOUS) {
      feedbackBody.userId = userId;
      feedbackBody.userName = userName;
    }
    postFeedback(feedbackBody.companyId, feedbackBody);
    this.addMessageToBotState(messages);
  };

  addMessageToBotState = (messages: any): void => {
    this.setState((state: { messages: any }) => ({
      ...state,
      messages: [...state.messages, messages],
    }));
  };
}

export default ActionProvider;

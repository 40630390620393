import { useTable } from "react-table";
import { useSelector } from "react-redux";
import { ReduxState } from "../../../redux/model/ReduxState.d";
import { UserCustomClaim } from "../../../redux/model/UserState.d";
import { CompanyLevelPermissions } from "../../../Common/Enums/permissions";
import { TableData } from "../../../Common/Interfaces/table";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { IconButton } from "@mui/material";

export default function Table({
  columns,
  data,
  getTdProps,
  handleEdit,
  updateAuthorized,
  handleEditRole,
  handleDelete,
  userId,
}: any) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });
  const userCustomClaim = useSelector<ReduxState, UserCustomClaim>(
    (state: ReduxState) => state.UserReducer?.userPermission?.userCustomClaim
  );
  const editDeleteIcons = (row: any, isHandleEdit: boolean) => {
    return (
      <>
        <IconButton
          aria-label="Edit"
          color="primary"
          onClick={() =>
            isHandleEdit ? handleEdit(true, row) : handleEditRole(row)
          }
        >
          <EditIcon fontSize="medium" />
        </IconButton>
        <IconButton
          aria-label="Delete"
          color="error"
          onClick={() => handleDelete(row)}
        >
          <DeleteIcon fontSize="medium" />
        </IconButton>
      </>
    );
  };
  return (
    <div className="table-responsive">
      <table
        style={{ fontSize: "1em" }}
        className="table table-striped mt-2"
        {...getTableProps()}
      >
        <thead className="purple-color thead-dark">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
              {handleEdit && <th>Actions</th>}
              {getTdProps && <th>Info</th>}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="clickable-row">
                {row.cells.map((cell) => {
                  return (
                    <td style={{ fontSize: "1em" }} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
                {handleEditRole &&
                userCustomClaim.isAdmin &&
                (row.original as TableData)?.userCompanyPermissions.includes(
                  CompanyLevelPermissions.DELETE_COMPANY_USERS
                ) ? (
                  <td className="text-center">
                    {editDeleteIcons(row.original, false)}
                  </td>
                ) : (
                  handleEditRole &&
                  userCustomClaim.isAdmin && (
                    <td className="text-center">
                      <i></i>
                    </td>
                  )
                )}
                {handleEditRole && !userCustomClaim.isAdmin && (
                  <td className="text-center">
                    {editDeleteIcons(row.original, false)}
                  </td>
                )}
                {handleEdit &&
                  (updateAuthorized ||
                    (row.original as any)?.userId === userId) && (
                    <td className="px-3">
                      {editDeleteIcons(row.original, true)}
                    </td>
                  )}
                {getTdProps && (
                  <td className="px-4">
                    <InfoOutlinedIcon
                      fontSize="medium"
                      onClick={() =>
                        getTdProps ? getTdProps(row.original) : null
                      }
                    />
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

import { AxiosResponse } from "axios";
import { Billable } from "../../Common/Enums/dashboard";
import { Worklog, WorklogCSV } from "../../Common/Interfaces/dashboard";
import {
  deleteData,
  getData,
  postData,
  updateData,
} from "../axios-calls/common-axios-service";

export const getAllWorkLogs = async (
  companyId: string,
  startDate: string,
  endDate: string,
  billable: string,
  memberId = "",
  projectId = "",
  startAt: string,
  endBefore: string
): Promise<Worklog[]> => {
  const fetchedWorklogs = await getData(
    `companies/${companyId}/worklog?userId=${memberId}&projectId=${projectId}&date=${startDate}&endingDate=${endDate}&billable=${
      billable === Billable.YES ? true : billable === Billable.NO ? false : ""
    }&startAt=${startAt}&endBefore=${endBefore}`
  );
  return fetchedWorklogs;
};

export const postWorklog = async (
  companyId: string,
  workLog: Worklog
): Promise<AxiosResponse> => {
  const res = await postData(`companies/${companyId}/worklog`, workLog);
  return res as AxiosResponse;
};

export const updateWorklog = async (
  companyId: string,
  worklogId: string,
  workLog: Worklog
): Promise<AxiosResponse> => {
  const res = await updateData(
    `companies/${companyId}/worklog/${worklogId}`,
    workLog
  );
  return res as AxiosResponse;
};

export const deleteWorklog = async (
  companyId: string,
  worklogId: string
): Promise<AxiosResponse> => {
  const res = await deleteData(`companies/${companyId}/worklog/${worklogId}`);
  return res as AxiosResponse;
};

export const getAllWorkLogsForCsv = async (
  companyId: string,
  startDate: Date,
  endDate: Date,
  billable: string,
  memberId = "",
  projectId = ""
): Promise<WorklogCSV[]> => {
  const fetchedWorklogs = await getData(
    `companies/${companyId}/worklog/list?userId=${memberId}&projectId=${projectId}&date=${startDate.toISOString()}&endingDate=${endDate.toISOString()}&billable=${
      billable === Billable.YES ? true : billable === Billable.NO ? false : ""
    }`
  );
  return fetchedWorklogs;
};

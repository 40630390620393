import axios from "axios";
import { apiUrl } from "../../Common/Common";
import { getToken } from "../auth/auth-service";
import { setLoader } from "../../redux/actions/LoaderActions";
const axiosClient = axios.create({});
axiosClient.defaults.baseURL = apiUrl;

const SetupInterceptors = (store: any) => {
  // Add a request interceptor
  axiosClient.interceptors.request.use(
    async (config: any) => {
      if (!store.getState().LoaderReducer.isLoading) {
        store.dispatch(setLoader({ isLoading: true }));
      }
      const jwt = await getToken();

      config.headers.Authorization = jwt ? `Bearer ${jwt}` : "";

      return config;
    },
    (error) => {
      store.dispatch(setLoader({ isLoading: false }));
      return error;
    }
  );
  axiosClient.interceptors.response.use(
    (response) => {
      store.dispatch(setLoader({ isLoading: false }));
      return response;
    },
    (error) => {
      store.dispatch(setLoader({ isLoading: false }));

      return Promise.reject(error);
    }
  );
};

export { SetupInterceptors, axiosClient };

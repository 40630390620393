import { useEffect, useState } from "react";

import Select from "react-select";
import { useSelector } from "react-redux";

import { Grid, IconButton } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

import { ReduxState } from "../../../../redux/model/ReduxState.d";
import { UserCustomClaim } from "../../../../redux/model/UserState.d";

import Alert from "../../../Common/Alert/Alert";
import Loader from "../../../Common/Loader/Loader";
import BasicTable from "../../../Common/Table/BasicTable";
import { MaxPageData } from "../../../../Common/Enums/pagination";
import { Video } from "../../../../Common/Interfaces/Videos/my-videos";
import { getUserProjects } from "../../../../apis/services/user-projects.service";
import { ProjectsListOptions } from "../../../../Common/Interfaces/Videos/projects-list-options";
import { VideoUploadShareOptions } from "../../../../Common/Enums/Lunch/video-upload-share-options";

import {
  getSharedVideosByCompanyId,
  getSharedVideosByProjectId,
  getSharedVideosByUserId,
} from "../../../../apis/services/videos.service";

import { COLUMNS } from "./my-video-data-columns";

export default function SharedWithMe() {
  const userCustomClaim = useSelector<ReduxState, UserCustomClaim>(
    (state: ReduxState) => state.UserReducer?.userPermission?.userCustomClaim
  );
  const userId = useSelector<ReduxState, string>(
    (state: ReduxState) => state.UserReducer.userId || ""
  );

  const [selectOption, setSelectOption] = useState(
    VideoUploadShareOptions.COMPANY
  );

  const selectOptionsList = [
    {
      value: VideoUploadShareOptions.COMPANY,
      label: "Company",
    },
    {
      value: VideoUploadShareOptions.PROJECT,
      label: "Project",
    },
    {
      value: VideoUploadShareOptions.USERS,
      label: "User",
    },
  ] as ProjectsListOptions[];
  const [isLoading, setIsLoading] = useState(false);
  const [tableKey, setTableKey] = useState(0);
  const [videos, setVideos] = useState([] as Video[]);
  const [pageNumber, setPageNumber] = useState(0);
  const [prePageNumber, setPrePageNumber] = useState(0);

  const [isProjectListLoading, setIsProjectListLoading] = useState(false);
  const [projectsList, setProjectsList] = useState([] as ProjectsListOptions[]);

  const [isProjectSelected, setIsProjectSelected] = useState(false);
  const [selectedProject, setSelectedProject] = useState(
    {} as ProjectsListOptions
  );

  useEffect(() => {
    if (videos?.length) {
      setTableKey((prevKey) => prevKey + 1);
    }
  }, [videos]);

  useEffect(() => {
    if (userCustomClaim) {
      if (selectOption === VideoUploadShareOptions.PROJECT) {
        getSharedVideosByProject(selectedProject.value);
      }
    }
  }, [selectedProject, userCustomClaim]);

  useEffect(() => {
    if (userCustomClaim) {
      getProjectsList(userCustomClaim.companyId);
      if (selectOption === VideoUploadShareOptions.USERS) {
        setIsProjectSelected(false);
        getSharedVideosByUser();
      } else if (selectOption === VideoUploadShareOptions.PROJECT) {
        getSharedVideosByProject(projectsList[0]?.value);
        setIsProjectSelected(true);
      } else {
        setIsProjectSelected(false);
        getSharedVideosByCompany(userCustomClaim.companyId);
      }
    }
  }, [userCustomClaim, selectOption, pageNumber]);

  const getSharedVideosByCompany = async (
    companyId: string,
    startAfter = ""
  ) => {
    let endBefore = "";
    if (startAfter === "")
      if (pageNumber > prePageNumber)
        startAfter = videos[videos.length - 1].createdAt.toString() ?? "";
      else if (pageNumber < prePageNumber) {
        endBefore = videos[0].createdAt.toString() ?? "";
      }
    setIsLoading(true);
    const videosInfo = await getSharedVideosByCompanyId(
      companyId,
      startAfter,
      endBefore
    );
    if (videosInfo) {
      setVideos(videosInfo);
    }
    pageNumber === 1 && pageNumber < prePageNumber
      ? setPrePageNumber(0)
      : setPrePageNumber(pageNumber);
    setIsLoading(false);
  };

  const getSharedVideosByUser = async (startAfter = "") => {
    let endBefore = "";
    if (startAfter === "")
      if (pageNumber > prePageNumber)
        startAfter = videos[videos.length - 1].createdAt.toString() ?? "";
      else if (pageNumber < prePageNumber) {
        endBefore = videos[0].createdAt.toString() ?? "";
      }
    setIsLoading(true);
    const videosInfo = await getSharedVideosByUserId(startAfter, endBefore);
    if (videosInfo) {
      setVideos(videosInfo);
    }
    pageNumber === 1 && pageNumber < prePageNumber
      ? setPrePageNumber(0)
      : setPrePageNumber(pageNumber);
    setIsLoading(false);
  };

  const getSharedVideosByProject = async (
    projectId: string,
    startAfter = ""
  ) => {
    let endBefore = "";
    if (startAfter === "")
      if (pageNumber > prePageNumber)
        startAfter = videos[videos.length - 1].createdAt.toString() ?? "";
      else if (pageNumber < prePageNumber) {
        endBefore = videos[0].createdAt.toString() ?? "";
      }
    setIsLoading(true);
    const videosInfo = await getSharedVideosByProjectId(
      projectId,
      startAfter,
      endBefore
    );
    if (videosInfo) {
      setVideos(videosInfo);
    }
    pageNumber === 1 && pageNumber < prePageNumber
      ? setPrePageNumber(0)
      : setPrePageNumber(pageNumber);
    setIsLoading(false);
  };

  async function getProjectsList(companyId: string) {
    if (companyId.length !== 0) {
      setIsProjectListLoading(true);
      const projects = await getUserProjects(companyId, userId);
      const selectOptions = projects.map((project) => {
        return {
          value: project.id,
          label: project.name,
        };
      });
      setProjectsList(selectOptions);

      setIsProjectListLoading(false);
    }
  }

  const videosAvailable = (): boolean => {
    if (videos?.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const tableHooks = (hooks: any) => {
    hooks.visibleColumns.push((columns: any) => [...columns]);
  };

  return (
    <>
      <div className="mt-4">
        <Grid container className="flex items-center">
          <Grid item xs={6} className="justify-start">
            <Select
              className="w-72"
              options={selectOptionsList}
              isMulti={false}
              onChange={(e: any) => {
                setSelectOption(e.value);
              }}
              defaultValue={selectOptionsList[0]}
              required={true}
            />
          </Grid>

          <Grid item xs={6} className="flex justify-end">
            {isProjectSelected && projectsList.length > 0 && (
              <>
                <Select
                  className="w-72"
                  options={projectsList}
                  isMulti={false}
                  onChange={(e: any) => {
                    setSelectedProject({ value: e.value, label: e.label });
                  }}
                  defaultValue={projectsList[0]}
                  required={true}
                  isLoading={isProjectListLoading}
                />
              </>
            )}
            {isProjectSelected && projectsList.length == 0 && (
              <Loader active={isProjectListLoading}>
                <Alert
                  message={"You are not a part of any project currently"}
                />
              </Loader>
            )}
          </Grid>
        </Grid>

        {/* <Loader active={isLoading}> */}
        {!isLoading && !videosAvailable() && (
          <Alert message={"No Videos Available"} />
        )}

        <Grid container className="flex items-center mt-2">
          <Grid item xs={12} className="justify-center">
            {!isLoading && videosAvailable() && (
              <BasicTable
                tableColumns={COLUMNS}
                tableData={videos}
                headerColor="bg-gray-850"
                maxHeight={800}
                tableHooks={tableHooks}
                key={tableKey}
              />
            )}
          </Grid>
        </Grid>

        <Grid container className="flex items-center" justifyContent={"end"}>
          {!isLoading && videosAvailable() && (
            <div className="inline-flex float-right my-2">
              {pageNumber === 0 && (
                <span>
                  <IconButton
                    aria-label="left"
                    color="primary"
                    disabled={pageNumber === 0}
                  >
                    <NavigateBeforeIcon fontSize="large" />
                  </IconButton>
                </span>
              )}
              {pageNumber > 0 && (
                <span>
                  <IconButton aria-label="left" color="primary">
                    <NavigateBeforeIcon
                      fontSize="large"
                      onClick={() => {
                        setPageNumber((pageNumber) => pageNumber - 1);
                      }}
                    />
                  </IconButton>
                </span>
              )}
              {videos?.length !== MaxPageData.DATA_SIZE && (
                <span>
                  <IconButton aria-label="right" color="primary" disabled>
                    <NavigateNextIcon fontSize="large" />
                  </IconButton>
                </span>
              )}
              {videos?.length === MaxPageData.DATA_SIZE && (
                <span>
                  <IconButton aria-label="right" color="primary">
                    <NavigateNextIcon
                      fontSize="large"
                      onClick={() => {
                        setPageNumber((pageNumber) => pageNumber + 1);
                      }}
                    />
                  </IconButton>
                </span>
              )}
            </div>
          )}
        </Grid>
        {/* </Loader> */}
      </div>
    </>
  );
}

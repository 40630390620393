import { AxiosResponse } from "axios";
import { Feedback } from "../../Common/Interfaces/Feedback/feedback";
import { getData, postData } from "../axios-calls/common-axios-service";

export const postFeedback = async (
  companyId: string,
  feedback: Feedback
): Promise<AxiosResponse> => {
  const res = await postData(`companies/${companyId}/feedback`, feedback);
  return res as AxiosResponse;
};

export const getAllFeedbacks = async (
  companyId: string,
  startDate: Date,
  endDate: Date,
  startAfter: string,
  endBefore: string
): Promise<Feedback[]> => {
  const fetchedFeedbacks = await getData(
    `companies/${companyId}/feedback?startingDate=${startDate.toISOString()}&endingDate=${endDate.toISOString()}&startAfter=${startAfter}&endBefore=${endBefore}`
  );
  return fetchedFeedbacks;
};

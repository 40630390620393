import { zodResolver } from "@hookform/resolvers/zod";
import { Grid, InputLabel, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { spinner } from "../../Common/ButtonSpiner/ButtonSpiner";
import { CompanyLevelPermissions } from "../../Common/Enums/permissions";
import { ILogin } from "../../Common/Interfaces/Auth/ILogin";
import { LoginSchema } from "../../Common/ValidationSchema/LoginSchema";
import AuroraLogoFull from "../../Common/logo/AuroraLogoFull";
import { login, loginWithGoogle } from "../../apis/auth/auth-service";
import { analytics, auth, setUserId } from "../../config/firebase.includes";
import { loginSuccessfull } from "../../redux/actions/UserActions";
import { ReduxState } from "../../redux/model/ReduxState.d";
import { UserCustomClaim } from "../../redux/model/UserState.d";
import Button from "../Common/Button/Button";
import Divider from "../Common/Divider/Divider";
import ForgotPasswordModal from "./ForgotPasswordModal/ForgotPasswordModal";
import "./Login.scss";

type ValidationSchema = z.infer<typeof LoginSchema>;
export default function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ValidationSchema>({
    resolver: zodResolver(LoginSchema),
    mode: "all",
  });
  const userCustomClaim = useSelector<ReduxState, UserCustomClaim>(
    (state: ReduxState) => state.UserReducer?.userPermission?.userCustomClaim
  );
  const [showLoader, setShowLoader] = useState(false);

  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      userCustomClaim &&
      user?.providerData?.some((provider) => provider.providerId === "password")
    ) {
      // JUST GET THE USERCOMPANY PERMISSIONS LENGHT
      if (
        userCustomClaim?.isAdmin ||
        userCustomClaim?.companyPermissions?.length ===
          Object.keys(CompanyLevelPermissions).length ||
        userCustomClaim?.companyPermissions?.length == 0
      ) {
        setUserId(analytics, userCustomClaim.companyId);

        navigate("/dashboard/company");
      } else {
        navigate("/dashboard/timelogs");
      }
    } else if (user) {
      navigate("/dashboard/timelogs");
    }
  }, [userCustomClaim]);

  const signInWithGoogle = async () => {
    const loggedIn = await loginWithGoogle();
    if (loggedIn) {
      navigate("/dashboard/timelogs");
    }
  };

  const signIn = async (data: ILogin) => {
    const userEmail = data.email;
    const userPassword = data.password;
    setShowLoader(true);
    const isLogin = await login(userEmail, userPassword);
    setShowLoader(isLogin);
    dispatch(loginSuccessfull());
  };

  return (
    <Grid container spacing={0} className="bg-blue-1000 login-container">
      <Grid item xs={12} md={6}>
        <form onSubmit={handleSubmit(signIn)}>
          <div className="flex flex-col justify-center min-h-screen items-center">
            <div className="max-w-[500px] w-full p-4">
              <div className="max-w-[208px] mb-8">
                <AuroraLogoFull />
              </div>
              <div>
                <h2 className="mt-0 mb-4 text-4xl leading-7 font-plus-jakarta font-semibold text-white">
                  Sign in
                </h2>
                <Typography variant="subtitle1" className="text-white">
                  Enter your email and password to sign in
                </Typography>
              </div>
              <div className=" mt-4">
                <InputLabel htmlFor="email" className="text-white m-2">
                  Email
                </InputLabel>

                <input
                  type="text"
                  className={`form-control rounded-pill ${
                    errors?.email && "is-invalid"
                  }`}
                  id="email"
                  placeholder="Enter email"
                  {...register("email")}
                />
                <p className="mt-1 ml-3 text-xs text-red-600">
                  {errors?.email?.message}
                </p>
              </div>
              <div className=" mt-2">
                <InputLabel htmlFor="password" className="text-white m-2">
                  Password
                </InputLabel>

                <input
                  type="password"
                  className={`form-control rounded-pill ${
                    errors?.password && "is-invalid"
                  }`}
                  id="password"
                  placeholder="Enter password"
                  {...register("password")}
                />
                <p className="mt-1 ml-3 text-xs text-red-600">
                  {errors?.password?.message}
                </p>
              </div>
              <div className="mt-2 text-right">
                <a
                  className="no-underline text-white"
                  href="#"
                  onClick={() => setShowForgotPasswordModal(true)}
                >
                  Forgot password?
                </a>
              </div>
              <Button
                type="submit"
                icon={isValid && showLoader ? spinner() : null}
                disabled={!isValid || showLoader}
                text={"Sign in"}
                additionalClasses={`h-10 mt-3 bg-blue-600 text-white w-full mb-2 ${
                  (!isValid || showLoader) && "opacity-25 cursor-not-allowed"
                }`}
              />
              <Divider spacing={2}>or</Divider>

              <div className="text-center">
                <Button
                  onClick={signInWithGoogle}
                  type="button"
                  text={"Sign in with Google"}
                  icon={
                    <img
                      src={require("../../assets/images/icons/googleicon.png")}
                      alt="googleIcon"
                    />
                  }
                  additionalClasses={
                    "flex items-center text-blue-600 font-semibold py-2 px-4 rounded shadow  ml-32"
                  }
                />
              </div>
            </div>
          </div>
        </form>
        {showForgotPasswordModal && (
          <ForgotPasswordModal
            showModal={showForgotPasswordModal}
            handleClose={() => setShowForgotPasswordModal(false)}
          />
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <div className="h-full max[900px]:hidden  opacity-100	bg-transparent text-rgb-52-71-103 block">
          <div className="flex justify-center overflow-hidden flex-col	opacity-100 items-center text-center relative h-screen w-full	 bg-gradient-to-r from-blue-400 via-blue-400 to-blue-500 px-12">
            <img
              src={require("../../assets/images/sign-in.png")}
              alt="login-bg"
              loading="lazy"
              className="max-w-[120%]"
            />
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

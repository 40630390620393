import { useEffect, useState } from "react";

import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import Select from "react-select";

import moment from "moment";

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Grid, IconButton } from "@mui/material";

import { getCompanyId, isAdmin } from "../../../Common/Common";
import { Feedback } from "../../../Common/Interfaces/Feedback/feedback";
import {
  DropdownItem,
  ReactSelect,
} from "../../../Common/Interfaces/dashboard";
import Alert from "../../Common/Alert/Alert";
import BasicTable from "../../Common/Table/BasicTable";

import { getAllCompaniesList } from "../../../apis/services/company.service";
import { getAllFeedbacks } from "../../../apis/services/feedback.service";

import { ReduxState } from "../../../redux/model/ReduxState.d";
import { UserCustomClaim } from "../../../redux/model/UserState.d";

import { useLogEvent } from "../Analytics/hooks/useLogEvent";
import { COLUMNS } from "./dataColumns";

export default function FeedbackTab() {
  const [feedbacks, setFeedbacks] = useState([] as Feedback[]);
  const [companiesList, setCompaniesList] = useState([] as ReactSelect[]);
  const [selectedCompany, setSelectedCompany] = useState({} as DropdownItem);
  const [startDate, setStartDate] = useState<Date>(
    new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
  );
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [firstId, setFirstId] = useState("");
  const userCustomClaim = useSelector<ReduxState, UserCustomClaim>(
    (state: ReduxState) => state.UserReducer?.userPermission?.userCustomClaim
  );

  useEffect(() => {
    if (!isAdmin(userCustomClaim)) {
      setSelectedCompany({
        id: getCompanyId(userCustomClaim),
        name: "",
      } as DropdownItem);
    } else {
      if (isAdmin(userCustomClaim)) {
        getCompaniesList();
      }
    }
  }, [userCustomClaim]);
  useLogEvent("page_view", "Feedback");

  useEffect(() => {
    getFeedbacks();
  }, [selectedCompany, endDate]);

  const getFeedbacks = async (page = 0) => {
    let startAfter = "";
    let endBefore = "";
    if (page === 1) {
      startAfter = feedbacks[feedbacks.length - 1].id ?? "";
    } else if (page === -1) {
      endBefore = feedbacks[0].id ?? "";
    }
    if (selectedCompany && selectedCompany.id) {
      setIsLoading(true);
      const date = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
        0,
        0,
        0,
        0
      );
      const edate = new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate(),
        0,
        0,
        0,
        0
      );
      const fetchedFeedbacks = await getAllFeedbacks(
        selectedCompany.id,
        date,
        edate,
        startAfter,
        endBefore
      );
      if (page === 1 && fetchedFeedbacks && fetchedFeedbacks.length === 0) {
        setFeedbacks(feedbacks);
      } else {
        setFeedbacks(fetchedFeedbacks);
      }
      if (
        fetchedFeedbacks &&
        fetchedFeedbacks.length > 0 &&
        startAfter.length === 0 &&
        endBefore.length === 0
      ) {
        setFirstId(fetchedFeedbacks[0].id || "");
      }
      setIsLoading(false);
    }
  };

  const feedbacksAvailable = () => {
    if (!feedbacks || (feedbacks && feedbacks.length === 0)) {
      return false;
    } else {
      return true;
    }
  };

  const getCompaniesList = async () => {
    const companyId = getCompanyId(userCustomClaim);
    if (companyId.length === 0) {
      setIsLoading(true);
      const companies = await getAllCompaniesList();
      const companiesDropDownData = companies.map(
        (company) => ({ value: company.id, label: company.name } as ReactSelect)
      );
      setCompaniesList(companiesDropDownData);
      if (companies && companies.length > 0) {
        setSelectedCompany(companies[0]);
      }
      setIsLoading(false);
    } else {
      setSelectedCompany({ id: companyId, name: "" } as DropdownItem);
    }
  };

  const tableHooks = (hooks: any) => {
    hooks.visibleColumns.push((columns: any) => [
      {
        id: "feedbackusername",
        Header: "Name",
        accessor: "userName",
        Cell: ({ row }: any) => {
          return (
            <span
              className={
                row?.original?.userName?.length === 0 ? "text-secondary" : ""
              }
            >
              {row?.original?.userName?.length === 0
                ? "Anonymous"
                : row?.original?.userName}
            </span>
          );
        },
      },
      {
        id: "feedbackdate",
        Header: "Date",
        accessor: "date",
        Cell: ({ row }: any) => {
          const custom_date = moment(row?.original?.date).format(
            "DD MMMM, yyyy"
          );
          return <span>{custom_date}</span>;
        },
      },
      ...columns,
    ]);
  };

  return (
    <>
      <div className="mt-5">
        <Grid container className="flex items-center">
          <Grid item xs={6} className="justify-start">
            {isAdmin(userCustomClaim) && (
              <Select
                className="w-72"
                options={companiesList}
                placeholder="Company"
                isMulti={false}
                onChange={(e: any) => {
                  const companyObj = { id: e.value, name: e.label };
                  setSelectedCompany(companyObj);
                }}
                isSearchable={false}
                styles={{
                  // Adjust the margin to align with buttons
                  container: (provided) => ({
                    ...provided,
                    marginTop: 0,
                  }),
                }}
              />
            )}
          </Grid>

          <Grid item xs={6}>
            <div className="float-right">
              <DateRangePickerComponent
                format="dd-MMM-yy"
                onChange={(range: any) => {
                  setStartDate(range.value[0]);
                  setEndDate(range.value[1]);
                }}
                showClearButton={false}
                startDate={startDate}
                endDate={endDate}
                delayUpdate={true}
                maxDays={14}
                width={300}
                allowEdit={false}
              />
            </div>
          </Grid>
        </Grid>

        {!isLoading && (
          <>
            {!feedbacksAvailable() && <Alert message={"No Feedback Found"} />}

            <Grid container spacing={0} className="my-4">
              {feedbacksAvailable() && (
                <BasicTable
                  tableColumns={COLUMNS}
                  tableData={feedbacks}
                  headerColor="bg-gray-850"
                  maxHeight={550}
                  tableHooks={tableHooks}
                />
              )}
            </Grid>

            <Grid container className="text-center" justifyContent={"end"}>
              {feedbacksAvailable() && feedbacks[0].id === firstId && (
                <Grid item>
                  <IconButton aria-label="left" color="primary" disabled={true}>
                    <NavigateBeforeIcon fontSize="large" />
                  </IconButton>
                </Grid>
              )}
              {feedbacksAvailable() && feedbacks[0].id !== firstId && (
                <Grid item>
                  <IconButton
                    aria-label="left"
                    color="primary"
                    onClick={() => getFeedbacks(-1)}
                  >
                    <NavigateBeforeIcon fontSize="large" />
                  </IconButton>
                </Grid>
              )}
              {feedbacksAvailable() && feedbacks.length < 5 && (
                <Grid item>
                  <IconButton aria-label="left" color="primary" disabled={true}>
                    <NavigateNextIcon fontSize="large" />
                  </IconButton>
                </Grid>
              )}
              {feedbacksAvailable() && feedbacks.length === 5 && (
                <Grid item>
                  <IconButton
                    aria-label="left"
                    color="primary"
                    onClick={() => getFeedbacks(1)}
                  >
                    <NavigateNextIcon fontSize="large" />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </>
        )}

        {isLoading && (
          <>
            <Skeleton height={50} />
            <Skeleton height={50} />
            <Skeleton height={50} />
          </>
        )}
      </div>
    </>
  );
}

import { FeedbackType } from "../../../../Common/Enums/feedback-type";
import Button from "../../../Common/Button/Button";

const Options = (props: any) => {
  const options = [
    {
      text: "Anonymous",
      handler: props.actionProvider.addFeedbackType,
      id: FeedbackType.ANONYMOUS,
    },
    {
      text: "Non Anonymous",
      handler: props.actionProvider.addFeedbackType,
      id: FeedbackType.NON_ANONYMOUS,
    },
  ];

  const buttonsMarkup = options.map((option) => (
    <Button
      key={option.id}
      onClick={() => option.handler(option.id)}
      text={option.text}
      additionalClasses={"gray-button my-1"}
    />
  ));

  return <div className="options-container">{buttonsMarkup}</div>;
};

export default Options;

import React, { useRef } from "react";
import { ProgressBar } from "react-bootstrap";
import { FilePickerProps } from "../../../Common/Interfaces/dashboard";
import Button from "../Button/Button";
import { isAdmin } from "../../../Common/Common";

export default function FilePicker(props: FilePickerProps) {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (hiddenFileInput.current !== null) {
      hiddenFileInput.current.click();
    }
  };

  const fileSelectedHandler = (event: any) => {
    const uploadedFile = event.target.files;
    if (uploadedFile.length > 0) {
      const reg = /(.*?)\.(jpg|jpeg|PNG|png)$/;

      if (!uploadedFile[0].name.match(reg)) {
        setFileValidationState(false);
      } else {
        setFileValidationState(true);
        props.setFileSelected(uploadedFile[0]);
      }
    }
  };

  const setFileValidationState = (stateValue: boolean) => {
    if (props.setIsValidFile) {
      props.setIsValidFile(stateValue);
    }
  };
  return (
    <>
      {props.pictureUrl.length === 0 && (
        <Button
          onClick={(e) => handleClick(e)}
          text={"Choose Picture"}
          additionalClasses={"purple-button"}
        />
      )}

      {props.fileSelected.name && (
        <p className="text-center">
          <span className="purple-color fw-bold">
            {props.fileSelected.name}
          </span>{" "}
          &nbsp;is selected
        </p>
      )}

      <input
        type="file"
        className="mt-2 d-none"
        onChange={(e) => fileSelectedHandler(e)}
        accept="image/*"
        ref={hiddenFileInput}
      ></input>
      {props.isUploading && (
        <ProgressBar
          striped
          now={props.uploadProgress}
          label={`${props.uploadProgress}%`}
        />
      )}
      {props.pictureUrl.length > 0 && !props.fileSelected.name && (
        <div className="img-container position-relative w-100 mt-3">
          <img src={props.pictureUrl} className="w-100" alt={props.name} />
          <button
            className={`btn gray-button rounded-pill ${
              !isAdmin(props.userCustomClaim) && props.name !== "projectLogo"
                ? "hidden"
                : ""
            }`}
            onClick={(e) => handleClick(e)}
          >
            Change Picture
          </button>
        </div>
      )}
    </>
  );
}

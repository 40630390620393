import {
  DropdownItem,
  Member,
  MemberRole,
} from "../../Common/Interfaces/dashboard";
import { getData } from "../axios-calls/common-axios-service";

export const getAllMembers = async (
  companyId: string,
  memberId = "",
  projectId = ""
): Promise<Member[]> => {
  const fetchedUsers = await getData(
    `companies/${companyId}/users?userId=${memberId}&projectId=${projectId}`
  );
  return fetchedUsers;
};

export const getProjectMembersAndRoles = async (
  companyId: string,
  memberId = "",
  projectId = ""
): Promise<MemberRole[]> => {
  const fetchedUsers = await getData(
    `companies/${companyId}/users/dropdownlist?projectId=${projectId}&userId=${memberId}`
  );
  return fetchedUsers;
};

export const getUserProjects = async (
  companyId: string,
  memberId = "",
  projectId = ""
): Promise<DropdownItem[]> => {
  const fetchedUsers = await getData(
    `companies/${companyId}/users/list?projectId=${projectId}&userId=${memberId}`
  );
  return fetchedUsers;
};

import { NOTIFICATION_TYPE, Store } from "react-notifications-component";
import validator from "validator";
import { SelectedGlobalProject } from "../redux/model/SelectedProject.d";
import {
  ProjectPermissionModel,
  UserCustomClaim,
} from "../redux/model/UserState.d";
import { AllDropdown, AttendanceStatus, UserRole } from "./Enums/dashboard";
import { DayOfWeek } from "./Enums/day";
import { DayNumber } from "./Enums/day-number";
import {
  CompanyLevelPermissions,
  ProjectLevelPermissions,
} from "./Enums/permissions";
import { DropdownItem } from "./Interfaces/dashboard";

export const defaultPic =
  "https://firebasestorage.googleapis.com/v0/b/aurora-portal-internal.appspot.com/o/profile%2FprofilePic.png?alt=media&token=4d535331-1c0e-4ecd-aba4-2e138ef1d0ee";
export const apiUrl = process.env.REACT_APP_FIREBASE_BACKEND_API;
export const foodOrderPrice = 75.0;

export const isSuccessCode = (code: number): boolean => {
  if (code >= 200 && code < 300) {
    return true;
  } else {
    return false;
  }
};

export const enumStringToText = (str: string): string => {
  if (str) {
    return capitalize(str.replaceAll("_", " "));
  } else {
    return "";
  }
};

export const getAttendanceStatusColor = (status: string): string => {
  switch (status) {
    case AttendanceStatus.PRESENT:
      return "green";
    case AttendanceStatus.PUBLIC_HOLIDAYS:
      return "purple";
    default:
      return "red";
  }
};

export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const textToId = (str: string) => {
  const spaceRemoved = str.replaceAll(" ", "");
  return spaceRemoved.charAt(0).toLowerCase() + spaceRemoved.slice(1);
};

export const convertTime = (totalMinutes: number): string => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours > 0 ? hours + "h" : ""} ${minutes}m`;
};

export const validateEmail = (text: string) => {
  if (validator.isEmail(text)) {
    return true;
  } else {
    return false;
  }
};

export const addAllInDropdown = (
  dropdownValues: any[],
  dropdownName: string
): DropdownItem[] => {
  const updated = [
    { id: AllDropdown.ALL, name: `All ${dropdownName}` } as DropdownItem,
    ...dropdownValues,
  ];
  return updated;
};

export const getDefaultProfilePicUrl = (): string => {
  return defaultPic;
};

export const addDefaultSrc = (ev: any) => {
  ev.target.src = defaultPic;
};

export const getRoleFromProjectPermissions = (
  permissions: ProjectLevelPermissions[]
) => {
  if (permissions.length > 0) {
    return UserRole.PROJECT_MANAGER;
  } else {
    return UserRole.TEAM_MEMBER;
  }
};

export const getRoleFromCompanyPermissions = (
  permissions: CompanyLevelPermissions[]
) => {
  const totalPermissions = Object.keys(CompanyLevelPermissions).map(
    (permission) => new Option(enumStringToText(permission), permission)
  ).length;
  if (permissions.length === totalPermissions) {
    return UserRole.COMPANY_ADMIN;
  } else {
    return UserRole.TEAM_MEMBER;
  }
};

export const hasPermission = (
  userCustomClaim: UserCustomClaim,
  permission: string
) => {
  if (userCustomClaim && userCustomClaim?.isAdmin) {
    return true;
  } else if (
    userCustomClaim &&
    userCustomClaim?.companyPermissions?.includes(
      (permission as CompanyLevelPermissions) || ""
    )
  ) {
    return true;
  } else {
    return false;
  }
};

export const isAdmin = (userCustomClaim: UserCustomClaim) => {
  if (userCustomClaim?.isAdmin) {
    return true;
  } else {
    return false;
  }
};

export const getCompanyId = (userCustomClaim: UserCustomClaim): string => {
  if (userCustomClaim?.companyId) {
    return userCustomClaim.companyId;
  } else {
    return "";
  }
};

export const getGlobalProject = (
  projectState: SelectedGlobalProject
): SelectedGlobalProject => {
  if (projectState?.projectId) {
    return projectState;
  } else {
    return { projectId: "", projectName: "" } as SelectedGlobalProject;
  }
};

export const getProjectPermissions = (
  userCustomClaim: UserCustomClaim,
  projectState: SelectedGlobalProject
): ProjectLevelPermissions[] => {
  if (userCustomClaim && userCustomClaim?.projectPermissionsModel) {
    const permissions = userCustomClaim.projectPermissionsModel;
    const filtered = permissions.filter(
      (x: ProjectPermissionModel) =>
        x.projectId === getGlobalProject(projectState).projectId
    )[0];
    if (filtered) {
      return filtered.projectPermissions;
    }
    return [];
  } else {
    return [];
  }
};

export const isCompanyAdmin = (userCustomClaim: UserCustomClaim) => {
  const companyAdminPermissions = [
    CompanyLevelPermissions.GET_COMPANY_USERS,
    CompanyLevelPermissions.GET_COMPANY,
  ];
  return (
    !isAdmin(userCustomClaim) &&
    hasCompanyAdminPermission(userCustomClaim, companyAdminPermissions)
  );
};
export const hasCompanyAdminPermission = (
  userCustomClaim: UserCustomClaim,
  allowedPermission: string[]
) => {
  return allowedPermission?.every((permission) =>
    userCustomClaim?.companyPermissions?.includes(
      permission as CompanyLevelPermissions
    )
  );
};

export const projectsAvailable = (
  userCustomClaim: UserCustomClaim
): boolean => {
  if (userCustomClaim && userCustomClaim?.projectPermissionsModel?.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const addNotificationToApp = (
  title: string,
  message: string,
  type: NOTIFICATION_TYPE
) => {
  Store.addNotification({
    title: title,
    message: message,
    type: type,
    insert: "bottom",
    container: "bottom-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      showIcon: true,
    },
  });
};

export const getDayFromDayNumber = (dayNumber: DayNumber): DayOfWeek => {
  switch (dayNumber) {
    case DayNumber.DAY_1:
      return DayOfWeek.MONDAY;
    case DayNumber.DAY_2:
      return DayOfWeek.TUESDAY;
    case DayNumber.DAY_3:
      return DayOfWeek.WEDNESDAY;
    case DayNumber.DAY_4:
      return DayOfWeek.THURSDAY;
    case DayNumber.DAY_5:
      return DayOfWeek.FRIDAY;
    case DayNumber.DAY_6:
      return DayOfWeek.SATURDAY;
    case DayNumber.DAY_7:
      return DayOfWeek.SUNDAY;
    case DayNumber.DAY_8:
      return DayOfWeek.MONDAY;
    case DayNumber.DAY_9:
      return DayOfWeek.TUESDAY;
    case DayNumber.DAY_10:
      return DayOfWeek.WEDNESDAY;
    case DayNumber.DAY_11:
      return DayOfWeek.THURSDAY;
    case DayNumber.DAY_12:
      return DayOfWeek.FRIDAY;
    case DayNumber.DAY_13:
      return DayOfWeek.SATURDAY;
    case DayNumber.DAY_14:
      return DayOfWeek.SUNDAY;
  }
};

export const getDayNumberFromIndex = (index: number): DayNumber => {
  const dayNumber: DayNumber = ("DAY_" + (index + 1)) as DayNumber;
  return dayNumber;
};

export const getClickableLink = (link: string) => {
  return link.startsWith("http://") || link.startsWith("https://")
    ? link
    : `https://${link}`;
};

export const userLoginGraphStyleOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "User Login Data",
    },
  },
};

export const userEngagementGraphStyleOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "User Engagement Data",
    },
  },
};

export const pieGraphStyleOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Page Views Data",
    },
  },
};

export const userRetentionGraphStyleOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "User Retention Data",
    },
  },
};
export const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
];

export const lineGraph = {
  labels,
  datasets: [
    {
      label: "daily login users",
      data: [],
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
  ],
};
export const graphInitialData = {
  labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/esm/Modal";
import Button from "../Button/Button";
import { DeleteProps } from "../../../Common/Interfaces/delete-props";
export default function DeleteModal(props: DeleteProps) {
  const submit = async () => {
    props.handleDelete();
  };

  return (
    <>
      <Modal
        className="container-modal"
        size="lg"
        centered={true}
        show={props.showModal}
        onHide={() => props.handleClose()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <label className="fw-bold">{props.message}</label>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => props.handleClose()}
            text="Close"
            additionalClasses={"gray-button"}
          />
          <Button
            onClick={() => submit()}
            text="Delete"
            additionalClasses={"purple-button"}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}

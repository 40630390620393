import { useEffect, useState } from "react";

import Select from "react-select";
import { Column } from "react-table";
import { useSelector } from "react-redux";

import moment from "moment";

import HashMap from "hashmap";

import { Checkbox, Grid, Typography } from "@mui/material";

import {
  DateRangePickerComponent,
  RenderDayCellEventArgs,
} from "@syncfusion/ej2-react-calendars";

import { getWeeklyAllocations } from "../../../../apis/services/allocation.service";
import {
  getAllCompaniesList,
  getCompanyUsersList,
} from "../../../../apis/services/company.service";

import {
  getCompanyId,
  hasPermission,
  isAdmin,
} from "../../../../Common/Common";
import BasicTable from "../../../Common/Table/BasicTable";
import { DayNumber } from "../../../../Common/Enums/day-number";
import { CompanyLevelPermissions } from "../../../../Common/Enums/permissions";
import { Allocation } from "../../../../Common/Interfaces/Allocation/allocation";
import {
  DropdownItem,
  ReactSelect,
} from "../../../../Common/Interfaces/dashboard";

import { ReduxState } from "../../../../redux/model/ReduxState.d";
import { UserCustomClaim } from "../../../../redux/model/UserState.d";

export default function CalendarTabNew() {
  const [companiesList, setCompaniesList] = useState([] as ReactSelect[]);
  const [selectedCompany, setSelectedCompany] = useState({} as DropdownItem);
  const [companyUsers, setCompanyUsers] = useState([] as DropdownItem[]);
  const [dates, setDates] = useState([] as Date[]);

  const [allocationTableData, setAllocationTableData] = useState([] as any);
  const [tableKey, setTableKey] = useState(0);

  const [showError, setShowError] = useState(
    moment(new Date()).format("ddd") !== "Mon"
  );
  const userCustomClaim = useSelector<ReduxState, UserCustomClaim>(
    (state: ReduxState) => state.UserReducer?.userPermission?.userCustomClaim
  );
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(
    new Date(new Date().getTime() + 14 * 24 * 60 * 60 * 1000)
  );
  const userId = useSelector<ReduxState, string>(
    (state: ReduxState) => state.UserReducer.userId || ""
  );
  const userName = useSelector<ReduxState, string>(
    (state: ReduxState) => state.UserReducer.userName || ""
  );

  const days = Object.keys(DayNumber).map(
    (dayNumber) => dayNumber as DayNumber
  );

  useEffect(() => {
    if (
      hasPermission(userCustomClaim, CompanyLevelPermissions.GET_COMPANY) ||
      isAdmin(userCustomClaim)
    ) {
      getCompaniesList();
    }
  }, [userCustomClaim]);

  useEffect(() => {
    getCompanyUsers();
  }, [selectedCompany]);

  useEffect(() => {
    if (allocationTableData?.length) {
      setTableKey((prevKey) => prevKey + 1);
    }
  }, [allocationTableData]);

  const disabledDate = (args: RenderDayCellEventArgs): void => {
    if (
      (args.date as Date).getDay() === 0 ||
      (args.date as Date).getDay() === 6
    ) {
      // set 'true' to disable the weekends
      args.isDisabled = true;
    }
  };

  const datesUpdateHandler = (firstDate: Date, lastDate: Date) => {
    setStartDate(firstDate);
    setEndDate(lastDate);
    const datesArray = getDatesBetween(firstDate, lastDate);
    setDates(datesArray);
  };

  useEffect(() => {
    getAllocations();
  }, [dates]);

  const getAllocations = async () => {
    if (selectedCompany && selectedCompany.id && !showError) {
      //   setIsLoading(true);
      const fetchedAllocations = await getWeeklyAllocations(
        selectedCompany.id,
        moment(startDate).format("L"),
        moment(endDate).format("L")
      );
      const mappedData = new HashMap<string, HashMap<string, boolean>>();
      fetchedAllocations &&
        fetchedAllocations.forEach((a: Allocation) => {
          const date = moment(a.date).format("L");
          if (!mappedData.has(date)) {
            mappedData.set(
              date,
              new HashMap<string, boolean>(a.userId, a.status)
            );
          }
          mappedData.get(date)?.set(a.userId, a.status);
        });

      if (companyUsers?.length) {
        // generate table data
        const tableData = companyUsers.map((companyUser: any) => {
          const userToUpdate = [];
          const userObject: any = {
            userId: companyUser?.id,
            userName: companyUser?.name,
          };
          if (dates.length > 0) {
            dates.map((d) => {
              const status =
                mappedData.get(moment(d).format("L"))?.get(companyUser.id) ||
                false;
              const dateString: any = moment(d).format("MM/DD/yyyy");

              userObject[dateString] = status;
            });
          }
          if (dates.length <= 0) {
            days.map((d) => {
              const status = mappedData.get(d)?.get(companyUser.id) || false;
              const dateString: any = moment(d).format("MM/DD/yyyy");

              userObject[dateString] = status;
            });
          }
          userToUpdate.push(userObject);
          return userToUpdate;
        });

        setAllocationTableData(tableData.flat(1));
      }
      //   setIsLoading(false);
    }
  };

  const getDatesBetween = (sDate: Date, eDate: Date) => {
    const allDates = [];
    const currentDate = new Date(sDate);
    while (currentDate < eDate) {
      if (!(currentDate.getDay() === 0 || currentDate.getDay() === 6)) {
        allDates.push(new Date(currentDate));
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    allDates.push(eDate);
    return allDates;
  };

  // Dropdown data
  const getCompaniesList = async () => {
    const companyId = getCompanyId(userCustomClaim);
    if (companyId.length === 0) {
      //   setIsLoading(true);
      const companies = await getAllCompaniesList();
      const companiesDropDownData = companies.map(
        (company) => ({ value: company.id, label: company.name } as ReactSelect)
      );
      setCompaniesList(companiesDropDownData);
      if (companies && companies.length > 0) {
        setSelectedCompany(companies[0]);
      }
      //   setIsLoading(false);
    } else {
      setSelectedCompany({ id: companyId, name: "" } as DropdownItem);
    }
  };

  const getCompanyUsers = async () => {
    if (
      hasPermission(userCustomClaim, CompanyLevelPermissions.GET_ALLOCATION) &&
      selectedCompany.id
    ) {
      //   setIsLoading(true);
      const fetchedCompanyUsers = await getCompanyUsersList(selectedCompany.id);
      setCompanyUsers(fetchedCompanyUsers);
      //   setIsLoading(false);
    } else {
      setCompanyUsers([{ id: userId, name: userName }] as DropdownItem[]);
    }
  };

  const generateColumns = () => {
    const columns: any = [];
    const uniqueColumnIds = new Set(); // Keep track of unique column identifiers

    if (allocationTableData?.length > 0) {
      allocationTableData.forEach((data: any) => {
        Object.keys(data).forEach((dataKey) => {
          if (dataKey !== "userId" && !uniqueColumnIds.has(dataKey)) {
            const column = {
              id: Math.random().toString(),
              Header:
                dataKey === "userName"
                  ? "Name"
                  : moment(dataKey).format("DD MMMM ddd"),
              accessor: dataKey,
              disableSortBy: true,
              Cell: ({ row }: any) => {
                const allocationCalendarTableDataValue =
                  row?.original?.[dataKey];

                if (dataKey !== "userName") {
                  return (
                    <span className="w-[10px] h-[10px] p-0">
                      <Checkbox
                        size="small"
                        id={dataKey}
                        disabled={true}
                        checked={allocationCalendarTableDataValue}
                        onChange={() => {
                          return;
                        }}
                      />
                    </span>
                  );
                } else {
                  return allocationCalendarTableDataValue;
                }
              },
            };
            columns.push(column);
            uniqueColumnIds.add(dataKey);
          }
        });
      });
    }

    return columns;
  };

  const tableHooks = (hooks: any) => {
    hooks.visibleColumns.push((columns: any) => [...columns]);
  };

  const COLUMNS: Column[] | any = [...generateColumns()];

  return (
    <>
      {/* <Loader active={isLoading}> */}
      <div className="mt-5">
        <Grid container spacing={2}>
          {isAdmin(userCustomClaim) && (
            <Grid item xs={2} className="flex" justifyContent={"start"}>
              <Select
                className="w-72"
                options={companiesList}
                placeholder="Company"
                isMulti={false}
                onChange={(e: any) => {
                  const companyObj = { id: e.value, name: e.label };
                  setSelectedCompany(companyObj);
                }}
                isSearchable={false}
              />
            </Grid>
          )}

          <Grid item xs={10} justifyContent={"start"}>
            <DateRangePickerComponent
              width={250}
              maxDays={14}
              allowEdit={false}
              endDate={endDate}
              delayUpdate={true}
              format="dd-MMM-yy"
              startDate={startDate}
              showClearButton={false}
              renderDayCell={disabledDate}
              onChange={(range: any) => {
                if (moment(range.value[0]).format("ddd") === "Mon") {
                  setShowError(false);
                  datesUpdateHandler(range.value[0], range.value[1]);
                }
              }}
            />
            {showError && (
              <Grid item xs={7}>
                <Typography variant="caption" className="text-gray-700">
                  <strong>Note:</strong> Start Date should be Monday!
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>

        {!showError && (
          <Grid container spacing={1} className="my-2">
            <Grid item xs={12} className="w-[500px]">
              {allocationTableData?.length > 0 && (
                <BasicTable
                  key={tableKey}
                  tableColumns={COLUMNS}
                  tableData={allocationTableData}
                  headerColor="bg-gray-850"
                  maxHeight={500}
                  tableHooks={tableHooks}
                />
              )}
            </Grid>
          </Grid>
        )}
      </div>
      {/* </Loader> */}
    </>
  );
}

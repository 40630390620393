import React, { Suspense } from "react";
import { useSelector } from "react-redux";

import Moment from "moment";

import {
  Card,
  Avatar,
  CardHeader,
  Typography,
  CardContent,
  CardActions,
  Skeleton,
  Tooltip,
  Button,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LaunchIcon from "@mui/icons-material/Launch";

import {
  getClickableLink,
  hasPermission,
  isAdmin,
} from "../../../../Common/Common";

import { ReduxState } from "../../../../redux/model/ReduxState.d";
import { UserCustomClaim } from "../../../../redux/model/UserState.d";
import { CompanyLevelPermissions } from "../../../../Common/Enums/permissions";

const ProjectCardNew = ({
  key,
  project,
  handleShow,
  handleDeleteProject,
}: any) => {
  const userCustomClaim = useSelector<ReduxState, UserCustomClaim>(
    (state: ReduxState) => state.UserReducer?.userPermission?.userCustomClaim
  );

  const openProjectBoard = (e: React.MouseEvent, url: string) => {
    e.preventDefault();
    window.open(getClickableLink(url), "_blank");
  };

  return (
    <div className="w-webkitfill">
      <Card className="h-full">
        <CardHeader
          avatar={
            <Avatar aria-label="recipe">
              <Suspense fallback={<Skeleton height={key} />}>
                <img
                  onClick={handleShow}
                  onChange={handleDeleteProject}
                  src={project.profilePicture}
                  alt={project.name}
                />
              </Suspense>
            </Avatar>
          }
          title={project.name}
          subheader={`Starting date: ${Moment(project.startingDate).format(
            "DD MMMM, yyyy"
          )}`}
        />
        <CardContent>
          <Typography variant="subtitle2" color="text.secondary">
            <b>Team Mates: </b> {project?.teammates}
          </Typography>
          <br />
          <b>Client Details: </b>
          <Typography variant="subtitle2" color="text.secondary">
            <b>Name: </b> {project?.clientName}
          </Typography>
          <Typography variant="subtitle2" color="text.secondary">
            <b>Email: </b> {project?.clientEmail}
          </Typography>
        </CardContent>
        <CardActions disableSpacing className="flex justify-between">
          <Tooltip title="View Project Board">
            <Button
              variant="text"
              size="small"
              startIcon={<LaunchIcon />}
              onClick={(e) => openProjectBoard(e, project.projectBoardUrl)}
            >
              View Project Board
            </Button>
          </Tooltip>
          {hasPermission(
            userCustomClaim,
            CompanyLevelPermissions.UPDATE_PROJECTS
          ) &&
            !isAdmin(userCustomClaim) && (
              <span>
                <IconButton
                  aria-label="Edit"
                  color="primary"
                  onClick={() => handleShow(true, project)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label="Delete"
                  color="error"
                  onClick={() => handleDeleteProject(project.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            )}
        </CardActions>
      </Card>
    </div>
  );
};

export default ProjectCardNew;

import { AxiosResponse } from "axios";
import { DropdownItem, ProjectDetail } from "../../Common/Interfaces/dashboard";
import {
  deleteData,
  getData,
  postData,
  updateData,
} from "../axios-calls/common-axios-service";

export const getAllProjects = async (
  companyId: string,
  startAt = "",
  endBefore = ""
): Promise<ProjectDetail[]> => {
  const fetchedProjects = await getData(
    `companies/${companyId}/projects?startAt=${startAt}&endBefore=${endBefore}`
  );
  return fetchedProjects;
};

export const getAllProjectsList = async (
  companyId: string
): Promise<DropdownItem[]> => {
  const fetchedProjects = await getData(`companies/${companyId}/projects/list`);
  return fetchedProjects;
};

export const postProject = async (
  companyId: string,
  project: ProjectDetail
): Promise<AxiosResponse> => {
  const res = await postData(`companies/${companyId}/projects`, project);
  return res as AxiosResponse;
};

export const updateProject = async (
  companyId: string,
  projectId: string,
  project: ProjectDetail
): Promise<AxiosResponse> => {
  const res = await updateData(
    `companies/${companyId}/projects/${projectId}`,
    project
  );
  return res as AxiosResponse;
};
export const deleteProject = async (
  companyId: string,
  projectId: string
): Promise<AxiosResponse> => {
  const res = await deleteData(`companies/${companyId}/projects/${projectId}`);
  return res as AxiosResponse;
};

export const addMemberInProject = async (
  companyId: string,
  memberBody: any
): Promise<AxiosResponse> => {
  const res = await postData(`companies/${companyId}/users`, memberBody);
  return res as AxiosResponse;
};

export const updateMemberRoleInProject = async (
  companyId: string,
  userProjectId: string,
  memberBody: any
): Promise<AxiosResponse> => {
  const res = await updateData(
    `companies/${companyId}/users/${userProjectId}`,
    memberBody
  );
  return res as AxiosResponse;
};
export const deleteMemberRoleInProject = async (
  companyId: string,
  userProjectId: string
): Promise<AxiosResponse> => {
  const res = await deleteData(`companies/${companyId}/users/${userProjectId}`);
  return res as AxiosResponse;
};
export const deleteUserFromProject = async (
  companyId: string,
  projectId: string,
  userId: string
): Promise<AxiosResponse> => {
  const res = await deleteData(
    `companies/${companyId}/users/${userId}/project/${projectId}`
  );
  return res as AxiosResponse;
};

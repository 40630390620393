import { AxiosResponse } from "axios";
import { Allocation } from "../../Common/Interfaces/Allocation/allocation";
import {
  getData,
  postData,
  updateData,
} from "../axios-calls/common-axios-service";

export const getWeeklyAllocations = async (
  companyId: string,
  startingDate: string,
  endingDate: string,
  startAfter = "",
  endBefore = ""
): Promise<Allocation[]> => {
  const allocations = await getData(
    `companies/${companyId}/allocations?startingDate=${startingDate}&endingDate=${endingDate}&startAfter=${startAfter}&endBefore=${endBefore}`
  );
  return allocations;
};

export const getWeeklyTempAllocations = async (
  companyId: string
): Promise<Allocation[]> => {
  const orders = await getData(`companies/${companyId}/allocations/next`);
  return orders;
};

export const updateAllocation = async (
  companyId: string,
  allocationId: string,
  allocation: Allocation | any
): Promise<AxiosResponse> => {
  const res = await updateData(
    `companies/${companyId}/allocations/${allocationId}`,
    allocation
  );
  return res as AxiosResponse;
};
export const createNonProjectUserAllocation = async (
  companyId: string,
  allocation: Allocation
): Promise<AxiosResponse> => {
  const res = await postData(`companies/${companyId}/allocations`, allocation);
  return res as AxiosResponse;
};

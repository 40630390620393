import { Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/esm/Modal";
import { useState } from "react";
import Button from "../../../Common/Button/Button";
import {
  enumStringToText,
  getAttendanceStatusColor,
  isSuccessCode,
} from "../../../../Common/Common";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import AttendanceDropdown from "../../../Common/AttendanceDropdown/AttendanceDropdown";
import {
  postHoliday,
  updateHoliday,
} from "../../../../apis/services/holiday.service";
import moment from "moment";
import { AddEditHolidayModalProps } from "../../../../Common/Interfaces/Attendance/add-edit-holiday-modal-props";
import { AttendanceStatusOption } from "../../../../Common/Interfaces/Attendance/attendance-status-option";
import { AddEditHolidaySchema } from "../../../../Common/ValidationSchema/AddEditHolidaySchema";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

type ValidationSchema = z.infer<typeof AddEditHolidaySchema>;
export interface IHoldiay {
  holidayName: string;
}
export default function AddEditHolidayModal(props: AddEditHolidayModalProps) {
  const [holidayId] = useState(props.editHoliday?.Id || "");
  // const [name, setname] = useState(props.editHoliday?.name || "");
  const [startDate, setStartDate] = useState(
    new Date(props.editHoliday?.startingDate) || new Date()
  );
  const [showError, setShowError] = useState(false);
  const [endDate, setEndDate] = useState(
    new Date(props.editHoliday?.endingDate) || new Date()
  );
  const [attendanceStatusOption, setAttendanceStatusOption] = useState({
    value: props.editHoliday.status,
    label: enumStringToText(props.editHoliday.status),
    color: getAttendanceStatusColor(props.editHoliday.status),
  } as AttendanceStatusOption);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isEdit] = useState(
    props.editHoliday &&
      props.editHoliday?.Id &&
      props.editHoliday?.Id.length > 0
      ? true
      : false
  );
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ValidationSchema>({
    defaultValues: { holidayName: props.editHoliday?.name || "" },
    resolver: zodResolver(AddEditHolidaySchema),
    mode: "all",
  });
  const saveHoliday = async (name: string) => {
    const holiday = {
      name,
      startingDate: moment(startDate).format("MM/DD/yyyy"),
      endingDate: moment(endDate).format("MM/DD/yyyy"),
      status: attendanceStatusOption.value,
    };

    if (!isEdit) {
      const res = await postHoliday(props.selectedCompanyId, holiday);
      if (res && isSuccessCode(res?.status)) {
        const resHoliday = {
          Id: res.data.id,
          name: name,
          startingDate: moment(startDate).format("MM/DD/yyyy"),
          endingDate: moment(endDate).format("MM/DD/yyyy"),
          status: attendanceStatusOption.value,
        };
        props.addHoliday(resHoliday);
      }
    } else {
      const res = await updateHoliday(
        props.selectedCompanyId,
        holidayId,
        holiday
      );
      if (res && isSuccessCode(res?.status)) {
        props.addHoliday(holiday, true);
      }
    }
  };

  const isFormValid = () => {
    if (startDate && endDate) {
      return true;
    } else {
      return false;
    }
  };

  const handleSave = (holidayData: IHoldiay) => {
    const holidayName = holidayData.holidayName;
    setIsSubmitted(true);
    if (isFormValid() && !showError) {
      saveHoliday(holidayName);
      props.handleClose();
    }
  };

  return (
    <>
      <Modal
        className="add-edit-holiday-modal-container"
        show={props.showModal}
        onHide={() => props.handleClose()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? "Edit Holiday" : "Add Holiday"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(handleSave)}>
            <Row>
              <Col>
                <label className="fw-bold">Holiday Name</label>
                <input
                  type="text"
                  id={"holidayName"}
                  className={`form-control rounded-pill ${
                    errors?.holidayName && "is-invalid"
                  }`}
                  {...register("holidayName")}
                />
                <p className="mt-1 ml-3 text-xs text-red-600">
                  {errors?.holidayName?.message}
                </p>
              </Col>
            </Row>
            <Row className="my-3">
              <Col>
                <label className="fw-bold">Holiday Dates</label>

                <DateRangePickerComponent
                  format="dd-MMM-yy"
                  onChange={(range: any) => {
                    if (
                      range.value &&
                      moment(range.value[0]).valueOf() <=
                        moment(range.value[1]).valueOf()
                    ) {
                      setShowError(false);
                      setStartDate(range.value[0]);
                      setEndDate(range.value[1]);
                    } else {
                      setShowError(true);
                    }
                  }}
                  showClearButton={false}
                  startDate={startDate}
                  endDate={endDate}
                  delayUpdate={true}
                ></DateRangePickerComponent>

                {showError && (
                  <div className="text-danger">
                    <small>Please provide valid date</small>
                  </div>
                )}
              </Col>
            </Row>

            <Row className="my-3">
              <Col>
                <label className="fw-bold">Attendance Status</label>
                <AttendanceDropdown
                  attendanceStatus={attendanceStatusOption.value}
                  onChange={(e: AttendanceStatusOption) =>
                    setAttendanceStatusOption(e)
                  }
                  target={{} as HTMLElement}
                  isDisabled={false}
                  width="auto"
                />
              </Col>
            </Row>

            <Button
              type="submit"
              text={isEdit ? "Update Holiday" : "Save Holiday"}
              disabled={isSubmitted || !isValid || showError}
              additionalClasses={`h-10 mt-3 bg-blue-600 text-white w-full mb-2 ${
                (isSubmitted || !isValid || showError) &&
                "opacity-25 cursor-not-allowed"
              }`}
            />
            <Button
              onClick={() => props.handleClose()}
              text="Close"
              additionalClasses={"h-10 mt-3 bg-gray-600 text-white w-full mb-2"}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}

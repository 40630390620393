import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.scss";
// import Dashboard from "./Components/Dashboard/Dashboard";
import Login from "./Components/Login/Login";
import "react-loading-skeleton/dist/skeleton.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-react-calendars/styles/material.css";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import DashboardNew from "./Components/Dashboard/DashboardNew";
import { useSelector } from "react-redux";
import { ReduxState } from "./redux/model/ReduxState.d";
import Loader from "./Components/Common/Loader/Loader";

function App() {
  const isLoading = useSelector<ReduxState, boolean>(
    (state: ReduxState) => state.LoaderReducer.isLoading
  );
  return (
    <>
      <ReactNotifications />

      <Loader active={isLoading}>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard/*" element={<DashboardNew />} />
          </Routes>
        </Router>
      </Loader>
    </>
  );
}

export default App;

import UserReducer from "./UserReducer";
import { combineReducers } from "redux";
import { UserState } from "../model/UserState.d";
import SelectedProjectReducer from "./ProjectReducer";
import { SelectedGlobalProject } from "../model/SelectedProject.d";
import { LoadingState } from "../model/LoadingState.d";
import LoaderReducer from "./LoaderReducer";

const rootReducer = combineReducers({
  UserReducer,
  SelectedProjectReducer,
  LoaderReducer,
});
export default rootReducer;
export interface RootSate {
  GetUserState: UserState;
  GetSelectedProject: SelectedGlobalProject;
  GetLoaderState: LoadingState;
}

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";

import { onAuthStateChanged } from "firebase/auth";

import { auth } from "../../config/firebase.includes";

import { loginSuccessfull, logout } from "../../redux/actions/UserActions";
import { ReduxState } from "../../redux/model/ReduxState.d";
import { UserCustomClaim } from "../../redux/model/UserState.d";

import { hasPermission, isAdmin } from "../../Common/Common";
import {
  AttendanceType,
  DashboardTab,
  LunchTab,
} from "../../Common/Enums/dashboard";
import { CompanyLevelPermissions } from "../../Common/Enums/permissions";

import AllocationTab from "./Allocation/AllocationTab";
import AttendanceTab from "./Attendance/AttendanceTab";
import CompanyTab from "./Company/CompanyTab";
import FeedbackChatbot from "./Feedback/FeedbackChatbot/FeedbackChatbot";
import FeedbackTab from "./Feedback/FeedbackTab";
import Header from "./Header/Header";
import LunchAllocationTab from "./LunchAllocation/LunchAllocationTab";
import MembersTab from "./Members/MembersTab";
import ProjectTab from "./Project/ProjectTab";
import Salary from "./Salary/Salary";
import SidebarTW from "./SideBar/SideBarTW";
import WorklogTab from "./Worklog/WorklogTab";

import fetchRoute from "../../utils/helpers/generateRoutePath";
import AnalyticsTab from "./Analytics/AnalyticsTab";
import VideoTab from "./Videos/VideoTab";

const DashboardNew = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userCustomClaim = useSelector<ReduxState, UserCustomClaim>(
    (state: ReduxState) => state.UserReducer?.userPermission?.userCustomClaim
  );
  const userId = useSelector<ReduxState, string>(
    (state: ReduxState) => state.UserReducer.userId || ""
  );

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [collapsed, setSidebarCollapsed] = useState(false);
  const [headerRouteName, setHeaderRouteName] = useState("");

  useEffect(() => {
    onAuthStateChanged(auth, function (user) {
      if (user === null) {
        dispatch(logout());
        navigate("/login");
      } else if (!userCustomClaim) {
        dispatch(loginSuccessfull());
      }
    });

    const last = window.location.href
      .split("/")
      [
        window.location.href.split("/").length - 1
      ].toUpperCase() as DashboardTab;
    const urlPathName = Object.keys(AttendanceType).includes(last)
      ? DashboardTab.ATTENDANCE
      : Object.keys(LunchTab).includes(last)
      ? DashboardTab.LUNCH
      : last;

    const routeIndex = Object.values(DashboardTab).indexOf(urlPathName);

    setSelectedIndex(routeIndex);
    const routeName = fetchRoute(routeIndex);
    if (routeName) {
      setHeaderRouteName(routeName);
    }
  }, []);

  const memberCheck =
    !isAdmin(userCustomClaim) &&
    !hasPermission(
      userCustomClaim,
      CompanyLevelPermissions.CREATE_COMPANY_USERS
    );

  const navigateHandler = (tabName: string, tabValue: number) => {
    setSelectedIndex(tabValue);
    navigate(tabName);
    const routeName = fetchRoute(tabValue);
    if (routeName) {
      setHeaderRouteName(routeName);
    }
  };

  return (
    <section className="layout-container-content min-h-screen flex flex-col">
      <section className="flex flex-1">
        <div
          className={`grid ${
            collapsed
              ? "grid-cols-sidebar-collapsed w-auto"
              : "grid-cols-sidebar w-60"
          } min-h-screen transition-[grid-template-columns] duration-300 ease-in-out`}
        >
          <div
            className={`h-auto w-auto px-0 py-0 z-9999 ${
              collapsed ? "w-auto" : ""
            }`}
            id="sidebar-wrapper"
          >
            {/* <SideBar /> */}
            <SidebarTW
              collapsed={collapsed}
              navigateHandler={navigateHandler}
              selectedIndex={selectedIndex}
              setCollapsed={setSidebarCollapsed}
            />
          </div>
        </div>

        <section className="flex flex-col flex-1">
          {/* Header */}
          <header
            role="heading"
            className="layout-header p-4 text-white bg-blue-600"
          >
            <span>
              <Header userId={userId} headerRouteName={headerRouteName} />
            </span>
          </header>

          {/* Content */}
          <main role="main" className="layout-main flex-1 p-4 bg-gray-200">
            {/* <span>Main</span> */}

            <div
              className={`col-span-${memberCheck ? 10 : 11} sm:col-span-${
                memberCheck ? 10 : 11
              } md:col-span-${memberCheck ? 10 : 11} lg:col-span-${
                memberCheck ? 6 : 7
              } xl:col-span-${memberCheck ? 8 : 9}`}
              id="page-content-wrapper"
            >
              <div className="grid">
                <div>
                  <Outlet />
                  <Routes>
                    <Route path="company" element={<CompanyTab />} />
                    <Route path="projects" element={<ProjectTab />} />
                    <Route path="members" element={<MembersTab />} />
                    <Route path="timelogs" element={<WorklogTab />} />
                    <Route path="attendance/*" element={<AttendanceTab />} />
                    <Route path="allocation/*" element={<AllocationTab />} />
                    <Route path="lunch/*" element={<LunchAllocationTab />} />
                    <Route path="feedback" element={<FeedbackTab />} />
                    <Route path="salary" element={<Salary />} />
                    <Route path="analytics" element={<AnalyticsTab />} />
                    <Route path="videos/*" element={<VideoTab />} />
                  </Routes>
                </div>
              </div>
              {!isAdmin(userCustomClaim) &&
                hasPermission(
                  userCustomClaim,
                  CompanyLevelPermissions.CREATE_FEEDBACK
                ) && <FeedbackChatbot />}
            </div>
          </main>

          <footer
            role="contentinfo"
            className="layout-footer p-3 bg-gray-300 z-9999"
          >
            <span className="text-gray-600 font-bold">
              Copyright © {new Date().getFullYear()} Aurora Solutions.
            </span>
          </footer>
        </section>
      </section>
    </section>
  );
};

export default DashboardNew;

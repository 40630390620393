import { getData } from "../axios-calls/common-axios-service";

export const getUserEngagements = async (
  startAt: string,
  endBefore: string,
  companyId: string
): Promise<any> => {
  const fetchedProjects = await getData(
    `reports/userEngagement?startDate=` +
      startAt +
      "&&endDate=" +
      endBefore +
      "&&companyId=" +
      companyId
  );
  return fetchedProjects;
};

export const getLoginUserReports = async (
  startAt: string,
  endBefore: string,
  companyId: string
): Promise<any> => {
  const fetchedProjects = await getData(
    `reports/loginUser?startDate=` +
      startAt +
      "&&endDate=" +
      endBefore +
      "&&companyId=" +
      companyId
  );
  return fetchedProjects;
};

export const getScreeViewRecords = async (
  startAt: string,
  endBefore: string,
  companyId: string
): Promise<any> => {
  const fetchedProjects = await getData(
    `reports/screenView?startDate=` +
      startAt +
      "&&endDate=" +
      endBefore +
      "&&companyId=" +
      companyId
  );
  return fetchedProjects;
};

export const getEventReports = async (
  startAt: string,
  endBefore: string,
  companyId: string
): Promise<any> => {
  const fetchedProjects = await getData(
    `reports/events?startDate=` +
      startAt +
      "&&endDate=" +
      endBefore +
      "&&companyId=" +
      companyId
  );
  return fetchedProjects;
};

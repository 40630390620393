import { useEffect } from "react";
import { analytics, logEvent } from "../../../../config/firebase.includes";

export function useLogEvent(eventName: string, pageTitle: string) {
  useEffect(() => {
    logEvent(analytics, eventName, {
      page_title: pageTitle,
      page_location: window.location.href, // Current page URL
    });
  }, []);
}

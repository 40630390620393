/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dispatch } from "react";
import { updateDisplayName } from "../../apis/auth/auth-service";
import {
  getUserCompanyPermissionsData,
  getUserProjectPermissionModelByUserId,
} from "../../apis/services/users.service";
import { auth } from "../../config/firebase.includes";
import { LOGIN_SUCCESS, LOGOUT, UPDATE } from "../Constants";
import { IEventSuccess } from "../model/ActionsInterface.d";
import { UpdatedGlobalUser } from "../model/UpdatedUser.d";
import {
  UserState,
  UserCustomClaim,
  UserPermission,
  UserCompanyPermissionResponse,
  ProjectPermissionModel,
} from "../model/UserState.d";

const loginSuccessfullEvent = (userState: UserState) => ({
  type: LOGIN_SUCCESS,
  userState: userState,
});

const logoutEvent = (userState: UserState) => ({
  type: LOGOUT,
  userState: userState,
});

export const loginSuccessfull =
  () => async (dispatch: Dispatch<IEventSuccess>) => {
    const user = auth.currentUser!;

    const results: [UserCompanyPermissionResponse, ProjectPermissionModel[]] =
      await Promise.all([
        getUserCompanyPermissionsData(user.uid),
        getUserProjectPermissionModelByUserId(user.uid),
      ]);
    const companyPermissionData: UserCompanyPermissionResponse = results[0];
    const projectPermissions: ProjectPermissionModel[] = results[1];

    const userPermissions: UserPermission = {
      name: companyPermissionData?.name,
      picture: companyPermissionData?.picture,
      email: companyPermissionData?.email,
      userCustomClaim: {
        isAdmin: companyPermissionData?.isAdmin,
        companyId: companyPermissionData?.companyId,
        companyPermissions: companyPermissionData?.companyPermissions,
        projectPermissionsModel: projectPermissions,
      },
    };
    dispatch(
      loginSuccessfullEvent({
        userId: user.uid,
        userName: user.displayName!,
        userPermission: userPermissions,
      })
    );
  };

export const logout = () => async (dispatch: Dispatch<IEventSuccess>) => {
  dispatch(
    logoutEvent({
      userId: "",
      userName: "",
      userPermission: undefined,
    })
  );
};

const updateUserEvent = (userState: UserState) => ({
  type: UPDATE,
  userState: userState,
});

export const updateGlobalUser =
  (user: UpdatedGlobalUser) => async (dispatch: Dispatch<IEventSuccess>) => {
    updateDisplayName(user.userName);

    const updatedUser = auth.currentUser!;
    const userCLaims = (await updatedUser?.getIdTokenResult())
      ?.claims as unknown as UserCustomClaim;

    dispatch(
      updateUserEvent({
        userId: user.userId,
        userName: user.userName!,
        userPermission: userCLaims,
      })
    );
  };

export enum DashboardTab {
  COMPANY = "COMPANY",
  PROJECTS = "PROJECTS",
  MEMBERS = "MEMBERS",
  TIMELOGS = "TIMELOGS",
  ATTENDANCE = "ATTENDANCE",
  ALLOCATION = "ALLOCATION",
  LUNCH = "LUNCH",
  FEEDBACK = "FEEDBACK",
  VIDEOS = "VIDEOS",
  SALARY = "SALARY",
  ANALYTICS = "ANALYTICS",
}

export enum AttendanceType {
  DAILY = "DAILY",
  HOLIDAY = "HOLIDAY",
}

export enum LunchTab {
  MENU = "MENU",
  ORDERS = "ORDERS",
}

export enum Billable {
  ALL = "All",
  YES = "Yes",
  NO = "No",
}

export enum UserRole {
  SUPER_ADMIN = "SUPER_ADMIN",
  COMPANY_ADMIN = "COMPANY_ADMIN",
  PROJECT_MANAGER = "PROJECT_MANAGER",
  TEAM_MEMBER = "TEAM_MEMBER",
  CUSTOM = "CUSTOM",
}

export enum Status {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}
export enum MemberStatus {
  Assigned = "ASSIGNED",
  Unassigned = "UNASSIGNED",
}
export enum TicketType {
  BUG = "BUG",
  TASK = "TASK",
}

export enum GroupType {
  GROUP_1 = "GROUP_1",
  GROUP_2 = "GROUP_2",
}

export enum AllDropdown {
  ALL = "ALL",
}

export enum AttendanceStatus {
  PRESENT = "PRESENT",
  ANNUAL_LEAVE = "ANNUAL_LEAVE",
  EMERGENCY_LEAVE = "EMERGENCY_LEAVE",
  SICK_LEAVE = "SICK_LEAVE",
  MATERNITY_LEAVE = "MATERNITY_LEAVE",
  PATERNITY_LEAVE = "PATERNITY_LEAVE",
  UNPAID_LEAVE = "UNPAID_LEAVE",
  BEREAVEMENT_LEAVE = "BEREAVEMENT_LEAVE",
  CONTEMPORARY_LEAVE = "CONTEMPORARY_LEAVE",
  BIRTHDAY_LEAVE = "BIRTHDAY_LEAVE",
  PUBLIC_HOLIDAYS = "PUBLIC_HOLIDAYS",
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useRef, useState } from "react";

import { Grid, Button } from "@mui/material";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";

import { useSelector } from "react-redux";
import { ReduxState } from "../../../../redux/model/ReduxState.d";
import { UserCustomClaim } from "../../../../redux/model/UserState.d";

import UploadVideoModal from "../Modals/uploadVideoModal";

let videoStopTimeOut: NodeJS.Timeout;
const RecordVideo: React.FC = () => {
  const userCustomClaim = useSelector<ReduxState, UserCustomClaim>(
    (state: ReduxState) => state.UserReducer?.userPermission?.userCustomClaim
  );
  const userId = useSelector<ReduxState, string>(
    (state: ReduxState) => state.UserReducer.userId || ""
  );

  const localCamStreamRef = useRef<MediaStream | null>(null);
  const localScreenStreamRef = useRef<MediaStream | null>(null);
  const localOverlayStreamRef = useRef<MediaStream | null>(null);
  const rafIdRef = useRef<number | null>(null);
  const camRef = useRef<HTMLVideoElement | null>(null);
  const screenRef = useRef<HTMLVideoElement | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioContextRef = useRef<AudioContext | null>(null);
  const audioDestinationRef = useRef<MediaStreamAudioDestinationNode | null>(
    null
  );
  const mediaWrapperDivRef = useRef<HTMLDivElement | null>(null);

  const canvasElement = document.createElement("canvas");
  const canvasCtx = canvasElement.getContext("2d");
  const canvasCtxRef = useRef<CanvasRenderingContext2D | null>(canvasCtx);
  const canvasElementRef = useRef<HTMLCanvasElement | null>(canvasElement);

  const [timerState, setTimerState] = useState<boolean>(false);
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);

  const [disableRecordingButtons, setDisableRecordingButtons] = useState<{
    startRecording: boolean;
    stopRecordingAndUpload: boolean;
  }>({
    startRecording: false,
    stopRecordingAndUpload: true,
  });

  const [videoBlob, setVideoBlob] = useState<Blob[]>([]);

  const handleClose = () => {
    setShowUploadModal(false);
    setDisableRecordingButtons({
      ...disableRecordingButtons,
      startRecording: false,
    });
  };

  useEffect(() => {
    if (timerState) {
      stopRecordingFn();
      setTimerState(false);
    }
  }, [timerState]);

  const stopAllStreamsFn = () => {
    [
      ...(localCamStreamRef.current
        ? localCamStreamRef.current.getTracks()
        : []),
      ...(localScreenStreamRef.current
        ? localScreenStreamRef.current.getTracks()
        : []),
      ...(localOverlayStreamRef.current
        ? localOverlayStreamRef.current.getTracks()
        : []),
    ].forEach((track) => track.stop());

    localCamStreamRef.current = null;
    localScreenStreamRef.current = null;
    localOverlayStreamRef.current = null;
    cancelVideoFrame(rafIdRef.current);
    if (mediaWrapperDivRef.current) {
      mediaWrapperDivRef.current.innerHTML = "";
    }
    document.getElementById("recordingState")!.innerHTML = "";
    setShowUploadModal(true);
  };

  const stopRecordingFn = () => {
    clearTimeout(videoStopTimeOut);
    setTimerState(false);
    setDisableRecordingButtons({
      ...disableRecordingButtons,
      startRecording: true,
      stopRecordingAndUpload: true,
    });

    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();

      document.getElementById(
        "recordingState"
      )!.innerHTML = `${mediaRecorderRef.current.state}...`;
    }
    stopAllStreamsFn();
  };

  function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const requestVideoFrame = (callback: FrameRequestCallback) => {
    return window.setTimeout(() => {
      callback(Date.now());
    }, 1000 / 60); // 60 fps - just like requestAnimationFrame
  };

  const cancelVideoFrame = (id: number | null) => {
    if (id) {
      clearTimeout(id);
    }
  };

  const startWebcamFn = async () => {
    localCamStreamRef.current = await navigator.mediaDevices.getUserMedia({
      video: { width: { max: 1280 }, height: { max: 720 } },
      audio: { deviceId: { ideal: "communications" } },
    });

    if (localCamStreamRef.current) {
      camRef.current = await attachToDOM(
        "justWebcam",
        localCamStreamRef.current
      );
    }
  };

  const startScreenShareFn = async () => {
    localScreenStreamRef.current = await navigator.mediaDevices.getDisplayMedia(
      {
        video: true,
        audio: true,
      }
    );

    if (localScreenStreamRef.current) {
      screenRef.current = await attachToDOM(
        "justScreenShare",
        localScreenStreamRef.current
      );
    }
  };

  const makeComposite = async () => {
    if (
      camRef.current &&
      screenRef.current &&
      canvasCtxRef.current &&
      canvasElementRef.current
    ) {
      canvasCtxRef.current.save();
      canvasElementRef.current.setAttribute(
        "width",
        `${screenRef.current.videoWidth}px`
      );
      canvasElementRef.current.setAttribute(
        "height",
        `${screenRef.current.videoHeight}px`
      );
      canvasCtxRef.current.clearRect(
        0,
        0,
        screenRef.current.videoWidth,
        screenRef.current.videoHeight
      );
      canvasCtxRef.current.drawImage(
        screenRef.current,
        0,
        0,
        screenRef.current.videoWidth,
        screenRef.current.videoHeight
      );
      canvasCtxRef.current.drawImage(
        camRef.current,
        0,
        Math.floor(
          screenRef.current.videoHeight - screenRef.current.videoHeight / 4
        ),
        Math.floor(screenRef.current.videoWidth / 4),
        Math.floor(screenRef.current.videoHeight / 4)
      );
      const imageData = canvasCtxRef.current?.getImageData(
        0,
        0,
        screenRef.current.videoWidth,
        screenRef.current.videoHeight
      );
      canvasCtxRef.current.putImageData(imageData, 0, 0);
      canvasCtxRef.current.restore();
      rafIdRef.current = requestVideoFrame(makeComposite);
    }
  };

  async function mergeStreamsFn() {
    setDisableRecordingButtons({
      ...disableRecordingButtons,
      startRecording: true,
      stopRecordingAndUpload: false,
    });
    // }
    await startWebcamFn();
    await timeout(100);
    await startScreenShareFn();
    await timeout(100);
    const mutingStreamsElement = document.getElementById("mutingStreams");
    if (mutingStreamsElement) {
      mutingStreamsElement.style.display = "block";
    }

    await makeComposite();

    audioContextRef.current = new AudioContext();
    audioDestinationRef.current =
      audioContextRef.current.createMediaStreamDestination();

    const fullVideoStream = canvasElementRef.current?.captureStream();
    const existingAudioStreams = [
      ...(localCamStreamRef.current
        ? localCamStreamRef.current.getAudioTracks()
        : []),
      ...(localScreenStreamRef.current
        ? localScreenStreamRef.current.getAudioTracks()
        : []),
    ];

    const audioTracks = existingAudioStreams.map((track) =>
      audioContextRef.current!.createMediaStreamSource(new MediaStream([track]))
    );

    audioTracks.forEach((track) => track.connect(audioDestinationRef.current!));

    localOverlayStreamRef.current = new MediaStream([
      ...(fullVideoStream?.getVideoTracks() || []),
    ]);

    const fullOverlayStream = new MediaStream([
      ...(fullVideoStream?.getVideoTracks() || []),
      ...(audioDestinationRef.current?.stream.getAudioTracks() || []),
    ]);

    if (localOverlayStreamRef.current) {
      await attachToDOM("pipOverlayStream", localOverlayStreamRef.current);

      mediaRecorderRef.current = new MediaRecorder(fullOverlayStream, {
        mimeType: "video/webm; codecs=vp9",
      });
      mediaRecorderRef.current.ondataavailable = handleDataAvailable;
      const overlayElement = document.getElementById(
        "pipOverlayStream"
      ) as HTMLVideoElement;
      overlayElement.volume = 0;
      camRef.current!.volume = 0;
      screenRef.current!.volume = 0;
      camRef.current!.style.display = "none";
      screenRef.current!.style.display = "none";
    }
    startRecordingFn();
  }

  const startRecordingFn = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.start();
      videoStopTimeOut = setTimeout(() => {
        setTimerState(true);
      }, 60100);

      document.getElementById("pipOverlayStream")!.style.border =
        "10px solid red";
      document.getElementById(
        "recordingState"
      )!.innerHTML = `${mediaRecorderRef.current.state}...`;
    }
  };

  const attachToDOM = async (id: string, stream: MediaStream) => {
    const videoElem = document.createElement("video");
    videoElem.id = id;
    videoElem.width = 640;
    videoElem.height = 360;
    videoElem.autoplay = true;
    videoElem.setAttribute("playsInline", "true");
    videoElem.srcObject = new MediaStream(stream.getTracks());
    if (mediaWrapperDivRef.current) {
      mediaWrapperDivRef.current.appendChild(videoElem);
    }
    return videoElem;
  };

  const handleDataAvailable = (event: any) => {
    if (event.data.size > 0) {
      setVideoBlob((prevBlob) => [...prevBlob, event.data]);
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} className="flex justify-end">
          <Button
            className={`${
              disableRecordingButtons.startRecording
                ? "opacity-25 cursor-not-allowed"
                : ""
            } h-10 mt-3 bg-blue-600 text-white w-fit mb-2`}
            component="label"
            variant="contained"
            startIcon={<CloudUploadOutlinedIcon />}
            onClick={mergeStreamsFn}
            disabled={disableRecordingButtons.startRecording}
          >
            Start Recording
          </Button>
          &nbsp;
          <Button
            className={`${
              disableRecordingButtons.stopRecordingAndUpload
                ? "opacity-25 cursor-not-allowed"
                : ""
            } h-10 mt-3 bg-blue-600 text-white w-fit mb-2`}
            component="label"
            variant="contained"
            startIcon={<StopCircleOutlinedIcon />}
            onClick={stopRecordingFn}
            disabled={disableRecordingButtons.stopRecordingAndUpload}
          >
            Stop Recording and Upload
          </Button>
        </Grid>
        <Grid item xs={12}>
          <div id="recordingState"></div>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} className="flex justify-center">
          <div id="mutingStreams">
            <div id="mediaWrapper" ref={mediaWrapperDivRef}></div>
          </div>
        </Grid>
      </Grid>
      {showUploadModal && (
        <UploadVideoModal
          showModal={showUploadModal}
          handleClose={handleClose}
          videoBlob={videoBlob}
          userInfo={{ companyId: userCustomClaim.companyId, userId: userId }}
        />
      )}
    </>
  );
};

export default RecordVideo;
